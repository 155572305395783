import type { ParsedUrlQueryInput } from 'querystring'
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import pickBy from 'lodash/pickBy'
import {
  getPayablesPath,
  useBusinessIdPathParam,
  usePayableGroupQueryParam,
  IGetPayablesPathParams,
  TPayableGroup,
  IPayableInvoicesFilters,
} from 'lib/navigation'
import { IPaginationQueryParams } from 'lib/pagination'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

export interface IPayableInvoicesQueryParams extends IPaginationQueryParams {
  fees?: boolean
  vendorNameSearch?: string
  group?: Maybe<TPayableGroup>
  invoiceNumberSearch?: string
  purchaseOrderNumberSearch?: string
  labelIds?: string[]
  sortingField?: gql.InvoiceSortableFieldEnum
  sortingDirection?: gql.InvoiceSortingDirectionEnum
  filters?: IPayableInvoicesFilters
  openSearch?: boolean
  invoiceAmountSearch?: string
}

type TGetQueryParams = (params?: IPayableInvoicesQueryParams) => ParsedUrlQueryInput
type TOpen = (params?: IPayableInvoicesQueryParams, options?: Parameters<typeof Router['push']>[2]) => Promise<boolean>
type TGetPath = (params?: Partial<IGetPayablesPathParams>) => string
type TUseReceivableInvoicesNavigation = () => {
  open: TOpen
  getPath: TGetPath
  getQueryParams: TGetQueryParams
}

/** Handles payable invoices page navigation. */
export const usePayablesNavigation: TUseReceivableInvoicesNavigation = () => {
  const payableGroup = usePayableGroupQueryParam()
  const businessIdPathParam = useBusinessIdPathParam()

  const getQueryParams: TGetQueryParams = useCallback(
    ({
      page,
      fees,
      perPage,
      filters,
      sortingField,
      vendorNameSearch,
      sortingDirection,
      invoiceNumberSearch,
      purchaseOrderNumberSearch,
      labelIds,
      group = payableGroup,
      openSearch,
      invoiceAmountSearch,
    } = {}) => ({
      ...(page && { page }),
      ...(group && { tab: group }),
      ...(perPage && { per_page: perPage }),
      ...(typeof fees === 'boolean' && { fees }),
      ...(sortingField && { sort: sortingField.toLowerCase() }),
      ...(sortingDirection && { order: sortingDirection.toLowerCase() }),
      ...(invoiceNumberSearch && invoiceNumberSearch !== '' && { invoice_number_search: invoiceNumberSearch }),
      ...(purchaseOrderNumberSearch &&
        purchaseOrderNumberSearch !== '' && { purchase_order_number_search: purchaseOrderNumberSearch }),
      ...(vendorNameSearch && { vendor_name_search: vendorNameSearch }),
      ...(labelIds && labelIds.length > 0 && { label_ids: labelIds }),
      ...pickBy(filters, (value) => value !== undefined),
      ...(typeof openSearch === 'boolean' && { openSearch }),
      ...(invoiceAmountSearch && { invoice_amount_search: invoiceAmountSearch }),
    }),
    [payableGroup],
  )

  const open: TOpen = useCallback(
    (params = {}, options = {}) =>
      Router.push(
        {
          pathname: getPayablesPath({ businessId: businessIdPathParam }),
          query: getQueryParams(params),
        },
        undefined,
        options,
      ),
    [businessIdPathParam, getQueryParams],
  )

  const getPath: TGetPath = useCallback(
    ({ businessId, ...params } = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getPayablesPath({ ...params, businessId: businessIdToUse })
    },
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath, getQueryParams }), [open, getPath, getQueryParams])
}
