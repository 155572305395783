import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getCatalogPath, useBusinessIdPathParam } from 'lib/navigation'
import { IPaginationQueryParams } from 'lib/pagination'

type TOpen = (params?: IPaginationQueryParams) => void
type TGetPath = (search?: string) => string
type TUseCatalogNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles catalog page navigation. */
export const useCatalogNavigation: TUseCatalogNavigation = () => {
  const businessId = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    ({ page, perPage } = {}) =>
      Router.push({
        pathname: getCatalogPath({ businessId }),
        query: {
          ...(page && { page }),
          ...(perPage && { per_page: perPage }),
        },
      }),
    [businessId],
  )

  const getPath: TGetPath = useCallback((search?: string) => getCatalogPath({ businessId, search }), [businessId])

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
