import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getReceivablesPath, useBusinessIdPathParam, IGetReceivablesPathParams, TReceivableGroup } from 'lib/navigation'
import { IPaginationQueryParams } from 'lib/pagination'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

export interface IQueryParams extends IPaginationQueryParams {
  payerNameSearch?: string
  invoiceNumberSearch?: string
  purchaseOrderNumberSearch?: string
  group?: Maybe<TReceivableGroup>
  sortingDirection?: gql.ReceivableInvoicesSortingDirectionEnum
  sortingField?: gql.ReceivableSortableFieldEnum
}
type TOpen = (params?: IQueryParams, options?: Parameters<typeof Router['push']>[2]) => Promise<boolean>
type TGetPath = (params?: IGetReceivablesPathParams) => string
type TUseReceivableInvoicesNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles receivables page navigation. */
export const useReceivablesNavigation: TUseReceivableInvoicesNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    (
      {
        group,
        invoiceNumberSearch,
        payerNameSearch,
        purchaseOrderNumberSearch,
        page,
        perPage,
        sortingField,
        sortingDirection,
      } = {},
      options = {},
    ) =>
      Router.push(
        {
          pathname: getReceivablesPath(businessIdPathParam),
          query: {
            ...(page && { page }),
            ...(group && { tab: group }),
            ...(perPage && { per_page: perPage }),
            ...(sortingField && { sort: sortingField.toLowerCase() }),
            ...(sortingDirection && { order: sortingDirection.toLowerCase() }),
            ...(payerNameSearch && { payer_name_search: payerNameSearch }),
            ...(invoiceNumberSearch && { invoice_number_search: invoiceNumberSearch }),
            ...(purchaseOrderNumberSearch && { purchase_order_number_search: purchaseOrderNumberSearch }),
          },
        },
        undefined,
        options,
      ),
    [businessIdPathParam],
  )

  const getPath: TGetPath = useCallback(
    (params) => getReceivablesPath(businessIdPathParam, params),
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
