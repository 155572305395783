/* eslint-disable max-len */
import { generateDDTiming } from './datadog'

/**
 * @desc Records timings for DRAFT and UNSCHEDULED invoices.
 * Dashboard {@link https://app.datadoghq.com/dashboard/9ge-sxi-6pn/rum-performance-selected-components-load-time?live=false&tile_focus=4713556192955690}
 */
export const receivableInvoiceDetailsRenderTiming = generateDDTiming('render_receivable_invoice_details')
/**
 * {@link https://app.datadoghq.com/dashboard/9ge-sxi-6pn/rum-performance-selected-components-load-time?live=false&tile_focus=11742160690114}
 */
export const invoiceDetailsRenderTiming = generateDDTiming('render_invoice_details')
/**
 * {@link https://app.datadoghq.com/dashboard/9ge-sxi-6pn/rum-performance-selected-components-load-time?live=false&tile_focus=2547144870401232}
 */
export const invoiceDetailsFormRenderTiming = generateDDTiming('render_invoice_details_form')
/**
 * {@link https://app.datadoghq.com/dashboard/9ge-sxi-6pn/rum-performance-selected-components-load-time?live=false&tile_focus=6869980504764192}
 */
export const receivableDraftInvoiceDetailsRenderTiming = generateDDTiming('render_receivable_draft_invoice_details')
/**
 * {@link https://app.datadoghq.com/dashboard/9ge-sxi-6pn/rum-performance-selected-components-load-time?live=false&tile_focus=2744867879476826}
 */
export const payablesScreenRenderTiming = generateDDTiming('render_payables_screen')
/**
 * {@link https://app.datadoghq.com/dashboard/9ge-sxi-6pn/rum-performance-selected-components-load-time?live=false&tile_focus=8250455376081788}
 */
export const receivablesScreenRenderTiming = generateDDTiming('render_receivables_screen')
/**
 * {@link https://app.datadoghq.com/dashboard/pnk-5ig-ym7/rum-performance-dialogs-openclose-time?live=false&tile_focus=6216302224058488}
 */
export const settingsDialogOpenTiming = generateDDTiming('render_settings_dialog_open')
/**
 * {@link https://app.datadoghq.com/dashboard/pnk-5ig-ym7/rum-performance-dialogs-openclose-time?live=false&tile_focus=6270877518634576}
 */
export const settingsDialogCloseTiming = generateDDTiming('render_settings_dialog_close')
/**
 * {@link https://app.datadoghq.com/dashboard/pnk-5ig-ym7/rum-performance-dialogs-openclose-time?live=false&tile_focus=324370263279366}
 */
export const openPayablesInvoiceDialogOnRowClickTiming = generateDDTiming(
  'render_open_payables_invoice_dialog_on_row_click',
)
/**
 * {@link https://app.datadoghq.com/dashboard/pnk-5ig-ym7/rum-performance-dialogs-openclose-time?tile_focus=6270877518634576&live=false}
 */
export const closePayablesInvoiceDialogTiming = generateDDTiming('render_close_payables_invoice_dialog')
/**
 * {@link https://app.datadoghq.com/dashboard/pnk-5ig-ym7/rum-performance-dialogs-openclose-time?live=false&tile_focus=1018720894662194}
 */
export const payablesInvoiceDialogPaginationTiming = generateDDTiming('render_payables_invoice_dialog_pagination')
