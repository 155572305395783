import { forwardRef } from 'react'
import { ExtendButtonBase, ButtonBaseTypeMap, CircularProgress } from '@mui/material'
import * as $ from './IconButton.styled'
import { IIconButtonProps } from './IconButton.types'

type TIconButton = ExtendButtonBase<ButtonBaseTypeMap<IIconButtonProps>>

// TODO: ??
// @ts-expect-error why?
export const IconButton: TIconButton = forwardRef(
  ({ size = 'medium', kind = 'neutral', variant = 'tertiary', sx, loading, children, ...otherProps }, ref) => (
    <$.IconButton sx={sx} kind={kind} variant={variant} size={size} ref={ref} {...otherProps}>
      {loading ? <CircularProgress size={20} color="inherit" /> : children}
    </$.IconButton>
  ),
)

// TODO: ??
// @ts-expect-error why?
IconButton.displayName = 'IconButton'

export default IconButton
