export interface IGetReceivablesPathParams {
  search?: string
}

/**
 * Returns path to the receivables page
 * with an optional search query.
 */
export const getReceivablesPath = (businessId: Maybe<string>, { search }: IGetReceivablesPathParams = {}) => {
  let path = `/${businessId}/receivables`

  if (search) {
    path += `?${search}`
  }

  return path
}
