import type { ParsedUrlQuery } from 'querystring'
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { useBusinessIdPathParam, getInboxPath, useRemoveRouterQueryParams } from 'lib/navigation'

export type TInboxTab = 'archived' | 'incoming'
interface IInboxQueryParams {
  selected?: string
  tab?: TInboxTab
  next?: string
  otherParams?: ParsedUrlQuery
}
type TOpen = (params?: IInboxQueryParams, options?: Parameters<typeof Router['push']>[2]) => Promise<boolean>
type TSwitchTab = (tab: TInboxTab) => Promise<boolean>
type TOpenInboxItem = (selected?: string, next?: string) => Promise<boolean>
type TCloseInboxItem = () => Promise<boolean>
type TGetPath = ({ businessId }?: { businessId?: string }) => string
type TUseInboxNavigation = () => {
  open: TOpen
  getPath: TGetPath
  switchTab: TSwitchTab
  openInboxItem: TOpenInboxItem
  closeInboxItem: TCloseInboxItem
}

/**
 * Handles the inbox page navigation.
 */
export const useInboxNavigation: TUseInboxNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open: TOpen = useCallback(
    ({ selected, tab, next, otherParams } = {}, options = {}) =>
      Router.push(
        {
          pathname: getInboxPath(businessIdPathParam),
          query: {
            ...otherParams,
            ...(tab && { tab }),
            ...(selected && { selected }),
            ...(next && { next }),
          },
        },
        undefined,
        options,
      ),
    [businessIdPathParam],
  )

  const getPath: TGetPath = useCallback(
    ({ businessId } = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getInboxPath(businessIdToUse)
    },
    [businessIdPathParam],
  )

  const switchTab: TSwitchTab = useCallback(
    (tab) => Router.push({ query: { ...Router.query, tab } }, undefined, { shallow: true }),
    [],
  )

  const openInboxItem: TOpenInboxItem = useCallback(
    (selected, next) => {
      if (!next) {
        removeRouterQueryParams('next')
      }

      return Router.push(
        { query: { ...Router.query, ...(selected && { selected }), ...(next && { next }) } },
        undefined,
        { shallow: true },
      )
    },
    [removeRouterQueryParams],
  )

  const closeInboxItem: TCloseInboxItem = useCallback(
    () => removeRouterQueryParams('selected', 'next'),
    [removeRouterQueryParams],
  )

  return useMemo(
    () => ({ open, getPath, switchTab, openInboxItem, closeInboxItem }),
    [open, getPath, switchTab, openInboxItem, closeInboxItem],
  )
}
