import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  useRemoveRouterQueryParams,
  getReceivableInvoicePath,
  useBusinessIdPathParam,
  IGetReceivableInvoicePathParams,
} from 'lib/navigation'

interface IQueryParams {
  invoiceId: Maybe<string>
}

type TOpen = (params: IQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TGetPath = (params: IGetReceivableInvoicePathParams) => string
type TUseReceivableNavigation = () => {
  open: TOpen
  close: TClose
  getPath: TGetPath
}

/** Handles receivable invoice dialog navigation. */
export const useReceivableNavigation: TUseReceivableNavigation = () => {
  const businessId = useBusinessIdPathParam()
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open: TOpen = useCallback(
    (params) =>
      Router.push(
        {
          query: {
            ...Router.query,
            receivable_id: params.invoiceId,
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const close: TClose = useCallback(() => removeRouterQueryParams('receivable_id'), [removeRouterQueryParams])

  const getPath: TGetPath = useCallback((params) => getReceivableInvoicePath(businessId, params), [businessId])

  return useMemo(() => ({ open, close, getPath }), [open, close, getPath])
}
