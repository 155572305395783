import { useRouter } from 'next/router'
import { TInboxTab } from 'lib/navigation'

interface IUseInboxQueryResult {
  selected: string | null
  tab: TInboxTab | null
  next: string | null
}

/**
 * Returns tab, selected, and next router parameters.
 * Returns null when it's missing.
 */
export const useInboxQueryParams = (): IUseInboxQueryResult => {
  const { query } = useRouter()
  const selected = typeof query.selected === 'string' ? query.selected : null
  const tab = typeof query.tab === 'string' && (query.tab === 'incoming' || query.tab === 'archived') ? query.tab : null
  const next = typeof query.next === 'string' ? query.next : null

  return { selected, tab, next }
}
