import throttle from 'lodash/throttle'
import { datadogRum } from '@datadog/browser-rum'
import { isImpersonationSession } from 'lib/helpers/isImpersonationSession/isImpersonationSession'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import type { IUserTraits } from './userTraits'

export const isTrackingDisabled = () => isImpersonationSession() || isSSR() || !window?.analytics

const exceptIntercom = {
  All: true,
  // filtering for Intercom destination inside of Segment is available only on expensive plan,
  // therefore this flag lets Segment know that "track" events should not be sent to Intercom
  Intercom: false,
}

const includingIntercom = {
  All: true,
  Intercom: true,
}

export const identifyUser = (userId?: string | null, traits: IUserTraits = {}) => {
  if (!isTrackingDisabled()) {
    window.analytics?.identify(
      userId,
      traits,
      {
        integrations: includingIntercom,
      },
      () => window.Intercom?.('update'),
    )
  }
}

export const updateUserTraits = (traitsUpdates: IUserTraits) => {
  if (!isTrackingDisabled()) {
    const userInstance = window.analytics?.user?.()
    const userId = userInstance?.id?.()

    if (userId) {
      window.analytics?.identify(userId, traitsUpdates, {
        integrations: includingIntercom,
      })
    }
  }
}

export const trackPage = (name?: string, properties: Record<string, unknown> = {}) => {
  if (!isTrackingDisabled()) {
    window.analytics?.page(name, properties, {
      integrations: includingIntercom,
    })
  }
}

export const logEvent = (name: string, props: Record<string, unknown> = {}) => {
  if (!isTrackingDisabled()) {
    window.analytics?.track(name, props, {
      integrations: exceptIntercom,
    })
  }
  datadogRum.addAction(name, props)
}

export const resetTracking = () => {
  if (!isTrackingDisabled()) {
    window.analytics?.reset()
    window.Intercom?.('shutdown')
  }
}

const createEventTiming = () => {
  let startTime: number | null = null

  return {
    start: () => {
      startTime = Date.now()
    },
    current: () => {
      if (startTime) {
        const endTime = Date.now()

        return endTime - startTime
      }
      return null
    },
  }
}

export const invoiceDialogOpenedTiming = createEventTiming()

export const logEventThrottled = throttle(logEvent, 1000, { leading: false })
