import { ERouterPage } from 'lib/navigation/consts'

interface IParams {
  warning?: string
  next?: Maybe<string>
}

/**
 * Returns path to the login page with optional redirect path.
 */
export const getLoginPath = ({ warning, next }: IParams = {}) => {
  const path = ERouterPage.login
  const searchParams = new URLSearchParams()

  if (next) {
    searchParams.set('next', next)
  }
  if (warning) {
    searchParams.set('warning', warning)
  }

  return searchParams.toString() ? `${path}?${searchParams.toString()}` : path
}
