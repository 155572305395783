/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonSecondaryBorderWidthPositiveDefault = '1px'
export const ButtonSecondaryBorderWidthPositiveHovered = '1px'
export const ButtonSecondaryBorderWidthPositivePressed = '1px'
export const ButtonSecondaryBorderWidthPositiveDisabled = '1px'
export const ButtonSecondaryBorderWidthDestructiveDefault = '1px'
export const ButtonSecondaryBorderWidthDestructiveHovered = '1px'
export const ButtonSecondaryBorderWidthDestructivePressed = '1px'
export const ButtonSecondaryBorderWidthDestructiveDisabled = '1px'
export const ButtonSecondaryColorPositiveDefaultBackground = '#ffffff00'
export const ButtonSecondaryColorPositiveDefaultOnBackground = '#ED9341'
export const ButtonSecondaryColorPositiveDefaultOutline = '#0000001f'
export const ButtonSecondaryColorPositiveHoveredBackground = '#ed934114'
export const ButtonSecondaryColorPositiveHoveredOnBackground = '#ED9341'
export const ButtonSecondaryColorPositiveHoveredOutline = '#0000001f'
export const ButtonSecondaryColorPositivePressedBackground = '#ed934129'
export const ButtonSecondaryColorPositivePressedOnBackground = '#ED9341'
export const ButtonSecondaryColorPositivePressedOutline = '#ED9341'
export const ButtonSecondaryColorPositiveDisabledBackground = '#ffffff00'
export const ButtonSecondaryColorPositiveDisabledOnBackground = '#00000061'
export const ButtonSecondaryColorPositiveDisabledOutline = '#0000001f'
export const ButtonSecondaryColorDestructiveDefaultBackground = '#ffffff00'
export const ButtonSecondaryColorDestructiveDefaultOnBackground = '#f4511e'
export const ButtonSecondaryColorDestructiveDefaultOutline = '#0000001f'
export const ButtonSecondaryColorDestructiveHoveredBackground = '#f4511e14'
export const ButtonSecondaryColorDestructiveHoveredOnBackground = '#f4511e'
export const ButtonSecondaryColorDestructiveHoveredOutline = '#0000001f'
export const ButtonSecondaryColorDestructivePressedBackground = '#f4511e29'
export const ButtonSecondaryColorDestructivePressedOnBackground = '#f4511e'
export const ButtonSecondaryColorDestructivePressedOutline = '#f4511e'
export const ButtonSecondaryColorDestructiveDisabledBackground = '#ffffff00'
export const ButtonSecondaryColorDestructiveDisabledOnBackground = '#00000061'
export const ButtonSecondaryColorDestructiveDisabledOutline = '#0000001f'
