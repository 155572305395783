// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import pickBy from 'lodash/pickBy'
import { useCallback, useMemo } from 'react'
import {
  getPaymentsPath,
  useBusinessIdPathParam,
  usePaymentsGroupQueryParam,
  IGetPaymentsPathParams,
  TPaymentsGroup,
  IReconciliationItemsFilters,
} from 'lib/navigation'
import type { IPaginationQueryParams } from 'lib/pagination'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

export interface IPaymentsSearchQueryParams {
  vendorNameSearch?: string
  paymentAmountSearch?: string
  approverNameSearch?: string
  schedulerNameSearch?: string
}

export interface IPaymentsQueryParams extends IPaginationQueryParams, IPaymentsSearchQueryParams {
  group?: Maybe<TPaymentsGroup>
  filters?: IReconciliationItemsFilters
  sortingField?: gql.ReconciliationItemSortableFieldEnum
  sortingDirection?: gql.ReconciliationItemSortingDirectionEnum
}
type TRouterPushConfig = Parameters<typeof Router['push']>[2]
type TOpen = (params?: IPaymentsQueryParams, options?: TRouterPushConfig) => Promise<boolean>
type TGetPath = (params?: Partial<IGetPaymentsPathParams>) => string
type TUsePaymentsNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles my payments page navigation. */
export const usePaymentsNavigation: TUsePaymentsNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()
  const paymentsGroup = usePaymentsGroupQueryParam()

  const open: TOpen = useCallback(
    (
      {
        page,
        perPage,
        filters,
        sortingField,
        sortingDirection,
        group = paymentsGroup,
        vendorNameSearch,
        paymentAmountSearch,
        approverNameSearch,
        schedulerNameSearch,
      } = {},
      options = {},
    ) =>
      Router.push(
        {
          pathname: getPaymentsPath({ businessId: businessIdPathParam }),
          query: {
            ...(page && { page }),
            ...(perPage && { per_page: perPage }),
            ...(group && { tab: group }),
            ...(sortingField && { sort: sortingField.toLowerCase() }),
            ...(sortingDirection && { order: sortingDirection.toLowerCase() }),
            ...(vendorNameSearch && { vendor_name_search: vendorNameSearch }),
            ...(paymentAmountSearch && { payment_amount_search: paymentAmountSearch }),
            ...(approverNameSearch && { approver_name_search: approverNameSearch }),
            ...(schedulerNameSearch && { scheduler_name_search: schedulerNameSearch }),
            ...pickBy(filters, (value) => value !== undefined),
          },
        },
        undefined,
        options,
      ),
    [businessIdPathParam, paymentsGroup],
  )

  const getPath: TGetPath = useCallback(
    (params) => getPaymentsPath({ businessId: businessIdPathParam, ...params }),
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
