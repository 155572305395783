import { useRouter } from 'next/router'
import { TPurchaseOrdersTab, getPurchaseOrdersTab } from 'lib/navigation'

/**
 * Returns active purchase orders tab router query parameter.
 * Returns null when it's missing.
 */
export const usePurchaseOrdersTabQueryParam = (): TPurchaseOrdersTab | null => {
  const { query } = useRouter()

  return getPurchaseOrdersTab(query.tab)
}
