import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { usePathParamsStore } from 'lib/navigation'

export const usePathParams = () => {
  const { query } = useRouter()
  const { setBusinessId, setUuid } = usePathParamsStore()

  useEffect(() => {
    setBusinessId(typeof query.businessId === 'string' ? query.businessId : '')
    setUuid(typeof query.uuid === 'string' ? query.uuid : '')
  }, [query.businessId, query.uuid, setBusinessId, setUuid])
}
