import { create } from 'zustand'
import { ApolloError } from '@apollo/client'
import reject from 'lodash/reject'
import type { ISnackbarButton } from 'settle-ui/components/Snackbar/Snackbar'
import { getSnackbarWithKey } from './helpers/getSnackbarWithKey/getSnackbarWithKey'

export type TSnackbarVariant = 'alert' | 'error' | 'warning' | 'info'

type TSnackbarOptions = {
  id?: string
  timeout?: number
  closable?: boolean
  spinner?: boolean
  permanent?: boolean
  onClose?: () => void
} & (
  | {
      buttons?: never
      text?: ISnackbarButton['text']
      onClick?: ISnackbarButton['onClick']
    }
  | {
      buttons?: Maybe<ISnackbarButton>[]
      text?: never
      onClick?: never
    }
)

export interface ISnackbar {
  id: string
  key: string
  message: string
  variant: TSnackbarVariant
  options?: TSnackbarOptions
}

interface ISnackbarsStore {
  snackbars: ISnackbar[]
  showSnackbar(variant: TSnackbarVariant, message: string, options?: TSnackbarOptions): string
  hideSnackbar(key: string): void
  showAlert(message: string, options?: TSnackbarOptions): string
  showWarning(message: string, options?: TSnackbarOptions): string
  showInfo(message: string, options?: TSnackbarOptions): string
  showError(message: string, options?: TSnackbarOptions): string
  showApolloError(err: ApolloError, options?: TSnackbarOptions): void
  showApolloOrTextError(err: ApolloError, text: string, options?: TSnackbarOptions): void
}

export const useSnackbarsStore = create<ISnackbarsStore>((set, get) => ({
  snackbars: [],
  showSnackbar: (variant, message, options) => {
    const snackbar = getSnackbarWithKey({ variant, message, options })

    set(({ snackbars }) => ({ snackbars: [...snackbars, snackbar] }))
    return snackbar.key
  },
  hideSnackbar: (key) => set(({ snackbars }) => ({ snackbars: reject(snackbars, { key }) })),
  showAlert: (message, options) => get().showSnackbar('alert', message, options),
  showWarning: (message, options) => get().showSnackbar('warning', message, options),
  showInfo: (message, options) => get().showSnackbar('info', message, options),
  showError: (message, options) => get().showSnackbar('error', message, options),
  showApolloError: (err: ApolloError, options) => {
    err.graphQLErrors.map((m) => m.message).forEach((message) => get().showError(message, options))
  },
  showApolloOrTextError: (err, text, options) => {
    const { showError } = get()

    if (err instanceof ApolloError) {
      const messages = err.graphQLErrors.map((m) => m.message)

      if (messages.length) {
        messages.forEach((message) => showError(message, options))
        return
      }
    }

    showError(text)
  },
}))
