import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { useRemoveRouterQueryParams } from 'lib/navigation'

interface IQueryParams {
  comparedPurchaseOrderId?: Maybe<string>
  comparedReceiptId?: Maybe<string>
  comparedInvoiceId?: Maybe<string>
}

type TOpen = (params: IQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TUseCompareDocumentsNavigation = () => {
  open: TOpen
  close: TClose
}

export const useCompareDocumentsNavigation: TUseCompareDocumentsNavigation = () => {
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open: TOpen = useCallback(
    (params) =>
      Router.push(
        {
          query: {
            ...Router.query,
            ...(params.comparedPurchaseOrderId && { compared_purchase_order_id: params.comparedPurchaseOrderId }),
            ...(params.comparedReceiptId && { compared_receipt_id: params.comparedReceiptId }),
            ...(params.comparedInvoiceId && { compared_invoice_id: params.comparedInvoiceId }),
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const close: TClose = useCallback(
    () => removeRouterQueryParams('compared_purchase_order_id', 'compared_receipt_id', 'compared_invoice_id'),
    [removeRouterQueryParams],
  )

  return useMemo(() => ({ open, close }), [open, close])
}
