import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  getVendorCreditPath,
  useBusinessIdPathParam,
  IVendorCreditQueryParams,
  useRemoveRouterQueryParams,
} from 'lib/navigation'

/** Handles vendor credit dialog navigation. */
export const useVendorCreditNavigation = () => {
  const businessId = useBusinessIdPathParam()
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open = useCallback(
    ({ vendorCreditId }: IVendorCreditQueryParams) =>
      Router.push(
        {
          query: {
            ...Router.query,
            vendor_credit_id: vendorCreditId,
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const close = useCallback(() => removeRouterQueryParams('vendor_credit_id'), [removeRouterQueryParams])

  const getPath = useCallback(
    ({ vendorCreditId }: IVendorCreditQueryParams) => getVendorCreditPath(businessId, { vendorCreditId }),
    [businessId],
  )

  return useMemo(() => ({ open, close, getPath }), [open, getPath, close])
}
