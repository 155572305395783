import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { RecoilRoot } from 'recoil'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
// eslint-disable-next-line import/no-unresolved
import { Analytics as VercelAudiences } from '@vercel/analytics/react'
import { LicenseInfo } from '@mui/x-license-pro'
import { Snackbars } from 'settle-ui/components/Snackbars/Snackbars'
import { setScrollbarStrategy } from 'settle-ui/lib/scrollbarObtrusive/setScrollbarStrategy'
import { ScrollbarObtrusiveGlobalStyles } from 'settle-ui/lib/scrollbarObtrusive/ScrollbarObtrusiveGlobalStyles'
import { AppProgress } from 'pages/components/AppProgress/AppProgress'
import { UserSettingsProvider } from 'pages/components/AppLayout/providers/UserSettingsProvider/UserSettingsProvider'
import { PathParams } from 'pages/components/PathParams/PathParams'
import { setRequestLinkBusinessId, initializeApollo } from 'lib/apollo-client'
import { initDatadog, isTrackingDisabled } from 'lib/tracking'
// eslint-disable-next-line
import 'lib/translations/i18next'

import { useLogSnackbarEvent } from './hooks/useLogSnackbarEvent/useLogSnackbarEvent'
import { useGlobalLDFlagsGetter } from './hooks/useGlobalLDFlagsGetter/useGlobalLDFlagsGetter'
import { AppStylesProvider, IAppStylesProviderProps } from './components/AppStylesProvider/AppStylesProvider'
import { SegmentIdentityTracking } from './components/SegmentIdentityTracking/SegmentIdentityTracking'
import { DatadogIdentityTracking } from './components/DatadogIdentityTracking/DatadogIdentityTracking'
import * as events from './AppPage.events'
import * as $ from './AppPage.styled'

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY ?? '')

export interface IAppPageProps extends AppProps, IAppStylesProviderProps {
  pageProps: Pick<AppProps, 'pageProps'> & {
    profileId: Maybe<string>
    initialCache?: NormalizedCacheObject
  }
}

export let AppPage: React.FC<IAppPageProps> = ({ Component, pageProps, emotionCache, router }) => {
  const { profileId, initialCache } = pageProps
  const businessId = typeof router.query.businessId === 'string' ? router.query.businessId : null
  const identifyUserInSegment = !isTrackingDisabled() && profileId
  const apolloClientRef = useRef(initializeApollo({ initialCache, businessId }))
  const businessIdRef = useRef(businessId)

  useGlobalLDFlagsGetter()
  useEffect(initDatadog, [])
  useEffect(setScrollbarStrategy, [])
  useEffect(() => {
    ;(async () => {
      setRequestLinkBusinessId(businessId)

      if (businessId !== businessIdRef.current) {
        businessIdRef.current = businessId
        apolloClientRef.current.stop()
        await apolloClientRef.current.resetStore()
      }
    })()
  }, [businessId])

  setRequestLinkBusinessId(businessId)

  const logSnackbarEvent = useLogSnackbarEvent(router)

  return (
    <>
      <Head>
        <title>Settle</title>
      </Head>
      {process.env.VERCEL_ENV === 'production' && <VercelAudiences />}
      <ApolloProvider client={apolloClientRef.current}>
        {identifyUserInSegment && <SegmentIdentityTracking profileId={profileId} />}
        {profileId && <DatadogIdentityTracking profileId={profileId} />}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RecoilRoot>
            <UserSettingsProvider>
              <AppStylesProvider emotionCache={emotionCache}>
                <$.GlobalStyles />
                <ScrollbarObtrusiveGlobalStyles />
                <Snackbars
                  onMount={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_DISPLAYED)
                  }}
                  onUnmount={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_REMOVED)
                  }}
                  onActionClick={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_ACTION_BTN_CLICK)
                  }}
                  onCloseClick={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_CLOSE_BTN_CLICK)
                  }}
                  onMouseEnter={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_MOUSE_ENTER)
                  }}
                  onMouseLeave={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_MOUSE_OVER)
                  }}
                />
                <AppProgress />
                <Component {...pageProps} />
                <PathParams />
              </AppStylesProvider>
            </UserSettingsProvider>
          </RecoilRoot>
        </LocalizationProvider>
      </ApolloProvider>
    </>
  )
}

if (process.env.LAUNCHDARKLY_ID) {
  // @ts-expect-error FIX
  AppPage = withLDProvider({ clientSideID: process.env.LAUNCHDARKLY_ID })(AppPage)
}
