import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getSyncStatusPath, useBusinessIdPathParam } from 'lib/navigation'

interface IParams {
  businessId?: string
}

/**
 * Handles sync status page navigation.
 * Can be used to either move to the current business root
 * or to a specific business when optional businessId argument is provided.
 */
export const useSyncStatusNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open = useCallback(
    ({ businessId }: IParams = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return Router.push({ pathname: getSyncStatusPath(businessIdToUse) })
    },
    [businessIdPathParam],
  )

  const getPath = useCallback(
    ({ businessId }: IParams = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getSyncStatusPath(businessIdToUse)
    },
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
