export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ISO8601Date: string
  ISO8601DateTime: string
  SafeInt: number
}

export enum AccessLevelEnum {
  BASIC = 'BASIC',
  FULL = 'FULL',
  NO_ACCESS = 'NO_ACCESS',
  VIEW = 'VIEW',
}

export type Account = {
  accountNumberType: Maybe<VendorAccountNumberTypeEnum>
  accountType: AccountTypeEnum
  accountingAccount: Maybe<AccountingAccount>
  bankAddress: Maybe<Address>
  bankConnection: Maybe<BankLink>
  bankName: Maybe<Scalars['String']>
  beneficiaryFirstName: Maybe<Scalars['String']>
  beneficiaryLastName: Maybe<Scalars['String']>
  beneficiaryName: Maybe<Scalars['String']>
  bicSwift: Maybe<Scalars['String']>
  currency: Maybe<Scalars['String']>
  destinationCountry: Scalars['String']
  /** @deprecated Use bankConnection instead */
  finicityConnection: Maybe<FinicityLink>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  lockbox: Maybe<Scalars['Boolean']>
  mask: Maybe<Scalars['String']>
  microdepositsStatus: Maybe<MicrodepositsStatusEnum>
  missingRequiredDetails: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  numOutstandingInvoices: Scalars['Int']
  /** @deprecated Use bankConnection instead */
  plaidItem: Maybe<PlaidLink>
  preferredPayingAccount: Scalars['Boolean']
  preferredReceivingAccount: Scalars['Boolean']
  /** @deprecated Use accounting_account */
  quickBooksAccount: Maybe<AccountingAccount>
  suggestedAccountingAccount: Maybe<AccountingAccount>
  supportedPaymentTypes: Array<PaymentMethodEnum>
  transactable: Scalars['Boolean']
  verificationSource: Maybe<VerificationSourceEnum>
  wireFields: Maybe<Array<InternationalWireFieldsDataFieldValueType>>
  /** @deprecated Use accounting_account */
  xeroAccount: Maybe<AccountingAccount>
}

export enum AccountConnectionStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  NOT_SET = 'NOT_SET',
}

export type AccountEstimatedDebitDate = {
  date: Scalars['ISO8601Date']
}

export type AccountInputObject = {
  accountNumber: Scalars['String']
  archived?: InputMaybe<Scalars['Boolean']>
  bankAddress?: InputMaybe<AddressInputObject>
  bankName?: InputMaybe<Scalars['String']>
  beneficiaryFirstName?: InputMaybe<Scalars['String']>
  beneficiaryLastName?: InputMaybe<Scalars['String']>
  beneficiaryName?: InputMaybe<Scalars['String']>
  bicSwift?: InputMaybe<Scalars['String']>
  cnaps?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  destinationCountry: Scalars['String']
  routingNumber?: InputMaybe<Scalars['String']>
  wireFields?: InputMaybe<Array<InternationalWireFieldsDataFieldInputObject>>
  wireRoutingNumber?: InputMaybe<Scalars['String']>
}

export enum AccountTypeEnum {
  PAYING = 'PAYING',
  PAYING_AND_RECEIVING = 'PAYING_AND_RECEIVING',
  RECEIVING = 'RECEIVING',
}

export type AccountantDashboard = {
  /** @deprecated Use firmDisplayName and firmCompanyName */
  accountingFirmName: Scalars['String']
  clients: Array<BusinessClient>
  firmCompanyName: Scalars['String']
  firmDisplayName: Scalars['String']
  teamMembers: Array<TeamMember>
}

export type AccountingAccount = {
  accountSubType: Scalars['String']
  accountType: Scalars['String']
  active: Scalars['Boolean']
  children: Array<AccountingAccount>
  classification: Maybe<Scalars['String']>
  currency: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  number: Maybe<Scalars['String']>
}

export type AccountingCategory = {
  categories: Array<AccountingDictionary>
  categoryType: AccountingCategoryTypeEnum
  label: Scalars['String']
  showOnTxn: Scalars['Boolean']
  showOnTxnLine: Scalars['Boolean']
}

export type AccountingCategoryType = {
  id: Scalars['ID']
}

export enum AccountingCategoryTypeEnum {
  CLASS = 'CLASS',
  DEPARTMENT = 'DEPARTMENT',
  LOCATION = 'LOCATION',
  TRACKING_CATEGORY_ONE = 'TRACKING_CATEGORY_ONE',
  TRACKING_CATEGORY_TWO = 'TRACKING_CATEGORY_TWO',
}

export enum AccountingConnectionStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  NOT_SET = 'NOT_SET',
}

export type AccountingCounters = {
  excludedFromSync: CountersBySyncObject
  recentlySynced: CountersBySyncObject
  syncErrors: CountersBySyncObject
  waitingForSync: CountersBySyncObject
}

export type AccountingDictionary = {
  children: Array<AccountingDictionary>
  id: Scalars['ID']
  name: Scalars['String']
}

export enum AccountingPermissionsEnum {
  ACCOUNTING_LINK = 'ACCOUNTING_LINK',
  ACCOUNTING_SYNC = 'ACCOUNTING_SYNC',
  ACCOUNTING_SYNC_STATE = 'ACCOUNTING_SYNC_STATE',
  ACCOUNTING_UNLINK = 'ACCOUNTING_UNLINK',
  ACCOUNTING_UNPAID_BILLS = 'ACCOUNTING_UNPAID_BILLS',
  ACCOUNTING_UPDATE_PREFERENCES = 'ACCOUNTING_UPDATE_PREFERENCES',
}

export enum AccountingQuickBooksItemTypeEnum {
  CATEGORY = 'CATEGORY',
  INVENTORY = 'INVENTORY',
  NONINVENTORY = 'NONINVENTORY',
  SERVICE = 'SERVICE',
}

export type AccountingState = {
  accountingProvider: Maybe<ProviderEnum>
  accounts: Array<AccountingAccount>
  bankAccounts: Array<AccountingAccount>
  categoryClasses: Maybe<AccountingCategory>
  categoryDepartments: Maybe<AccountingCategory>
  categoryLocations: Maybe<AccountingCategory>
  categoryTrackingCategoryOne: Maybe<AccountingCategory>
  categoryTrackingCategoryTwo: Maybe<AccountingCategory>
  cogsAccounts: Array<AccountingAccount>
  creditCardAccounts: Array<AccountingAccount>
  expenseAccounts: Array<AccountingAccount>
  filteredAccounts: Array<AccountingAccount>
  liabilityAccounts: Array<AccountingAccount>
}

export type AccountingSummary = {
  status: AccountingConnectionStatusEnum
  syncErrorCount: Scalars['Int']
}

export type AccountsReceivableNotificationSettings = {
  paymentCanceledEmail: Scalars['Boolean']
  paymentLandedInAccountEmail: Scalars['Boolean']
  paymentOnItsWayEmail: Scalars['Boolean']
  paymentScheduledEmail: Scalars['Boolean']
}

export type AccountsReceivableNotificationSettingsInputObject = {
  paymentCanceledEmail?: InputMaybe<Scalars['Boolean']>
  paymentLandedInAccountEmail?: InputMaybe<Scalars['Boolean']>
  paymentOnItsWayEmail?: InputMaybe<Scalars['Boolean']>
  paymentScheduledEmail?: InputMaybe<Scalars['Boolean']>
}

export type AccountsSummary = {
  status: AccountConnectionStatusEnum
  unmappedCount: Scalars['Int']
}

export type Action = {
  allowed: Scalars['Boolean']
  errorReasons: Array<Scalars['String']>
}

export type Address = {
  additionalAddressLines: Maybe<Array<Scalars['String']>>
  addressLine: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  country: Maybe<Scalars['String']>
  id: Scalars['ID']
  oneLineFormatted: Scalars['String']
  postalCode: Maybe<Scalars['String']>
  secondaryAddressLine: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
  unitNumber: Maybe<Scalars['String']>
}

export type AddressInputObject = {
  additionalAddressLines?: InputMaybe<Array<Scalars['String']>>
  addressLine?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  secondaryAddressLine?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  unitNumber?: InputMaybe<Scalars['String']>
}

export type AddressOcr = {
  addressLine: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  country: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  rawAddress: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
}

export type AddressValidation = {
  /** Returns false if the address is a PO box, true otherwise. Makes network request to Smarty */
  nonPoBox: Scalars['Boolean']
  /** Returns true if the address is completely verified, false otherwise. Makes network request to Smarty */
  valid: Scalars['Boolean']
}

export type Agreement = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  type: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type AmortizedLoan = {
  /** Annual percentage rate (APR) for the loan */
  apr: Scalars['Float']
  /** The current installment (last failed, next scheduled or the last one) */
  currentInstallment: AmortizedLoanInstallment
  /** Planned or actual installments schedule */
  installments: Array<AmortizedLoanInstallment>
  /** Sum of interest accrued */
  interest: Money
  /** Number of days in each period */
  periodDays: Scalars['Int']
  /** Number of periods ending with installment payment */
  periods: Scalars['Int']
  /** Sum of principal repayments */
  principal: Money
  /** Sum of all installment payments */
  total: Money
  /** Sum of days in all periods */
  totalDays: Scalars['Int']
}

export type AmortizedLoanInstallment = {
  /** Installment payment date */
  date: Scalars['ISO8601Date']
  /**
   * Full description of installment (eg. "Installment 1", "Installment 2", ..., "Early repayment")
   *
   */
  description: Scalars['String']
  /** Marks installment which is an early repayment */
  earlyRepayment: Scalars['Boolean']
  /** Interest included into installment amount */
  interest: Money
  /** Number of the installment */
  number: Scalars['Int']
  /** Principal repayment included in installment amount */
  principal: Money
  reconciliationItem: Maybe<ReconciliationItem>
  /** Status of the installment */
  status: AmortizedLoanInstallmentStatusEnum
  /** Installment amount */
  total: Money
  /**
   * Marks installment which unlocks EPT balance
   * @deprecated Balance now unlocks without delay. This returns false always now.
   */
  unlocksBalance: Scalars['Boolean']
}

export enum AmortizedLoanInstallmentStatusEnum {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
}

export type ApplicableVendorCredit = {
  applied: Scalars['Boolean']
  appliedAmount: Money
  autoFillAmount: Money
  availableAmount: Money
  canApply: Scalars['Boolean']
  invoice: Invoice
  reason: Maybe<Scalars['String']>
  vendorCredit: VendorCredit
}

export enum ApplicationThemeEnum {
  ESCALON = 'ESCALON',
  SETTLE = 'SETTLE',
}

export enum ApprovalStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  STAGED = 'STAGED',
}

export type Approver = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type AttachedReport = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  url: Scalars['String']
}

export type Attachment = {
  byteSize: Scalars['Int']
  category: Maybe<AttachmentCategoryEnum>
  contentType: Scalars['String']
  conversionPending: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  createdBySettle: Scalars['Boolean']
  description: Maybe<Scalars['String']>
  filename: Scalars['String']
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
  preview: Maybe<AttachmentPreview>
  previewStatus: AttachmentPreviewStatusEnum
  targetId: Maybe<Scalars['Int']>
  targetType: Maybe<Scalars['String']>
  type: Maybe<Scalars['String']>
  uploadedBy: Maybe<TeamMember>
  url: Scalars['String']
}

export enum AttachmentCategoryEnum {
  INVOICE = 'INVOICE',
  PAYMENT_ORDER = 'PAYMENT_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  RECEIPT = 'RECEIPT',
  RECEIVABLE = 'RECEIVABLE',
  SETTLE_INBOX = 'SETTLE_INBOX',
  VENDOR = 'VENDOR',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
  W8_OR_W9 = 'W8_OR_W9',
}

export type AttachmentPreview = {
  x1: Maybe<Scalars['String']>
  x2: Maybe<Scalars['String']>
  x3: Maybe<Scalars['String']>
}

export enum AttachmentPreviewStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
}

export enum AuthProviderTypeEnum {
  GOOGLE = 'GOOGLE',
}

export type AuthResponse = {
  mfaToken: Maybe<Scalars['ID']>
  profile: Maybe<Profile>
  token: Maybe<Scalars['ID']>
  totpSetupInfo: Maybe<TotpSetupInfo>
}

export type AuthorizationPaginated = {
  data: Array<MarketingVirtualCardAuthorization>
  pagination: Pagination
}

export enum BalancePaymentStatusEnum {
  DELINQUENT = 'DELINQUENT',
  PAID = 'PAID',
  WAITING = 'WAITING',
}

export type BalanceSheet = {
  availableCreditCents: Scalars['Int']
  creditLimitCents: Scalars['Int']
  eptLength: Scalars['Int']
  eptLiabilityCents: Scalars['Int']
  eptRate: Scalars['Float']
  id: Scalars['ID']
  maximumExtensionDays: Scalars['Int']
  minimumEptCents: Scalars['Int']
  numEptMax: Scalars['Int']
}

export enum BankAccountsPermissionsEnum {
  ACCOUNT = 'ACCOUNT',
  ACCOUNTS = 'ACCOUNTS',
  LINK_FINICITY = 'LINK_FINICITY',
  LINK_PLAID = 'LINK_PLAID',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
}

/** Common fields of bank account links */
export type BankLink = {
  deleted: Scalars['Boolean']
  fixAvailable: Maybe<BankLinkFixEnum>
  /** @deprecated Use banners instead */
  fixFlowMessage: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export enum BankLinkFixEnum {
  FIX_FLOW = 'FIX_FLOW',
  RELINK = 'RELINK',
}

export type Banner = {
  button: Maybe<BannerButton>
  category: Maybe<BannerCategoryEnum>
  id: Scalars['String']
  message: Scalars['String']
  severity: BannerSeverityEnum
}

export enum BannerActionEnum {
  ADD_BENEFICIAL_OWNER = 'ADD_BENEFICIAL_OWNER',
  CHANGE_BILLING_METHOD = 'CHANGE_BILLING_METHOD',
  DASHBOARD_TO_DO_LIST = 'DASHBOARD_TO_DO_LIST',
  EDIT_BANK_ACCOUNTS = 'EDIT_BANK_ACCOUNTS',
  EDIT_INTEGRATIONS = 'EDIT_INTEGRATIONS',
  EDIT_INTERNATIONAL_RECEIVING_BANK_ACCOUNT = 'EDIT_INTERNATIONAL_RECEIVING_BANK_ACCOUNT',
  EDIT_SYNC_PREFERENCES = 'EDIT_SYNC_PREFERENCES',
  FINICITY_FIX_FLOW = 'FINICITY_FIX_FLOW',
  HELP_CENTER = 'HELP_CENTER',
  INVITE_CUSTOMERS = 'INVITE_CUSTOMERS',
  LINK_UNVERIFIED_BANK_ACCOUNT = 'LINK_UNVERIFIED_BANK_ACCOUNT',
  PLAID_FIX_FLOW = 'PLAID_FIX_FLOW',
  PROVIDE_BUSINESS_INFO = 'PROVIDE_BUSINESS_INFO',
  VERIFY_BANK_ACCOUNT = 'VERIFY_BANK_ACCOUNT',
}

export type BannerActionParams = {
  accountId: Maybe<Scalars['Int']>
  connectionId: Maybe<Scalars['Int']>
}

export type BannerButton = {
  action: Maybe<BannerActionEnum>
  actionParams: Maybe<BannerActionParams>
  text: Scalars['String']
}

export enum BannerCategoryEnum {
  ACCOUNTING_SOFTWARE = 'ACCOUNTING_SOFTWARE',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  BANK_ACCOUNTS_INTEGRATION = 'BANK_ACCOUNTS_INTEGRATION',
  BILLING = 'BILLING',
  MARKETNG = 'MARKETNG',
  ONBOARDING = 'ONBOARDING',
  RISK = 'RISK',
}

export enum BannerSeverityEnum {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export type Banners = {
  banners: Array<Banner>
}

export type BaseExternalPayableLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  quickbooksCategoryId?: InputMaybe<Scalars['ID']>
  quickbooksClassId?: InputMaybe<Scalars['ID']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  xeroCategoryId?: InputMaybe<Scalars['ID']>
}

export type BeneficialOwner = {
  createdAt: Scalars['ISO8601Date']
  createdBy: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  significantControl: Scalars['Boolean']
  status: BeneficialOwnerStatusEnum
}

export type BeneficialOwnerIndividualInputType = {
  dataFields: DataFieldsInput
  significantControl?: InputMaybe<Scalars['Boolean']>
}

export enum BeneficialOwnerStatusEnum {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DECLINED = 'DECLINED',
  FLAGGED = 'FLAGGED',
  IN_PROGRESS = 'IN_PROGRESS',
  REMOVED = 'REMOVED',
}

/** Autogenerated return type of BeneficialOwnersApprove. */
export type BeneficialOwnersApprovePayload = {
  success: Scalars['Boolean']
}

export type BeneficialOwnersDataFields = {
  communication: Maybe<BeneficialOwnersSectionFields>
  countrySpecific: Maybe<BeneficialOwnersSectionFields>
  driverLicence: Maybe<BeneficialOwnersSectionFields>
  location: Maybe<BeneficialOwnersSectionFields>
  nationalIds: Maybe<BeneficialOwnersSectionFields>
  passport: Maybe<BeneficialOwnersSectionFields>
  personInfo: Maybe<BeneficialOwnersSectionFields>
}

/** Autogenerated return type of BeneficialOwnersDecline. */
export type BeneficialOwnersDeclinePayload = {
  success: Scalars['Boolean']
}

export type BeneficialOwnersFieldDescription = {
  description: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  nestedIn: Maybe<Scalars['String']>
  propertyKey: Scalars['String']
  type: Scalars['String']
}

export type BeneficialOwnersSectionFields = {
  fields: Maybe<Array<BeneficialOwnersFieldDescription>>
  required: Maybe<Array<Scalars['String']>>
}

/** Autogenerated return type of BeneficialOwnersStartReview. */
export type BeneficialOwnersStartReviewPayload = {
  success: Scalars['Boolean']
}

export type BillingCouponType = {
  active: Scalars['Boolean']
  description: Scalars['String']
  displayName: Scalars['String']
  id: Scalars['ID']
  termsLink: Scalars['String']
}

export type BillingDiscountType = {
  activatedAt: Scalars['ISO8601DateTime']
  active: Scalars['Boolean']
  coupon: BillingCouponType
  expiresAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  promotionCode: Maybe<BillingPromotionCodeType>
}

export type BillingDowngradeDetailsType = {
  description: Scalars['String']
  name: Scalars['String']
}

export type BillingExtendedDowngradeDetailsType = {
  featureGroupTitle: Scalars['String']
  features: Array<BillingDowngradeDetailsType>
}

export type BillingFeatureType = {
  key: FeatureKeyEnum
  name: Scalars['String']
}

export type BillingHistoryEntryType = {
  amount: Money
  billingMethod: Maybe<BillingMethod>
  billingPaymentId: Scalars['ID']
  date: Scalars['ISO8601DateTime']
  periodEnd: Scalars['ISO8601DateTime']
  periodStart: Scalars['ISO8601DateTime']
  planName: Maybe<Scalars['String']>
  status: BillingPaymentStatusEnum
}

export type BillingHistoryItem = {
  amount: Money
  name: Scalars['String']
  paidAt: Scalars['ISO8601Date']
}

export type BillingLimitType = {
  name: Scalars['String']
  type: Scalars['String']
  value: Scalars['String']
}

export type BillingMethod = {
  default: Scalars['Boolean']
  id: Scalars['ID']
  source: BillingMethodSource
}

export type BillingMethodSource = Account | PaymentCard

export enum BillingPaymentStatusEnum {
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  RETRY_INITIATED = 'RETRY_INITIATED',
}

export type BillingPlan = {
  amount: Scalars['String']
  availableFeatures: Array<BillingPlanFeaturesEnum>
  /** @deprecated Use availableFeatures instead */
  features: Array<BillingPlanFeature>
  id: Scalars['ID']
  name: Scalars['String']
  transactionsLimit: Scalars['String']
}

export type BillingPlanFeature = {
  name: Scalars['String']
  status: BillingPlanFeatureStatusEnum
}

export enum BillingPlanFeatureStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type BillingPlanFeatures = {
  feature: BillingPlanFeaturesEnumV2
  supportedByPlan: Scalars['Boolean']
  usageData: BillingPlanUsageData
}

export enum BillingPlanFeaturesEnum {
  APPROVAL_AUTOMATIONS = 'APPROVAL_AUTOMATIONS',
  FREE_ACH = 'FREE_ACH',
  FREE_DOMESTIC_WIRE = 'FREE_DOMESTIC_WIRE',
  FREE_INTERNATIONAL_WIRE = 'FREE_INTERNATIONAL_WIRE',
  FREE_PAPER_CHECK = 'FREE_PAPER_CHECK',
  INTEGRATIONS = 'INTEGRATIONS',
  TEXT_RECOGNITION = 'TEXT_RECOGNITION',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
}

export enum BillingPlanFeaturesEnumV2 {
  ADHOC_INVOICE_APPROVALS = 'ADHOC_INVOICE_APPROVALS',
  ADVANCED_AND_CUSTOM_USER_ROLES = 'ADVANCED_AND_CUSTOM_USER_ROLES',
  APPROVAL_WORKFLOW_RULES = 'APPROVAL_WORKFLOW_RULES',
  BASIC_USER_ROLES = 'BASIC_USER_ROLES',
  CASH_FLOW_CHART = 'CASH_FLOW_CHART',
  ITEMIZED_ACCOUNTING = 'ITEMIZED_ACCOUNTING',
  OCR = 'OCR',
  PREMIUM_CUSTOMER_SUPPORT = 'PREMIUM_CUSTOMER_SUPPORT',
  PURCHASE_ORDER_MANAGEMENT = 'PURCHASE_ORDER_MANAGEMENT',
  PURCHASING_SUITE = 'PURCHASING_SUITE',
  SEATS_AVAILABLE = 'SEATS_AVAILABLE',
  SETTLE_INBOX = 'SETTLE_INBOX',
  SPLIT_PAY_AND_VENDOR_CREDITS = 'SPLIT_PAY_AND_VENDOR_CREDITS',
  TRANSACTIONS = 'TRANSACTIONS',
  TWO_WAY_SYNC = 'TWO_WAY_SYNC',
  VENDOR_MANAGEMENT = 'VENDOR_MANAGEMENT',
  VENDOR_W9_STORAGE_AND_1099 = 'VENDOR_W9_STORAGE_AND_1099',
}

export enum BillingPlanPermissionsEnum {
  BILLING_PLANS = 'BILLING_PLANS',
  CHANGE_BILLING_PLAN = 'CHANGE_BILLING_PLAN',
}

export type BillingPlanType = {
  basePlanType: Maybe<BillingPlanTypeEnum>
  description: Maybe<Scalars['String']>
  features: Array<BillingFeatureType>
  id: Scalars['ID']
  limits: Array<BillingLimitType>
  name: Scalars['String']
  pricePerMonth: Money
  pricing: BillingPricingType
  type: BillingPlanTypeEnum
}

export enum BillingPlanTypeEnum {
  ACCOUNTING_BASIC = 'ACCOUNTING_BASIC',
  ACCOUNTING_FIRM = 'ACCOUNTING_FIRM',
  ACCOUNTING_PLUS = 'ACCOUNTING_PLUS',
  BASIC = 'BASIC',
  CUSTOM = 'CUSTOM',
  /** @deprecated Will be removed after migration from free */
  FREE = 'FREE',
  PLUS = 'PLUS',
}

export type BillingPlanUpgrade = {
  amount: Money
  currentPeriodStart: Scalars['ISO8601Date']
  prorated: Scalars['Boolean']
}

export type BillingPlanUsageData = {
  currentUsage: Maybe<Scalars['Int']>
  limitReached: Maybe<Scalars['Boolean']>
  usageLimit: Maybe<Scalars['Int']>
}

export type BillingPriceType = {
  name: Scalars['String']
  type: Scalars['String']
  unitAmountCents: Maybe<Scalars['String']>
  unitAmountCurrency: Maybe<Scalars['String']>
  unitAmountFormatted: Scalars['String']
}

export type BillingPricingType = {
  achTransfer: BillingPriceType
  domesticWireTransfer: BillingPriceType
  internationalWireTransfer: BillingPriceType
  paperCheckTransfer: BillingPriceType
  sameDayAchTransfer: BillingPriceType
}

export type BillingPromotionCodeType = {
  active: Scalars['Boolean']
  code: Scalars['String']
  coupon: BillingCouponType
  id: Scalars['ID']
}

export type BillsSummary = {
  inboxCount: Scalars['Int']
  rejectedCount: Scalars['Int']
}

export type Blob = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  type: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type BlockLocalBusinessPayments = {
  reason: BlockLocalBusinessPaymentsReasonEnum
}

export enum BlockLocalBusinessPaymentsReasonEnum {
  PUBLIC_BUSINESS_MATCH_AVAILABLE = 'PUBLIC_BUSINESS_MATCH_AVAILABLE',
}

export type BlockPayments = {
  reason: ReasonEnum
}

export type BulkPayBase = {
  invoice: Maybe<Invoice>
  message: Maybe<Scalars['String']>
  payment: Maybe<Payment>
  success: Scalars['Boolean']
}

export type BulkPayInputObjectV2 = {
  amount?: InputMaybe<MoneyInputObject>
  invoiceId: Scalars['ID']
  toAccountId?: InputMaybe<Scalars['ID']>
}

export type BulletEpt = {
  /** Annual percentage rate (APR) for the loan */
  apr: Scalars['Float']
  /** Planned or actual charges schedule */
  charges: Array<BulletEptCharge>
  /** The current installment (last failed, next scheduled or the last one) */
  currentCharge: BulletEptCharge
  /** Sum of interest accrued */
  interest: Money
  /** Number of days in each period */
  periodDays: Scalars['Int']
  /** Percentage of principal charged each period as interest */
  periodRate: Scalars['Float']
  /** Number of periods ending with installment payment */
  periods: Scalars['Int']
  /** Sum of principal repayments */
  principal: Money
  /** Whether or not this EPT schedule is active on the payment */
  selected: Scalars['Boolean']
  /** Sum of all installment payments */
  total: Money
  /** Sum of days in all periods */
  totalDays: Scalars['Int']
}

export type BulletEptCharge = {
  /** Installment payment date */
  date: Scalars['ISO8601Date']
  /** Full description of charge */
  description: Scalars['String']
  /** Interest included into installment amount */
  interest: Money
  /** Number of the installment */
  number: Scalars['Int']
  /** Percentage of principal charged each period as interest */
  periodRate: Scalars['Float']
  /** Principal repayment included in installment amount */
  principal: Money
  reconciliationItem: Maybe<ReconciliationItem>
  /** Status of the installment */
  status: AmortizedLoanInstallmentStatusEnum
  /** Installment amount */
  total: Money
}

export type Business = {
  actions: BusinessActions
  address: Maybe<Address>
  /** Returns true when amortization configured and enabled for the business */
  amortizationAvailable: Scalars['Boolean']
  appliedForFinancing: Scalars['Boolean']
  balanceSheet: Maybe<BalanceSheet>
  billPayFromPayer: Scalars['Boolean']
  billPayToVendor: Scalars['Boolean']
  billingPlan: Maybe<BillingPlan>
  blockPayments: Maybe<BlockPayments>
  /** @deprecated use blockPayments instead. */
  blockPaymentsError: Maybe<ErrorDialog>
  businessStructure: Maybe<BusinessStructureEnum>
  companyName: Maybe<Scalars['String']>
  counters: Counters
  country: Maybe<Scalars['String']>
  /** Credit limit status of current business */
  creditLimitStatus: Maybe<BusinessCreditLimitStatusEnum>
  current: Scalars['Boolean']
  demoAvailable: Scalars['Boolean']
  displayName: Scalars['String']
  doingBusinessAsNames: Maybe<Array<Scalars['String']>>
  enabledFeatures: Array<BusinessFeaturesEnum>
  eptFromPayer: Scalars['Boolean']
  eptMetrics: Maybe<EptMetrics>
  eptRate: Maybe<EptRate>
  eptToVendor: Scalars['Boolean']
  financingNudgeBanner: Maybe<FinancingNudgeBanner>
  firstName: Maybe<Scalars['String']>
  hasAmortizedPayments: Scalars['Boolean']
  hasBeptPayments: Scalars['Boolean']
  hasEptPayments: Scalars['Boolean']
  hasRefunds: Scalars['Boolean']
  id: Scalars['ID']
  industry: Maybe<BusinessIndustryEnum>
  inputStateOfFormation: Maybe<Scalars['String']>
  internalEmail: Maybe<Scalars['String']>
  is1099Eligible: Maybe<Scalars['Boolean']>
  kybBusinessStatus: Maybe<KybStatusEnum>
  kybStatus: KybStatusEnum
  kybUboStatus: Maybe<KybStatusEnum>
  lastName: Maybe<Scalars['String']>
  /** @deprecated Use query.journeys instead */
  lendingChecklistItemsRequired: Scalars['Boolean']
  lockboxEnabled: Maybe<Scalars['Boolean']>
  logo: Maybe<Blob>
  mailingAddress: Maybe<Address>
  marketingVirtualCardDefaultRepaymentPercent: Scalars['Float']
  marketingVirtualCardEnabled: Scalars['Boolean']
  mfaRequired: Scalars['Boolean']
  /** @deprecated Use companyName instead */
  name: Maybe<Scalars['String']>
  netsuiteLinked: Scalars['Boolean']
  newBillingPlan: Maybe<BillingPlanType>
  officialName: Scalars['String']
  onboardingStatus: Maybe<OnboardingStatusEnum>
  partnerPricingEnabled: Scalars['Boolean']
  payerOnboarding: Maybe<PayerOnboarding>
  plaidLinked: Scalars['Boolean']
  preferredPayingAccount: Maybe<Account>
  preferredReceivingAccount: Maybe<Account>
  pricePerDomesticWire: Maybe<Money>
  pricePerInternationalWire: Maybe<Money>
  /** @deprecated Use pricePerDomesticWire or pricePerInternationWire instead */
  pricePerWire: Maybe<Money>
  pusherChannelId: Scalars['String']
  quickBooksLinked: Scalars['Boolean']
  quickBooksState: Maybe<QuickBooksState>
  receivablesEnabled: Scalars['Boolean']
  /** @deprecated Use preferredReceivingAccount instead */
  receivingAccount: Maybe<LocalBusinessAccount>
  /** @deprecated replaced by feature gating framework */
  requiresSubscriptionUpgrade: Scalars['Boolean']
  roles: Array<BusinessRoleEnum>
  rulesCount: Scalars['Int']
  selectablePaymentMethods: Array<PaymentMethodEnum>
  showPayablesSignup: Maybe<Scalars['Boolean']>
  /** @deprecated banner deprecated */
  showStatusesRenamingBanner: Scalars['Boolean']
  signupInterests: Maybe<Array<SignupInterestsEnum>>
  taxpayerId: Maybe<Scalars['String']>
  underwritingStatus: BusinessUnderwritingStatusEnum
  /** W9 form document uploaded by the current business */
  w9Form: Maybe<Attachment>
  website: Maybe<Scalars['String']>
  xeroLinked: Scalars['Boolean']
}

export type BusinessActions = {
  editDetails: Action
  exportCashFlowReport: Action
}

export type BusinessBeneficialOwnerInput = {
  address: AddressInputObject
  countryOfFormation?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  legalName: Scalars['String']
  stateOfFormation?: InputMaybe<Scalars['String']>
  taxpayerId: Scalars['String']
}

export type BusinessClient = {
  business: PublicBusiness
  teamMemberships: Array<UserClient>
}

export enum BusinessComplianceStatusEnum {
  AWAITING_INFO = 'AWAITING_INFO',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  VERIFIED = 'VERIFIED',
}

export enum BusinessCreditLimitStatusEnum {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum BusinessDetailsPermissionsEnum {
  ADD_BENEFICIAL_OWNERS = 'ADD_BENEFICIAL_OWNERS',
  UPDATE_BUSINESS_DETAILS = 'UPDATE_BUSINESS_DETAILS',
  VIEW_BENEFICIAL_OWNERS = 'VIEW_BENEFICIAL_OWNERS',
}

export type BusinessFeaturesActions = {
  updateVendorDunning: Action
}

export enum BusinessFeaturesEnum {
  APPROVAL_RULES = 'APPROVAL_RULES',
  INVOICE_TIMELINE = 'INVOICE_TIMELINE',
  REQUEST_INVOICE_APPROVALS = 'REQUEST_INVOICE_APPROVALS',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
}

export type BusinessFeaturesInputObject = {
  vendorDunning?: InputMaybe<Scalars['Boolean']>
}

export enum BusinessFeaturesPermissionsEnum {
  UPDATE_VENDOR_DUNNING = 'UPDATE_VENDOR_DUNNING',
}

export type BusinessFeaturesType = {
  actions: BusinessFeaturesActions
  vendorDunning: Scalars['Boolean']
}

export enum BusinessIndustryEnum {
  BEAUTY = 'BEAUTY',
  CLOTHING_APPAREL = 'CLOTHING_APPAREL',
  CONSTRUCTION = 'CONSTRUCTION',
  ELECTRONICS = 'ELECTRONICS',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  FOOD_BEVERAGE = 'FOOD_BEVERAGE',
  FURNITURE = 'FURNITURE',
  JEWELERY = 'JEWELERY',
  MARKETING = 'MARKETING',
  OTHER = 'OTHER',
  SERVICES = 'SERVICES',
  SPORTS = 'SPORTS',
  TOYS = 'TOYS',
  VIRTUAL_SERVICES = 'VIRTUAL_SERVICES',
}

export type BusinessMatch = {
  business: PublicBusiness
  id: Scalars['ID']
  localBusiness: LocalBusiness
  matchedOn: Array<Scalars['String']>
  status: BusinessMatchStatusEnum
}

export enum BusinessMatchStatusEnum {
  MERGED = 'MERGED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum BusinessRoleEnum {
  ACCOUNTING_FIRM = 'ACCOUNTING_FIRM',
  PAYER = 'PAYER',
  VENDOR = 'VENDOR',
}

export enum BusinessStructureEnum {
  BUSINESS_GENERAL = 'BUSINESS_GENERAL',
  C_CORPORATION = 'C_CORPORATION',
  INDIVIDUAL = 'INDIVIDUAL',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  LLC = 'LLC',
  NON_PROFIT = 'NON_PROFIT',
  OTHER_BUSINESS = 'OTHER_BUSINESS',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROP = 'SOLE_PROP',
  S_CORPORATION = 'S_CORPORATION',
}

export enum BusinessTraitsEnum {
  ACCOUNTING_FIRM = 'ACCOUNTING_FIRM',
  BILLING_PLAN_BASIC = 'BILLING_PLAN_BASIC',
  BILLING_PLAN_PLUS = 'BILLING_PLAN_PLUS',
  INDIVIDUAL = 'INDIVIDUAL',
  IS_1099_ELIGIBLE = 'IS_1099_ELIGIBLE',
  LEGACY_BILLING_PLAN = 'LEGACY_BILLING_PLAN',
  MFA_REQUIRED = 'MFA_REQUIRED',
  MICRO_DEPOSITS_SENT = 'MICRO_DEPOSITS_SENT',
  MICRO_DEPOSITS_WAITING = 'MICRO_DEPOSITS_WAITING',
  MIDDESK_VERIFIED = 'MIDDESK_VERIFIED',
  ONBOARDING_COMPLETE_BIZ_DETAILS = 'ONBOARDING_COMPLETE_BIZ_DETAILS',
  ONBOARDING_STEPS_COMPLETED = 'ONBOARDING_STEPS_COMPLETED',
  ONBOARDING_STEP_ADD_BENEFICIAL_OWNERS = 'ONBOARDING_STEP_ADD_BENEFICIAL_OWNERS',
  ONBOARDING_STEP_COMPLETE_BUSINESS_DETAILS_SKIPPED = 'ONBOARDING_STEP_COMPLETE_BUSINESS_DETAILS_SKIPPED',
  ONBOARDING_STEP_CONNECT_ACCOUNTING_IGNORED = 'ONBOARDING_STEP_CONNECT_ACCOUNTING_IGNORED',
  ONBOARDING_STEP_CONNECT_ACCOUNTS_SKIPPED = 'ONBOARDING_STEP_CONNECT_ACCOUNTS_SKIPPED',
  ONBOARDING_STEP_CONNECT_BANK_ACCOUNT = 'ONBOARDING_STEP_CONNECT_BANK_ACCOUNT',
  PAYER = 'PAYER',
  SOLE_PROP = 'SOLE_PROP',
  UNDERWRITTEN = 'UNDERWRITTEN',
  VENDOR = 'VENDOR',
  VERIFICATION_FILLED = 'VERIFICATION_FILLED',
  VERIFIED = 'VERIFIED',
  VERIFIED_INTERNATIONAL = 'VERIFIED_INTERNATIONAL',
  VERIFIED_WITHOUT_RECEIVING_ACCOUNT = 'VERIFIED_WITHOUT_RECEIVING_ACCOUNT',
  WITH_ACCOUNT_BILLING_METHOD = 'WITH_ACCOUNT_BILLING_METHOD',
  WITH_AMORTIZATION = 'WITH_AMORTIZATION',
  WITH_CARD_BILLING_METHOD = 'WITH_CARD_BILLING_METHOD',
  WITH_CLIENT = 'WITH_CLIENT',
  WITH_EPT_LIMIT = 'WITH_EPT_LIMIT',
  WITH_FRAUD_APPROVAL = 'WITH_FRAUD_APPROVAL',
  WITH_PAYEE_AGENT_DISCLOSURE = 'WITH_PAYEE_AGENT_DISCLOSURE',
  WITH_UBO = 'WITH_UBO',
  WITH_W9_FORM = 'WITH_W9_FORM',
  WITH_ZERO_EPT_LIMIT = 'WITH_ZERO_EPT_LIMIT',
}

export enum BusinessUnderwritingStatusEnum {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  MISSING_BANK_ACCOUNT = 'MISSING_BANK_ACCOUNT',
  PENDING = 'PENDING',
}

export type CashOutflow = {
  endDate: Scalars['ISO8601Date']
  eptPayments: Money
  failed: Money
  fees: Money
  marketingCardPayments: Money
  nonfinancedPayments: Money
  pendingApproval: Money
  startDate: Scalars['ISO8601Date']
  total: Money
}

export type CatalogItem = {
  accountingCategoryClass: Maybe<AccountingDictionary>
  accountingCategoryDepartment: Maybe<AccountingDictionary>
  accountingCategoryLocation: Maybe<AccountingDictionary>
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingType: Maybe<AccountingQuickBooksItemTypeEnum>
  accountingUrl: Maybe<Scalars['String']>
  actions: CatalogItemActions
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  description: Maybe<Scalars['String']>
  expenseAccountingAccount: Maybe<AccountingAccount>
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
  pricePerVariation: Scalars['Boolean']
  properties: Array<CatalogItemProperty>
  /** @deprecated Use fields directly from this object */
  purchaseDetails: Maybe<PurchaseDetailsType>
  showSyncHistory: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  skuPerVariation: Scalars['Boolean']
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  unitOfMeasure: Maybe<Scalars['String']>
  variations: Array<CatalogItemVariation>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type CatalogItemActions = {
  updateVendor: Action
}

export type CatalogItemInputObject = {
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  amount?: InputMaybe<MoneyInputObject>
  description?: InputMaybe<Scalars['String']>
  expenseAccountingAccountId?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  pricePerVariation?: InputMaybe<Scalars['Boolean']>
  sku?: InputMaybe<Scalars['String']>
  skuPerVariation?: InputMaybe<Scalars['Boolean']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type CatalogItemProperty = {
  archived: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  values: Array<CatalogItemPropertyValue>
}

export type CatalogItemPropertyInputObject = {
  name: Scalars['String']
  values: Array<CatalogItemPropertyValuesInputObject>
}

export type CatalogItemPropertyValue = {
  archived: Scalars['Boolean']
  id: Scalars['ID']
  value: Scalars['String']
}

export type CatalogItemPropertyValuesInputObject = {
  id?: InputMaybe<Scalars['ID']>
  value: Scalars['String']
}

export type CatalogItemVariation = {
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
  propertyValues: Array<CatalogItemPropertyValue>
  shortName: Maybe<Scalars['String']>
  sku: Maybe<Scalars['String']>
}

export type CatalogItemVariationInputObject = {
  amount?: InputMaybe<MoneyInputObject>
  sku?: InputMaybe<Scalars['String']>
}

export type CatalogItems = {
  data: Array<CatalogItem>
  pagination: Pagination
}

export type CatalogItemsFilteringInputObject = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export enum CategoryEnum {
  AP_AGING_CSV = 'AP_AGING_CSV',
  EXORT_AP_AGING_SUMMARY_CSV = 'EXORT_AP_AGING_SUMMARY_CSV',
  EXPORT_1099_CSV = 'EXPORT_1099_CSV',
  EXPORT_AP_AGING_DETAILS_CSV = 'EXPORT_AP_AGING_DETAILS_CSV',
  EXPORT_CASH_OUTFLOW = 'EXPORT_CASH_OUTFLOW',
  EXPORT_EXTENDED_PAYMENT_TERM_AGREEMENTS = 'EXPORT_EXTENDED_PAYMENT_TERM_AGREEMENTS',
  EXPORT_FACTORING_AGREEMENTS = 'EXPORT_FACTORING_AGREEMENTS',
  EXPORT_HISTORICAL_1099_CSV = 'EXPORT_HISTORICAL_1099_CSV',
  EXPORT_HOLDING_ACCOUNT_TRANSACTIONS = 'EXPORT_HOLDING_ACCOUNT_TRANSACTIONS',
  EXPORT_INVOICES_TO_CSV = 'EXPORT_INVOICES_TO_CSV',
  EXPORT_MVC_TRANSACTIONS_TO_CSV = 'EXPORT_MVC_TRANSACTIONS_TO_CSV',
  EXPORT_MY_INVOICES_TO_CSV = 'EXPORT_MY_INVOICES_TO_CSV',
  EXPORT_OWED_TO_SETTLE = 'EXPORT_OWED_TO_SETTLE',
  EXPORT_PAYEE_AGENT_DISCLOSURES = 'EXPORT_PAYEE_AGENT_DISCLOSURES',
  EXPORT_PAYMENT_CONFIRMATIONS = 'EXPORT_PAYMENT_CONFIRMATIONS',
  EXPORT_PURCHASE_ORDER = 'EXPORT_PURCHASE_ORDER',
  EXPORT_RECEIVABLES_TO_CSV = 'EXPORT_RECEIVABLES_TO_CSV',
  EXPORT_RECONCILIATION_ITEMS_TO_CSV = 'EXPORT_RECONCILIATION_ITEMS_TO_CSV',
  EXPORT_VENDOR_PAYMENT_CONFIRMATIONS = 'EXPORT_VENDOR_PAYMENT_CONFIRMATIONS',
}

export type ChecklistItem = {
  data: Maybe<ChecklistItemData>
  item: ChecklistItemEnum
  status: ChecklistItemStatusEnum
}

export type ChecklistItemData = {
  dataRequestId: Maybe<Scalars['ID']>
  documentType: Maybe<DocumentTypeEnum>
  documentUploadRequestId: Maybe<Scalars['ID']>
  latestAccountingDate: Maybe<Scalars['ISO8601Date']>
  targetDescription: Maybe<Scalars['String']>
}

export enum ChecklistItemEnum {
  ADD_BENEFICIAL_OWNERS = 'ADD_BENEFICIAL_OWNERS',
  COMPLETE_BUSINESS_DETAILS = 'COMPLETE_BUSINESS_DETAILS',
  CONNECT_ACCOUNTING = 'CONNECT_ACCOUNTING',
  CONNECT_BANK_ACCOUNT = 'CONNECT_BANK_ACCOUNT',
  CREATE_OR_UPLOAD_PURCHASE_ORDER = 'CREATE_OR_UPLOAD_PURCHASE_ORDER',
  DATA_REQUEST = 'DATA_REQUEST',
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  IMPORT_BILLS = 'IMPORT_BILLS',
  UPLOAD_BILL = 'UPLOAD_BILL',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export enum ChecklistItemStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
}

export type ClientApplication = {
  name: Scalars['String']
}

export type ClientManagerAssignmentInputObject = {
  role?: InputMaybe<MembershipRolesEnum>
  roleId?: InputMaybe<Scalars['ID']>
  userId: Scalars['ID']
}

export type ClientManagerDashboard = {
  data: Array<ClientSummary>
  pagination: Pagination
}

export enum ClientManagerPermissionsEnum {
  CLIENT_MANAGER_TEAM_MEMBERS = 'CLIENT_MANAGER_TEAM_MEMBERS',
  MANAGE_CLIENTS = 'MANAGE_CLIENTS',
  MANAGE_CLIENT_MANAGER_TEAM_MEMBERS = 'MANAGE_CLIENT_MANAGER_TEAM_MEMBERS',
}

export type ClientSummary = {
  accounting: AccountingSummary
  accounts: AccountsSummary
  bills: BillsSummary
  complianceStatus: Maybe<BusinessComplianceStatusEnum>
  displayName: Scalars['String']
  id: Scalars['ID']
  payments: PaymentsSummary
  status: ClientSummaryStatusEnum
  /** @deprecated Use teamMemberships instead */
  teamMembers: Array<TeamMember>
  teamMemberships: Array<Membership>
}

export enum ClientSummaryStatusEnum {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED',
}

/** Fetches referrer link data for a given user/business pair */
export type Code = {
  accountingFirm: Scalars['Boolean']
  referralCode: Scalars['String']
  referralCount: Scalars['Int']
}

/** Fetches referrer link data for a given user/business pair */
export type CodeTypeV2 = {
  referralCode: Scalars['String']
  referralCount: Scalars['Int']
  referrerPromotionAmount: Money
}

export type CombinedLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['Int']>
  amountCurrency?: InputMaybe<Scalars['String']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  isInventory: Scalars['Boolean']
  quantity?: InputMaybe<Scalars['Float']>
}

export type Counters = {
  all: Scalars['Int']
  allBySource: CountersBySource
  allInboxMessages: Scalars['Int']
  allUnread: Scalars['Int']
  archived: Scalars['Int']
  archivedInboxMessages: Scalars['Int']
  draftReceivables: Scalars['Int']
  failedReconciliationItems: Scalars['Int']
  /** @deprecated Use receivableInvoices instead */
  myInvoices: Scalars['Int']
  paid: Scalars['Int']
  paidReceivables: Scalars['Int']
  /** @deprecated Use unscheduled field instead */
  pending: Scalars['Int']
  receivableInvoices: Scalars['Int']
  receivables: Scalars['Int']
  reconciliationItemsInReview: Scalars['Int']
  scheduled: Scalars['Int']
  scheduledReceivables: Scalars['Int']
  scheduledReconciliationItems: Scalars['Int']
  unreadBySource: CountersBySource
  unreadInboxMessages: Scalars['Int']
  unscheduled: Scalars['Int']
  unscheduledReceivables: Scalars['Int']
  vmcUnpaid: Scalars['Int']
}

export type CountersBySource = {
  email: Scalars['Int']
  eptFee: Scalars['Int']
  quickbooksImport: Scalars['Int']
  uploaded: Scalars['Int']
  vmc: Scalars['Int']
  vmcFee: Scalars['Int']
  xeroImport: Scalars['Int']
}

export type CountersBySyncObject = {
  catalogItems: Scalars['Int']
  invoices: Scalars['Int']
  vendorCredits: Scalars['Int']
}

export type Country = {
  accountNumberType: VendorAccountNumberTypeEnum
  code: Scalars['String']
  domestic: Scalars['Boolean']
  name: Scalars['String']
}

/** Autogenerated return type of CreateBusiness. */
export type CreateBusinessPayload = {
  businessId: Scalars['String']
}

/** Autogenerated return type of CreateDocumentRequestItem. */
export type CreateDocumentRequestItemPayload = {
  success: Scalars['Boolean']
}

/** Autogenerated return type of CreateInvitationLink. */
export type CreateInvitationLinkPayload = {
  invitationLink: Scalars['String']
}

/** Autogenerated return type of CreateInvitation. */
export type CreateInvitationPayload = {
  invitationUrl: Scalars['String']
}

export type CreatePayableInvoiceResponse = {
  invoice: Invoice
  settleInboxMessage: SettleInboxMessage
}

export type CreateTotpResponse = {
  mfaToken: Scalars['ID']
  totpSetupInfo: TotpSetupInfo
}

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  token: Scalars['ID']
  userId: Scalars['String']
}

export type CreateVendorCreditResponseType = {
  settleInboxMessage: SettleInboxMessage
  vendorCredit: VendorCredit
}

export type CreateW9ResponseType = {
  settleInboxMessage: SettleInboxMessage
  w9Form: Attachment
}

export type CreatedDocument = Attachment | Invoice | VendorCredit

export type Currency = {
  decimalMark: Scalars['String']
  exponent: Scalars['Int']
  isoCode: Scalars['String']
  subunitToUnit: Scalars['Int']
  symbol: Scalars['String']
  thousandsSeparator: Scalars['String']
}

export type Dashboard = {
  actionableItems: DashboardActionableItemConnection
  bankAccounts: Maybe<Array<DashboardBankAccount>>
  checklistItems: Maybe<Array<ChecklistItem>>
  /** @deprecated Use businesses.balanceSheet for getting liability instead. */
  extendedTerms: Maybe<DashboardExtendedTerms>
  paid: Maybe<DashboardPaid>
  scheduledAndProcessing: Maybe<DashboardScheduledAndProcessing>
  toDos: Array<JourneyStep>
  totalOwe: Maybe<DashboardTotalOwe>
  unscheduled: Maybe<DashboardUnscheduled>
  virtualMarketingCard: Maybe<DashboardVirtualMarketingCard>
}

export type DashboardactionableItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DashboardActionableItem = {
  invoice: Maybe<Invoice>
  invoiceReasons: Array<TransferActionableItem>
  receivable: Maybe<Receivable>
  receivableReason: Maybe<DashboardActionableItemReasonEnum>
}

/** The connection type for DashboardActionableItem. */
export type DashboardActionableItemConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DashboardActionableItemEdge>>>
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DashboardActionableItem>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type DashboardActionableItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<DashboardActionableItem>
}

export enum DashboardActionableItemReasonEnum {
  AT_RISK_PAYMENT = 'AT_RISK_PAYMENT',
  EPT_EXTENSION_AVAILABLE = 'EPT_EXTENSION_AVAILABLE',
  FAILED_INVOICE = 'FAILED_INVOICE',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  OVERDUE_OPEN_BILL = 'OVERDUE_OPEN_BILL',
  PENDING_APPROVAL_BILL = 'PENDING_APPROVAL_BILL',
  PENDING_APPROVAL_PAYMENT = 'PENDING_APPROVAL_PAYMENT',
  PENDING_INVOICE = 'PENDING_INVOICE',
  READY_TO_EXTEND_PAYMENT = 'READY_TO_EXTEND_PAYMENT',
  RECEIVABLE_APPROVED = 'RECEIVABLE_APPROVED',
  RECEIVABLE_DEBIT_REQUESTED = 'RECEIVABLE_DEBIT_REQUESTED',
  REJECTED_BILL = 'REJECTED_BILL',
  REJECTED_PAYMENT = 'REJECTED_PAYMENT',
  UNKNOWN = 'UNKNOWN',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_INVOICE_APPROVAL = 'WAITING_FOR_INVOICE_APPROVAL',
}

export type DashboardBankAccount = {
  balance: Maybe<Money>
  id: Scalars['String']
  maskedNumber: Maybe<Scalars['String']>
  name: Scalars['String']
  /** @deprecated Use scheduledAndProcessing instead. */
  processingPayments: DashboardGroupedAmounts
  scheduledAndProcessing: DashboardGroupedAmounts
  /** @deprecated Use scheduledAndProcessing instead. */
  scheduledPayments: DashboardGroupedAmounts
}

export type DashboardExtendedTerms = {
  /** @deprecated Use businesses.balanceSheet.eptLiabilityCents instead. */
  total: Money
}

export type DashboardGroupedAmounts = {
  sevenDays: Money
  thirtyDays: Money
}

export type DashboardPaid = {
  payerToSettle: DashboardGroupedAmounts
  payerToVendors: DashboardGroupedAmounts
  payerToVendorsAndSettle: DashboardGroupedAmounts
  total: Money
}

export enum DashboardPermissionsEnum {
  DASHBOARD_WIDGETS = 'DASHBOARD_WIDGETS',
  FULL_TODO_LIST = 'FULL_TODO_LIST',
}

export type DashboardScheduledAndProcessing = {
  payerToSettle: DashboardGroupedAmounts
  payerToVendors: DashboardGroupedAmounts
  payerToVendorsAndSettle: DashboardGroupedAmounts
  total: Money
}

export type DashboardTotalOwe = {
  scheduledAndProcessing: Money
  total: Money
  unscheduled: Money
}

export type DashboardUnscheduled = {
  failed: Money
  overdue: Money
  payerToVendors: DashboardGroupedAmounts
  total: Money
}

export type DashboardVirtualMarketingCard = {
  availableCredit: Money
  creditLimit: Money
  currentPeriod: Money
  previousUnpaid: Money
}

export type DataFieldsInput = {
  communication?: InputMaybe<Array<SectionFieldsInput>>
  countrySpecific?: InputMaybe<Array<SectionFieldsInput>>
  driverLicence?: InputMaybe<Array<SectionFieldsInput>>
  location?: InputMaybe<Array<SectionFieldsInput>>
  nationalIds?: InputMaybe<Array<SectionFieldsInput>>
  passport?: InputMaybe<Array<SectionFieldsInput>>
  personInfo?: InputMaybe<Array<SectionFieldsInput>>
}

export enum DateFilterPresetEnum {
  AFTER_TODAY = 'AFTER_TODAY',
  ALL_DATES = 'ALL_DATES',
  BEFORE_TODAY = 'BEFORE_TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_30_DAYS = 'NEXT_30_DAYS',
  NOT_SET = 'NOT_SET',
  THIS_MONTH = 'THIS_MONTH',
  THIS_QUARTER = 'THIS_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
}

export type DateFilteringInputObject = {
  from?: InputMaybe<Scalars['ISO8601Date']>
  /** Use presetEnum instead */
  preset?: InputMaybe<Scalars['String']>
  presetEnum?: InputMaybe<DateFilterPresetEnum>
  to?: InputMaybe<Scalars['ISO8601Date']>
}

export type DeletePayload = {
  deletedIds: Array<Scalars['ID']>
}

export type DeleteRoleResponse = {
  deletedRole: UserRole
  teamMembers: Maybe<TeamMembers>
}

export type Disclosures = {
  payeeAgentDisclosure: Scalars['String']
}

export enum DocumentCategoryEnum {
  ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
  BALANCE_SHEET = 'BALANCE_SHEET',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BUSINESS_TAX_RETURN = 'BUSINESS_TAX_RETURN',
  ID_CARD = 'ID_CARD',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  PASSPORT = 'PASSPORT',
  PROFIT_AND_LOSS = 'PROFIT_AND_LOSS',
  PROOF_OF_SOS_FILING = 'PROOF_OF_SOS_FILING',
  REVENUE_FORECAST = 'REVENUE_FORECAST',
  SS4 = 'SS4',
  SSN9_DOCUMENT = 'SSN9_DOCUMENT',
}

export enum DocumentTypeEnum {
  ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
  BALANCE_SHEET = 'BALANCE_SHEET',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BUSINESS_TAX_RETURN = 'BUSINESS_TAX_RETURN',
  ID_CARD = 'ID_CARD',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  PASSPORT = 'PASSPORT',
  PROFIT_AND_LOSS = 'PROFIT_AND_LOSS',
  PROOF_OF_SOS_FILING = 'PROOF_OF_SOS_FILING',
  REVENUE_FORECAST = 'REVENUE_FORECAST',
  SS4 = 'SS4',
  SSN9_DOCUMENT = 'SSN9_DOCUMENT',
}

export type EmailOptOut = {
  email: Scalars['String']
  emailAction: Scalars['String']
}

export enum EmailTypeEnum {
  GOOGLE = 'GOOGLE',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}

export enum EptFilterEnum {
  FILTER_AMRT = 'FILTER_AMRT',
  FILTER_AMRT30 = 'FILTER_AMRT30',
  FILTER_AMRT60 = 'FILTER_AMRT60',
  FILTER_AMRT90 = 'FILTER_AMRT90',
  FILTER_AMRT120 = 'FILTER_AMRT120',
  FILTER_AMRT150 = 'FILTER_AMRT150',
  FILTER_AMRT180 = 'FILTER_AMRT180',
  FILTER_AMRT180PLUS = 'FILTER_AMRT180PLUS',
  FILTER_EPT = 'FILTER_EPT',
  FILTER_EPT30 = 'FILTER_EPT30',
  FILTER_EPT60 = 'FILTER_EPT60',
  FILTER_EPT90 = 'FILTER_EPT90',
  FILTER_EPT120 = 'FILTER_EPT120',
  FILTER_EPT120PLUS = 'FILTER_EPT120PLUS',
}

export type EptMetricVendorTotal = {
  paid: Scalars['Int']
  unpaid: Scalars['Int']
}

export type EptMetrics = {
  days30: EptMetricVendorTotal
  days30Amortized: EptMetricVendorTotal
  days60: EptMetricVendorTotal
  days60Amortized: EptMetricVendorTotal
  days90: EptMetricVendorTotal
  days90Amortized: EptMetricVendorTotal
  days120: EptMetricVendorTotal
  days120Amortized: EptMetricVendorTotal
  days120Plus: EptMetricVendorTotal
  days150Amortized: EptMetricVendorTotal
  days180Amortized: EptMetricVendorTotal
  days180PlusAmortized: EptMetricVendorTotal
}

export type EptRate = {
  eptLength: Scalars['Int']
  eptRate: Maybe<Scalars['Float']>
  id: Scalars['ID']
  minEptThresholdCents: Scalars['Int']
  numEptMax: Scalars['Int']
}

export type ErrorDialog = {
  body: Scalars['String']
  /** @deprecated Use type instead */
  button: Maybe<ErrorDialogButton>
  title: Scalars['String']
  type: Scalars['String']
}

export enum ErrorDialogActionEnum {
  AWAITING_OPS_APPROVAL = 'AWAITING_OPS_APPROVAL',
  NEED_BENEFICIAL_OWNER = 'NEED_BENEFICIAL_OWNER',
  NEED_BUSINESS_INFO = 'NEED_BUSINESS_INFO',
  NEED_SIGNIFICANT_CONTROL = 'NEED_SIGNIFICANT_CONTROL',
  /** @deprecated Use NEED_BENEFICIAL_OWNER instead */
  PROVIDE_BENEFICIAL_OWNER = 'PROVIDE_BENEFICIAL_OWNER',
  /** @deprecated Use NEED_BUSINESS_INFO instead */
  PROVIDE_BUSINESS_INFO = 'PROVIDE_BUSINESS_INFO',
  SEND_EPT_REQUEST_AVAILABLE_CREDIT_LIMIT = 'SEND_EPT_REQUEST_AVAILABLE_CREDIT_LIMIT',
  SEND_EPT_REQUEST_TOTAL_CREDIT_LIMIT = 'SEND_EPT_REQUEST_TOTAL_CREDIT_LIMIT',
}

export type ErrorDialogButton = {
  action: Maybe<ErrorDialogActionEnum>
  text: Scalars['String']
}

export type ExchangeFeeData = {
  exchangeFeeAmount: Maybe<Money>
  exchangeRate: Maybe<Scalars['String']>
  totalUsdAmount: Money
  usdAmount: Money
}

export type ExternalPayableLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  quickbooksCategoryId?: InputMaybe<Scalars['ID']>
  quickbooksClassId?: InputMaybe<Scalars['ID']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  xeroCategoryId?: InputMaybe<Scalars['ID']>
}

export enum FeatureKeyEnum {
  ADHOC_INVOICE_APPROVALS = 'ADHOC_INVOICE_APPROVALS',
  ADVANCED_AND_CUSTOM_USER_ROLES = 'ADVANCED_AND_CUSTOM_USER_ROLES',
  APPROVAL_WORKFLOW_RULES = 'APPROVAL_WORKFLOW_RULES',
  BASIC_USER_ROLES = 'BASIC_USER_ROLES',
  CASH_FLOW_CHART = 'CASH_FLOW_CHART',
  ITEMIZED_ACCOUNTING = 'ITEMIZED_ACCOUNTING',
  OCR = 'OCR',
  PREMIUM_CUSTOMER_SUPPORT = 'PREMIUM_CUSTOMER_SUPPORT',
  PURCHASING_SUITE = 'PURCHASING_SUITE',
  SETTLE_INBOX = 'SETTLE_INBOX',
  SPLIT_PAY_AND_VENDOR_CREDITS = 'SPLIT_PAY_AND_VENDOR_CREDITS',
  TWO_WAY_SYNC = 'TWO_WAY_SYNC',
  VENDOR_MANAGEMENT = 'VENDOR_MANAGEMENT',
  VENDOR_W9_STORAGE_AND_1099 = 'VENDOR_W9_STORAGE_AND_1099',
}

export type FinancingNudgeBanner = {
  qboBillsToNudge: Array<Scalars['ID']>
  settleInvoicesToNudge: Scalars['Int']
}

export type FinancingOptions = {
  /** Details of amortized loan offer when it's available */
  amortizedOffer: Maybe<AmortizedLoan>
  /** Details of EPT offers when EPT is available */
  bulletEptOffers: Maybe<Array<BulletEpt>>
  /** Details of EPT offers when EPT is available */
  eptOffers: Maybe<Array<InvoiceExtendedPaymentTermOffer>>
  /** Estimated date of payment arrival to vendor */
  etaDate: Maybe<Scalars['ISO8601Date']>
  /** Planned date of initiating payment to vendor */
  vendorPaymentDate: Maybe<Scalars['ISO8601Date']>
}

export type FinicityLink = BankLink & {
  deleted: Scalars['Boolean']
  fixAvailable: Maybe<BankLinkFixEnum>
  /** @deprecated Use banners instead */
  fixFlowMessage: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

/** Autogenerated return type of FraudBusinessReviewApprove. */
export type FraudBusinessReviewApprovePayload = {
  success: Scalars['Boolean']
}

export type Holiday = {
  date: Scalars['ISO8601Date']
  name: Scalars['String']
}

export enum IconEnum {
  ADDED_TO_PAYABLES = 'ADDED_TO_PAYABLES',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  GENERIC = 'GENERIC',
  LIGHTNING = 'LIGHTNING',
  ON_APPROVAL = 'ON_APPROVAL',
  PAID = 'PAID',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
}

export type Integration = NetsuiteState | QuickBooksState | XeroState

export type InternationalWireFieldsDataFieldInputObject = {
  id?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['String']>
}

export type InternationalWireFieldsDataFieldType = {
  country: Scalars['String']
  currency: Maybe<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  label: Scalars['String']
  length: Maybe<Scalars['Int']>
  propertyKey: InternationalWirePropertyKeyTypeEnum
  regex: Maybe<Scalars['String']>
  targetType: InternationalWireTargetTypeEnum
}

export type InternationalWireFieldsDataFieldValueType = {
  id: Scalars['ID']
  value: Maybe<Scalars['String']>
}

export enum InternationalWirePropertyKeyTypeEnum {
  BANK_NAME = 'BANK_NAME',
  CNAPS = 'CNAPS',
  LOCAL_BANK_CODE = 'LOCAL_BANK_CODE',
}

export enum InternationalWireTargetTypeEnum {
  ACCOUNT = 'ACCOUNT',
  PAYMENT = 'PAYMENT',
}

export type Invitation = {
  businessName: Scalars['String']
  email: Maybe<Scalars['String']>
  existingUser: Maybe<Profile>
  id: Scalars['ID']
  role: Maybe<MembershipRolesEnum>
  userRole: Maybe<UserRole>
}

/** Autogenerated return type of InvitePayer. */
export type InvitePayerPayload = {
  localBusiness: Maybe<LocalBusiness>
}

/** Autogenerated return type of InviteVendor. */
export type InviteVendorPayload = {
  invoice: Maybe<Invoice>
  localBusiness: Maybe<LocalBusiness>
}

export type Invoice = {
  account: Maybe<Account>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingMemo: Maybe<Scalars['String']>
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingUrl: Maybe<Scalars['String']>
  actions: InvoiceActions
  allowAccountChange: Scalars['Boolean']
  allowPaymentCardMethod: Maybe<Scalars['Boolean']>
  allowSkipVendorInvitation: Scalars['Boolean']
  allowVendorChange: Scalars['Boolean']
  amount: Maybe<Money>
  applicableVendorCredits: Array<ApplicableVendorCredit>
  archived: Scalars['Boolean']
  attachments: Array<Attachment>
  balanceDue: Maybe<Money>
  balanceDueToSettle: Maybe<Money>
  createdAt: Scalars['ISO8601DateTime']
  createdForPayment: Maybe<Payment>
  /** @deprecated Use vendorCurrency.isoCode instead */
  currency: Maybe<Scalars['String']>
  deletable: Scalars['Boolean']
  description: Maybe<Scalars['String']>
  discrepancyAmount: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  dueDays: Maybe<Scalars['Int']>
  dueToVendorAmount: Maybe<Money>
  /** @deprecated Use transfers.paymentFromPayerStatus */
  eptPaymentFromPayerStatus: Maybe<InvoiceStatusEnum>
  /** @deprecated Use transfers.paymentToVendorStatus */
  eptPaymentToVendorStatus: Maybe<InvoiceStatusEnum>
  /** @deprecated Use sync_object_status instead */
  excludedFromSync: Maybe<Scalars['Boolean']>
  /** @deprecated Use transfers.payment instead */
  failedPayment: Maybe<Payment>
  failedPayments: Array<Payment>
  /** Total of all fee associated with EPT agreements accepted by customer for the invoice. */
  feeAmount: Maybe<Money>
  hasAccessToView: Scalars['Boolean']
  hasUnprocessedTransfers: Scalars['Boolean']
  id: Scalars['ID']
  inboundProcessing: Scalars['Boolean']
  inventoryLineItemsTotal: Maybe<Money>
  invoiceApproval: Maybe<InvoiceApproval>
  invoiceApprovalRule: Maybe<InvoiceApprovalRule>
  invoiceInventoryLineItems: Array<InvoiceInventoryLineItem>
  isExtendedPaymentTermFeeInvoice: Scalars['Boolean']
  isExtendedPaymentTermInvoice: Scalars['Boolean']
  isMarketingVirtualCardAggregatedInvoice: Scalars['Boolean']
  isMarketingVirtualCardFeeInvoice: Scalars['Boolean']
  issueDate: Maybe<Scalars['ISO8601Date']>
  labels: Array<Label>
  lastAttached: Maybe<Attachment>
  liabilityAmount: Maybe<Money>
  lineItems: Array<InvoiceLineItem>
  markedAsPaidBy: Maybe<Approver>
  markedAsPaidFromAccount: Maybe<Account>
  /** Date the 'mark invoice paid' action was taken */
  markedAsPaidOn: Maybe<Scalars['ISO8601Date']>
  marketingVirtualCardInvoice: Maybe<InvoiceMarketingVirtualCard>
  memo: Maybe<Scalars['String']>
  /** @deprecated use nearestProcessingDate query instead */
  nearestProcessingDate: Maybe<Scalars['ISO8601Date']>
  /** @deprecated use nearestProcessingDate query instead */
  nearestProcessingDateEpt: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  paidOn: Maybe<Scalars['ISO8601Date']>
  paidWithMethod: Maybe<PaymentMethodEnum>
  paperCheck: Scalars['Boolean']
  paperCheckDetails: Maybe<PaperCheckDetails>
  payableActionIndicator: Maybe<PayableActionIndicatorEnum>
  payerBusiness: Maybe<PublicBusiness>
  payerLocalBusiness: Maybe<LocalBusiness>
  payerTaxpayerId: Maybe<Scalars['String']>
  /** @deprecated Use transfers.payment instead */
  payment: Maybe<Payment>
  paymentAccountingAccount: Maybe<AccountingAccount>
  paymentAccountingAccountId: Maybe<Scalars['ID']>
  paymentCard: Maybe<PaymentCard>
  paymentMethod: Maybe<PaymentMethodEnum>
  paymentTermDays: Maybe<Scalars['Int']>
  paymentsTotalAmount: Money
  /** Only query for this field for single invoices. Querying at volume may introduce high latency */
  possibleDuplicates: Array<Invoice>
  purchaseOrderNumber: Maybe<Scalars['String']>
  purchaseOrders: Array<PurchaseOrder>
  purposeCode: Maybe<Scalars['String']>
  /** @deprecated Use lineItems instead */
  quickBooksAccountId: Maybe<Scalars['ID']>
  /** @deprecated Use lineItems instead */
  quickBooksClassId: Maybe<Scalars['ID']>
  /** @deprecated Use quickbooksLocationId instead */
  quickBooksLocationId: Maybe<Scalars['ID']>
  /** @deprecated Use quickbooksMemo instead */
  quickBooksMemo: Maybe<Scalars['String']>
  /** @deprecated Use lineItems accounting_account_id instead */
  quickbooksCategoryId: Maybe<Scalars['ID']>
  /** @deprecated Use accounting_category_location_id instead */
  quickbooksLocationId: Maybe<Scalars['ID']>
  quickbooksMemo: Maybe<Scalars['String']>
  /** @deprecated Use payment_accounting_account */
  quickbooksPaymentAccount: Maybe<AccountingAccount>
  /** @deprecated Use payment_accounting_account_id */
  quickbooksPaymentAccountId: Maybe<Scalars['ID']>
  read: Scalars['Boolean']
  receipts: Array<Receipt>
  recommendedAccount: Maybe<Account>
  recommendedAccountWarning: Maybe<Scalars['String']>
  recommendedPaymentMethod: Maybe<PaymentMethodEnum>
  relatedInvoices: Array<Invoice>
  selectedForFinancing: Scalars['Boolean']
  settleInboxMessage: Maybe<SettleInboxMessage>
  showDiscrepancyAlert: Scalars['Boolean']
  showSyncHistory: Maybe<Scalars['Boolean']>
  signedDocuments: Array<SignedDocument>
  skipVendorInvitation: Scalars['Boolean']
  source: Maybe<InvoiceSourceEnum>
  /** @deprecated Use transfers.status */
  status: InvoiceStatusEnum
  statuses: Array<InvoiceStatusEnum>
  statusesForBadge: Array<InvoiceStatusEnum>
  syncCurrency: Maybe<Scalars['String']>
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  syncPossible: Maybe<Scalars['Boolean']>
  timelineEvents: Maybe<Array<TimelineEvent>>
  totalUsdAmount: Maybe<Money>
  transfers: Array<Transfer>
  unscheduledAmount: Maybe<Money>
  uploadedByCurrentBusiness: Scalars['Boolean']
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorAmount: Maybe<Money>
  vendorBusiness: Maybe<PublicBusiness>
  vendorCreditApplications: Array<VendorCreditApplication>
  vendorCreditAppliedTotal: Money
  vendorCurrency: Maybe<Currency>
  vendorInvited: Scalars['Boolean']
  vendorLocalBusiness: Maybe<LocalBusiness>
  vendorReceivedAmount: Money
  /** @deprecated Use lineItems instead */
  xeroAccountId: Maybe<Scalars['String']>
  /** @deprecated Use lineItems accounting_account_id instead */
  xeroCategoryId: Maybe<Scalars['String']>
  /** @deprecated Use payment_accounting_account */
  xeroPaymentAccount: Maybe<AccountingAccount>
  /** @deprecated Use payment_accounting_account_id */
  xeroPaymentAccountId: Maybe<Scalars['ID']>
}

export type InvoiceActions = {
  addNewPayment: Action
  applyVendorCredit: Action
  bulkPay: Action
  cancelVendorFinancing: Action
  createLinkedPurchaseOrder: Action
  createLinkedReceipt: Action
  financeInvoice: Action
  markPaid: Action
  markUnpaid: Action
  partialPayment: Action
  requestRuleBasedApproval: Action
  sendInvoice: Action
  updateAmount: Action
  updateArchived: Action
  updateCurrency: Action
  updateVendor: Action
  updateVendorAccount: Action
}

export type InvoiceAmountFilteringInputObject = {
  from?: InputMaybe<Scalars['Int']>
  to?: InputMaybe<Scalars['Int']>
}

export type InvoiceApproval = {
  approvedBy: Maybe<Array<Approver>>
  approvedOrRejectedOn: Maybe<Scalars['ISO8601Date']>
  approvers: Maybe<Array<Scalars['ID']>>
  requestingUser: Maybe<Approver>
}

export type InvoiceApprovalRule = {
  approvalRule: Maybe<Rule>
  approvalSteps: Maybe<Array<InvoiceApprovalStep>>
}

export type InvoiceApprovalStep = {
  approvalType: RuleActionConditionEnum
  approvers: Maybe<Array<Approver>>
  stepNumber: Maybe<Scalars['Int']>
}

export enum InvoiceArchivedStatusEnum {
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
}

export type InvoiceDateFilteringOption = {
  code: Maybe<Scalars['String']>
  empty: Scalars['Boolean']
  from: Maybe<Scalars['ISO8601Date']>
  name: Maybe<Scalars['String']>
  to: Maybe<Scalars['ISO8601Date']>
}

export type InvoiceDateFilteringOptions = {
  field: InvoiceSortableFieldEnum
  presets: Array<InvoiceDateFilteringOption>
}

export enum InvoiceEventTypeEnum {
  APPLIED_VENDOR_CREDITS_CLOSED = 'APPLIED_VENDOR_CREDITS_CLOSED',
  APPLIED_VENDOR_CREDITS_UPDATED = 'APPLIED_VENDOR_CREDITS_UPDATED',
  COLLECTION_EMAIL_SENT = 'COLLECTION_EMAIL_SENT',
  EPT_AGREEMENT_SIGNED = 'EPT_AGREEMENT_SIGNED',
  EPT_TERMS_CHANGED = 'EPT_TERMS_CHANGED',
  INVOICE_APPROVAL_CANCELLED = 'INVOICE_APPROVAL_CANCELLED',
  INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE = 'INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE',
  INVOICE_APPROVAL_REQUESTED = 'INVOICE_APPROVAL_REQUESTED',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_ARCHIVED = 'INVOICE_ARCHIVED',
  INVOICE_CREATED_BY_EMAIL = 'INVOICE_CREATED_BY_EMAIL',
  INVOICE_CREATED_BY_IMPORT = 'INVOICE_CREATED_BY_IMPORT',
  INVOICE_CREATED_BY_USER = 'INVOICE_CREATED_BY_USER',
  INVOICE_CREATED_FROM_SETTLE_INBOX = 'INVOICE_CREATED_FROM_SETTLE_INBOX',
  INVOICE_MARKED_AS_PAID = 'INVOICE_MARKED_AS_PAID',
  INVOICE_MARKED_AS_UNPAID = 'INVOICE_MARKED_AS_UNPAID',
  INVOICE_RECEIVED_IN_SETTLE_INBOX = 'INVOICE_RECEIVED_IN_SETTLE_INBOX',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_VIEWED_BY_PAYER = 'INVOICE_VIEWED_BY_PAYER',
  PAYMENT_APPROVAL_CANCELLED = 'PAYMENT_APPROVAL_CANCELLED',
  PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE = 'PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE',
  PAYMENT_APPROVAL_REQUESTED = 'PAYMENT_APPROVAL_REQUESTED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_FROM_PAYER_PAID = 'PAYMENT_FROM_PAYER_PAID',
  PAYMENT_FROM_PAYER_PROCESSED = 'PAYMENT_FROM_PAYER_PROCESSED',
  PAYMENT_FULLY_REVERSED = 'PAYMENT_FULLY_REVERSED',
  PAYMENT_PARTIALLY_REPAID_EARLY = 'PAYMENT_PARTIALLY_REPAID_EARLY',
  PAYMENT_REFUND_COMPLETED = 'PAYMENT_REFUND_COMPLETED',
  PAYMENT_REFUND_INITIATED = 'PAYMENT_REFUND_INITIATED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_REPAID_EARLY = 'PAYMENT_REPAID_EARLY',
  PAYMENT_RESCHEDULED = 'PAYMENT_RESCHEDULED',
  PAYMENT_RETRIED = 'PAYMENT_RETRIED',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_TO_VENDOR_PAID = 'PAYMENT_TO_VENDOR_PAID',
  PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED = 'PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED',
  PAYMENT_TO_VENDOR_PROCESSED = 'PAYMENT_TO_VENDOR_PROCESSED',
  RECEIVABLE_PURCHASED = 'RECEIVABLE_PURCHASED',
  RECEIVABLE_PURCHASE_CANCELED = 'RECEIVABLE_PURCHASE_CANCELED',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
  VENDOR_CREDIT_ADDED = 'VENDOR_CREDIT_ADDED',
  VENDOR_CREDIT_APPLIED = 'VENDOR_CREDIT_APPLIED',
  VENDOR_CREDIT_CLOSED = 'VENDOR_CREDIT_CLOSED',
  VENDOR_CREDIT_CREATED = 'VENDOR_CREDIT_CREATED',
  VENDOR_CREDIT_DELETED = 'VENDOR_CREDIT_DELETED',
  VENDOR_CREDIT_UNAPPLIED = 'VENDOR_CREDIT_UNAPPLIED',
}

export type InvoiceExtendedPaymentTermConfirmation = {
  additionalDays: Scalars['Int']
  agreement: SignedDocument
  dueDate: Scalars['ISO8601Date']
  feeAmount: Money
  feeInvoice: Maybe<Invoice>
  feePaymentDate: Scalars['ISO8601Date']
  feePercent: Scalars['Float']
  id: Scalars['ID']
  payerPaymentDate: Scalars['ISO8601Date']
}

export type InvoiceExtendedPaymentTermOffer = {
  additionalDays: Scalars['Int']
  feeAmount: Maybe<Money>
  feeAmountWithRepayments: Maybe<Money>
  feeInvoice: Maybe<Invoice>
  feePaymentDate: Scalars['ISO8601Date']
  feePercent: Scalars['Float']
  id: Scalars['ID']
  payerPaymentDate: Maybe<Scalars['ISO8601Date']>
  status: OfferStatusEnum
}

export type InvoiceExtendedPaymentTermOffers = {
  offers: Maybe<Array<InvoiceExtendedPaymentTermOffer>>
  vendorPaymentDate: Maybe<Scalars['ISO8601Date']>
}

export type InvoiceFilteringOptions = {
  createdAt: Array<InvoiceDateFilteringOption>
  dates: Array<InvoiceDateFilteringOptions>
  dueDate: Array<InvoiceDateFilteringOption>
  epts: Maybe<Array<EptFilterEnum>>
  issueDate: Array<InvoiceDateFilteringOption>
  scheduledDate: Array<InvoiceDateFilteringOption>
}

export type InvoiceInputObject = {
  accountNumber?: InputMaybe<Scalars['String']>
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  payerLocalBusinessId?: InputMaybe<Scalars['ID']>
  payerTaxpayerId?: InputMaybe<Scalars['String']>
  paymentCardId?: InputMaybe<Scalars['ID']>
  purposeCode?: InputMaybe<Scalars['String']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  quickBooksClassId?: InputMaybe<Scalars['ID']>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  quickBooksMemo?: InputMaybe<Scalars['String']>
  routingNumber?: InputMaybe<Scalars['String']>
  skipVendorInvitation?: InputMaybe<Scalars['Boolean']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  vendorAccountId?: InputMaybe<Scalars['ID']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type InvoiceInventoryLineItem = {
  accountingCategoryClassId: Maybe<Scalars['ID']>
  amount: Maybe<Money>
  catalogItem: CatalogItem
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
}

export type InvoiceInventoryLineItemInputObject = {
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['Int']>
  amountCurrency?: InputMaybe<Scalars['String']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
}

export type InvoiceLineItem = {
  accountingAccount: Maybe<AccountingAccount>
  accountingAccountId: Maybe<Scalars['ID']>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingCategoryOneId: Maybe<Scalars['ID']>
  accountingCategoryTwoId: Maybe<Scalars['ID']>
  amount: Money
  catalogItem: Maybe<CatalogItem>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  manuallyCreated: Scalars['Boolean']
  netsuiteId: Maybe<Scalars['String']>
  quantity: Maybe<Scalars['Float']>
  /** @deprecated Use accounting_account */
  quickbooksCategory: Maybe<QuickBooksAccount>
  /** @deprecated Use accounting_account_id */
  quickbooksCategoryId: Maybe<Scalars['ID']>
  quickbooksClass: Maybe<AccountingDictionary>
  quickbooksClassId: Maybe<Scalars['ID']>
  quickbooksId: Maybe<Scalars['String']>
  tax: Scalars['Boolean']
  trackingCategoryOne: Maybe<AccountingDictionary>
  trackingCategoryOneId: Maybe<Scalars['ID']>
  trackingCategoryTwo: Maybe<AccountingDictionary>
  trackingCategoryTwoId: Maybe<Scalars['ID']>
  unitAmount: Maybe<Money>
  /** @deprecated Use accounting_account */
  xeroCategory: Maybe<XeroDictionary>
  /** @deprecated Use accounting_account_id */
  xeroCategoryId: Maybe<Scalars['String']>
  xeroId: Maybe<Scalars['String']>
}

export type InvoiceLineItemDetailsInputObject = {
  amountCents: Scalars['SafeInt']
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
  unitAmountCents?: InputMaybe<Scalars['Int']>
}

export type InvoiceLineItemInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  catalogItemId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  manuallyCreated?: InputMaybe<Scalars['Boolean']>
  netsuiteId?: InputMaybe<Scalars['String']>
  quantity: Scalars['Float']
  quickbooksCategoryId?: InputMaybe<Scalars['ID']>
  quickbooksClassId?: InputMaybe<Scalars['ID']>
  quickbooksId?: InputMaybe<Scalars['String']>
  tax?: InputMaybe<Scalars['Boolean']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  unitAmountCents: Scalars['Int']
  xeroCategoryId?: InputMaybe<Scalars['ID']>
  xeroId?: InputMaybe<Scalars['String']>
}

export type InvoiceMarketingVirtualCard = {
  fromDate: Scalars['ISO8601Date']
  toDate: Scalars['ISO8601Date']
}

export type InvoiceOcr = {
  ocrRecordLineItems: Array<InvoiceOcrLineItem>
  payerAddress: Maybe<AddressOcr>
  payerEmail: Maybe<Scalars['String']>
  payerName: Maybe<Scalars['String']>
  routingNumberValidation: Maybe<RoutingNumberValidation>
  vendorAccountNumber: Maybe<Scalars['String']>
  vendorAddress: Maybe<AddressOcr>
  vendorEmail: Maybe<Scalars['String']>
  vendorIban: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
  vendorPhone: Maybe<Scalars['String']>
  vendorRoutingNumber: Maybe<Scalars['String']>
  vendorSwift: Maybe<Scalars['String']>
}

export type InvoiceOcrLineItem = {
  amount: Maybe<Money>
  id: Scalars['ID']
  matchingCatalogItem: Maybe<CatalogItem>
  productName: Maybe<Scalars['String']>
  quantity: Maybe<Scalars['Float']>
  sku: Maybe<Scalars['String']>
  unitAmount: Maybe<Money>
}

export type InvoicePayableDataInputObject = {
  accountId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
  paperCheck?: InputMaybe<Scalars['Boolean']>
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  quickBooksMemo?: InputMaybe<Scalars['String']>
}

export enum InvoiceSortableFieldEnum {
  AMOUNT = 'AMOUNT',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  CREATED_AT = 'CREATED_AT',
  DUE_DATE = 'DUE_DATE',
  FEE_AMOUNT = 'FEE_AMOUNT',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  ISSUE_DATE = 'ISSUE_DATE',
  LABELS = 'LABELS',
  NEAREST_INSTALLMENT_AMOUNT = 'NEAREST_INSTALLMENT_AMOUNT',
  NEAREST_PAYER_PAYMENT_DATE = 'NEAREST_PAYER_PAYMENT_DATE',
  NEAREST_VENDOR_PAYMENT_DATE = 'NEAREST_VENDOR_PAYMENT_DATE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  SCHEDULED_DATE = 'SCHEDULED_DATE',
  STATUS = 'STATUS',
  TERMS = 'TERMS',
  TOTAL_USD_AMOUNT = 'TOTAL_USD_AMOUNT',
  VENDOR_NAME = 'VENDOR_NAME',
}

export enum InvoiceSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type InvoiceSortingInputObject = {
  direction: InvoiceSortingDirectionEnum
  field: InvoiceSortableFieldEnum
}

export enum InvoiceSourceEnum {
  ADDED_WITHOUT_ATTACHMENT = 'ADDED_WITHOUT_ATTACHMENT',
  CREATED = 'CREATED',
  EMAIL = 'EMAIL',
  EPT_FEE = 'EPT_FEE',
  NETSUITE_IMPORT = 'NETSUITE_IMPORT',
  QUICKBOOKS_IMPORT = 'QUICKBOOKS_IMPORT',
  UPLOADED = 'UPLOADED',
  VMC = 'VMC',
  VMC_FEE = 'VMC_FEE',
  XERO_IMPORT = 'XERO_IMPORT',
}

export type InvoiceStatusCounts = {
  approvalRequested: Scalars['Int']
  approved: Scalars['Int']
  draft: Scalars['Int']
  error: Scalars['Int']
  failed: Scalars['Int']
  paid: Scalars['Int']
  partiallyRepaid: Scalars['Int']
  paymentPlan: Scalars['Int']
  processed: Scalars['Int']
  rejected: Scalars['Int']
  scheduled: Scalars['Int']
  splitted: Scalars['Int']
  unscheduled: Scalars['Int']
  vendorPartiallyPaid: Scalars['Int']
  voided: Scalars['Int']
  waiting: Scalars['Int']
}

export enum InvoiceStatusEnum {
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PARTIALLY_REPAID = 'PARTIALLY_REPAID',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  SCHEDULED = 'SCHEDULED',
  SPLITTED = 'SPLITTED',
  UNSCHEDULED = 'UNSCHEDULED',
  VENDOR_PARTIALLY_PAID = 'VENDOR_PARTIALLY_PAID',
  VOIDED = 'VOIDED',
  WAITING = 'WAITING',
}

export type InvoiceSuggestedVendorAccount = {
  routingNumberValidation: Maybe<RoutingNumberValidation>
  type: Maybe<SuggestedVendorAccountTypeEnum>
  vendorAccountNumber: Maybe<Scalars['String']>
  vendorIban: Maybe<Scalars['String']>
  vendorRoutingNumber: Maybe<Scalars['String']>
  vendorSwift: Maybe<Scalars['String']>
}

export type Invoices = {
  data: Array<Invoice>
  pagination: Pagination
}

export enum InvoicesEptReasonEnum {
  CREDIT_LIMIT = 'CREDIT_LIMIT',
  THRESHOLD = 'THRESHOLD',
}

export type InvoicesFilteringInputObject = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryClassIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryDepartmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryOneIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingCategoryTwoIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  amountCents?: InputMaybe<InvoiceAmountFilteringInputObject>
  amountCurrencies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  approverIds?: InputMaybe<Array<Scalars['ID']>>
  archivedStatus?: InputMaybe<InvoiceArchivedStatusEnum>
  createdAt?: InputMaybe<DateFilteringInputObject>
  dueDate?: InputMaybe<DateFilteringInputObject>
  epts?: InputMaybe<Array<InputMaybe<EptFilterEnum>>>
  issueDate?: InputMaybe<DateFilteringInputObject>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  number?: InputMaybe<Scalars['String']>
  payerName?: InputMaybe<Scalars['String']>
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethodEnum>>>
  purchaseOrderNumber?: InputMaybe<Scalars['String']>
  quickbooksClassIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  quickbooksLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  scheduledDate?: InputMaybe<DateFilteringInputObject>
  selectedForFinancing?: InputMaybe<Scalars['Boolean']>
  sources?: InputMaybe<Array<InvoiceSourceEnum>>
  sourcesV2?: InputMaybe<Array<InvoiceSourceEnum>>
  statuses?: InputMaybe<Array<InvoiceStatusEnum>>
  vendorLocalBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  vendorName?: InputMaybe<Scalars['String']>
}

export enum InvoicesPermissionsEnum {
  ATTACH_INVOICE_DOCUMENT = 'ATTACH_INVOICE_DOCUMENT',
  BULK_UPDATE_TRANSFER = 'BULK_UPDATE_TRANSFER',
  CANCEL_INVOICE_APPROVAL_REQUEST = 'CANCEL_INVOICE_APPROVAL_REQUEST',
  CREATE_PAYABLE_INVOICE = 'CREATE_PAYABLE_INVOICE',
  CREATE_RECEIVABLE_INVOICE = 'CREATE_RECEIVABLE_INVOICE',
  DELETE_INVOICES = 'DELETE_INVOICES',
  DELETE_INVOICE_ATTACHMENT = 'DELETE_INVOICE_ATTACHMENT',
  INVOICE_STATUS_COUNTS = 'INVOICE_STATUS_COUNTS',
  MARK_INVOICE_PAID = 'MARK_INVOICE_PAID',
  MARK_INVOICE_UNPAID = 'MARK_INVOICE_UNPAID',
  MARK_TRANSFER_PAID = 'MARK_TRANSFER_PAID',
  MARK_TRANSFER_UNPAID = 'MARK_TRANSFER_UNPAID',
  MERGE_TRANSFERS = 'MERGE_TRANSFERS',
  NOTIFY_EPT_REQUEST = 'NOTIFY_EPT_REQUEST',
  NOTIFY_WRONG_INVOICE = 'NOTIFY_WRONG_INVOICE',
  REQUEST_INVOICE_APPROVAL = 'REQUEST_INVOICE_APPROVAL',
  SPLIT_TRANSFERS = 'SPLIT_TRANSFERS',
  UPDATE_INVOICE = 'UPDATE_INVOICE',
  UPDATE_INVOICE_ARCHIVED = 'UPDATE_INVOICE_ARCHIVED',
  UPDATE_INVOICE_EXTERNAL_ATTRIBUTES = 'UPDATE_INVOICE_EXTERNAL_ATTRIBUTES',
  UPDATE_INVOICE_LABELS = 'UPDATE_INVOICE_LABELS',
  UPDATE_INVOICE_PAYABLE_DATA = 'UPDATE_INVOICE_PAYABLE_DATA',
  UPDATE_TRANSFER = 'UPDATE_TRANSFER',
}

export type Journey = {
  active: Scalars['Boolean']
  status: JourneyStatus
  steps: Array<JourneyStep>
  type: JourneyEnum
}

export enum JourneyEnum {
  ACTIVATE_BUSINESS = 'ACTIVATE_BUSINESS',
  LENDING = 'LENDING',
  PAYER = 'PAYER',
  PURCHASING = 'PURCHASING',
}

export enum JourneyStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type JourneyStep = {
  /** @deprecated Use `type` instead */
  name: JourneyStepEnum
  relatedObjectData: Maybe<JourneyStepRelatedObject>
  status: JourneyStepStatus
  type: JourneyStepEnum
  userCanCompleteStep: Scalars['Boolean']
}

export enum JourneyStepEnum {
  ADD_BENEFICIAL_OWNERS = 'ADD_BENEFICIAL_OWNERS',
  COMPLETE_BUSINESS_DETAILS = 'COMPLETE_BUSINESS_DETAILS',
  CONNECT_ACCOUNTING = 'CONNECT_ACCOUNTING',
  CONNECT_BANK_ACCOUNT = 'CONNECT_BANK_ACCOUNT',
  CREATE_OR_UPLOAD_PURCHASE_ORDER = 'CREATE_OR_UPLOAD_PURCHASE_ORDER',
  DOCUMENT_OR_DATA_REQUEST = 'DOCUMENT_OR_DATA_REQUEST',
  SCHEDULE_BILL_PAYMENT = 'SCHEDULE_BILL_PAYMENT',
}

export type JourneyStepRelatedObject = {
  dataRequestId: Maybe<Scalars['ID']>
  documentType: Maybe<DocumentTypeEnum>
  documentUploadRequestId: Maybe<Scalars['ID']>
  latestAccountingDate: Maybe<Scalars['ISO8601Date']>
  targetDescription: Maybe<Scalars['String']>
}

export enum JourneyStepStatus {
  COMPLETE = 'COMPLETE',
  TO_DO = 'TO_DO',
}

export type KybDataField = {
  description: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  propertyKey: Scalars['String']
  type: Scalars['String']
}

export type KybDataFieldInput = {
  intValue?: InputMaybe<Scalars['Int']>
  key: Scalars['String']
  stringValue?: InputMaybe<Scalars['String']>
}

export type KybDataRequest = {
  dataFields: Maybe<Array<KybDataField>>
  dataRequestId: Maybe<Scalars['String']>
  status: Maybe<Scalars['String']>
}

export type KybDataRequestInput = {
  dataFieldInputs: Array<KybDataFieldInput>
  dataRequestId: Scalars['ID']
}

export enum KybStatusEnum {
  /** Awaiting approval by Settle */
  AWAITING_OPS_APPROVAL = 'AWAITING_OPS_APPROVAL',
  /** Blocked by Settle */
  BLOCKED = 'BLOCKED',
  /** Awaiting business details or UBO information */
  NEED_BUSINESS_INFO = 'NEED_BUSINESS_INFO',
  /** Approved by Settle */
  VERIFIED = 'VERIFIED',
}

export type Label = {
  /** HEX representation of a color (e.g. #RRGGBB) */
  color: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
}

export type LabelInputObject = {
  /** HEX representation of a color (e.g. #RRGGBB) */
  color: Scalars['String']
  name: Scalars['String']
}

export type LinkedDocument = Invoice | PurchaseOrder | Receipt

export type LocalBusiness = {
  accounts: Array<LocalBusinessAccount>
  actions: LocalBusinessActions
  address: Maybe<Address>
  addressForPaperCheck: Maybe<Address>
  availableVendorCreditAmount: Maybe<Money>
  bankVerified: Scalars['Boolean']
  blockPayments: Maybe<BlockLocalBusinessPayments>
  business: Maybe<PublicBusiness>
  businessName: Maybe<Scalars['String']>
  businessStructure: Maybe<BusinessStructureEnum>
  /** @deprecated Use suggestedBusinessMatches instead */
  canMergeWithPublicBusiness: Scalars['Boolean']
  currency: Maybe<Scalars['String']>
  defaultBeneficiaryFirstName: Maybe<Scalars['String']>
  defaultBeneficiaryLastName: Maybe<Scalars['String']>
  defaultBeneficiaryName: Maybe<Scalars['String']>
  deleted: Scalars['Boolean']
  factoringEmail: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  importedEmails: Array<Scalars['String']>
  invited: Scalars['Boolean']
  invitedAsPayer: Scalars['Boolean']
  invitedAt: Maybe<Scalars['ISO8601DateTime']>
  invoicesStatistics: LocalBusinessStatusesCountType
  is1099Eligible: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  notificationsEmail: Maybe<Scalars['String']>
  numBills: Scalars['Int']
  numPayments: Scalars['Int']
  officialName: Maybe<Scalars['String']>
  /** non-w9 documents */
  otherAttachments: Array<Attachment>
  /** @deprecated Use suggestedBusinessMatches instead */
  publicBusinessMatch: Maybe<PublicBusiness>
  registeredBusinessName: Maybe<Scalars['String']>
  suggestedBusinessMatches: Array<BusinessMatch>
  taxpayerId: Maybe<Scalars['String']>
  vendorCredits: Array<VendorCredit>
  vendorPaymentTerms: Array<VendorPaymentTerm>
  visible: Scalars['Boolean']
  w9Form: Maybe<Attachment>
}

export type LocalBusinessAccount = {
  accountNumber: Maybe<Scalars['String']>
  accountNumberType: Maybe<VendorAccountNumberTypeEnum>
  archived: Scalars['Boolean']
  bankAddress: Maybe<Address>
  bankName: Maybe<Scalars['String']>
  beneficiaryFirstName: Maybe<Scalars['String']>
  beneficiaryLastName: Maybe<Scalars['String']>
  beneficiaryName: Maybe<Scalars['String']>
  bicSwift: Maybe<Scalars['String']>
  cnaps: Maybe<Scalars['String']>
  copyOfVerifiedAccount: Scalars['Boolean']
  currency: Maybe<Scalars['String']>
  deletable: Scalars['Boolean']
  destinationCountry: Scalars['String']
  editable: Scalars['Boolean']
  id: Scalars['ID']
  lastUsedInvoice: Maybe<Invoice>
  lastUsedOn: Maybe<Scalars['ISO8601Date']>
  mask: Scalars['String']
  /** True iff this account is missing wire fields and has a complete local copy */
  missingWireFieldsProvidedByLocalCopy: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  ownedByPayer: Scalars['Boolean']
  preferredReceivingAccount: Scalars['Boolean']
  routingNumber: Maybe<Scalars['String']>
  routingNumberValidation: Maybe<RoutingNumberValidation>
  supportedPaymentTypes: Array<PaymentMethodEnum>
  verified: Scalars['Boolean']
  wireFields: Maybe<Array<InternationalWireFieldsDataFieldValueType>>
  wireRoutingNumber: Maybe<Scalars['String']>
  wireRoutingNumberValidation: Maybe<RoutingNumberValidation>
}

export type LocalBusinessAccountInputObject = {
  accountNumber: Scalars['String']
  archived?: InputMaybe<Scalars['Boolean']>
  bankAddress?: InputMaybe<AddressInputObject>
  bankName?: InputMaybe<Scalars['String']>
  beneficiaryFirstName?: InputMaybe<Scalars['String']>
  beneficiaryLastName?: InputMaybe<Scalars['String']>
  beneficiaryName?: InputMaybe<Scalars['String']>
  bicSwift?: InputMaybe<Scalars['String']>
  cnaps?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  destinationCountry: Scalars['String']
  routingNumber?: InputMaybe<Scalars['String']>
  wireFields?: InputMaybe<Array<InternationalWireFieldsDataFieldInputObject>>
  wireRoutingNumber?: InputMaybe<Scalars['String']>
}

export type LocalBusinessActions = {
  delete: Action
  updateW9: Action
}

export type LocalBusinessInputObject = {
  address?: InputMaybe<AddressInputObject>
  addressForPaperCheck?: InputMaybe<AddressInputObject>
  businessName?: InputMaybe<Scalars['String']>
  businessStructure?: InputMaybe<BusinessStructureEnum>
  currency?: InputMaybe<Scalars['String']>
  factoringEmail?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  initialRole?: InputMaybe<LocalBusinessRoleEnum>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  notificationsEmail?: InputMaybe<Scalars['String']>
  taxpayerId?: InputMaybe<Scalars['String']>
  vendorPaymentTerms?: InputMaybe<Array<VendorPaymentTermInputObject>>
  visible?: InputMaybe<Scalars['Boolean']>
}

export type LocalBusinessPublicInputObject = {
  businessStructure?: InputMaybe<BusinessStructureEnum>
  currency?: InputMaybe<Scalars['String']>
  initialRole?: InputMaybe<LocalBusinessRoleEnum>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  notificationsEmail?: InputMaybe<Scalars['String']>
  vendorPaymentTerms?: InputMaybe<Array<VendorPaymentTermInputObject>>
}

export enum LocalBusinessRoleEnum {
  PAYER = 'PAYER',
  VENDOR = 'VENDOR',
}

export type LocalBusinessStatusesCountType = {
  all: Scalars['Int']
  lastInvoiceDate: Maybe<Scalars['ISO8601Date']>
  lastInvoiceTotal: Maybe<Money>
  paid: Scalars['Int']
  pending: Scalars['Int']
  scheduled: Scalars['Int']
  totalAll: Money
  totalPaid: Money
  totalPending: Money
  totalScheduled: Money
}

export type LocalBusinesses = {
  data: Array<LocalBusiness>
  hasDeleted: Scalars['Boolean']
  pagination: Pagination
}

export enum LocalBusinessesPermissionsEnum {
  ACCEPT_BUSINESS_MATCH = 'ACCEPT_BUSINESS_MATCH',
  CREATE_FROM_PUBLIC_BUSINESS = 'CREATE_FROM_PUBLIC_BUSINESS',
  CREATE_LOCAL_BUSINESS = 'CREATE_LOCAL_BUSINESS',
  CREATE_LOCAL_BUSINESS_ACCOUNT = 'CREATE_LOCAL_BUSINESS_ACCOUNT',
  DELETE_LOCAL_BUSINESSES = 'DELETE_LOCAL_BUSINESSES',
  DELETE_LOCAL_BUSINESS_ACCOUNT = 'DELETE_LOCAL_BUSINESS_ACCOUNT',
  INVITE_PAYER = 'INVITE_PAYER',
  INVITE_VENDOR = 'INVITE_VENDOR',
  MERGE_WITH_PUBLIC_BUSINESS = 'MERGE_WITH_PUBLIC_BUSINESS',
  REJECT_ALL_BUSINESS_MATCHES = 'REJECT_ALL_BUSINESS_MATCHES',
  RESTORE_LOCAL_BUSINESS = 'RESTORE_LOCAL_BUSINESS',
  SYNC_WITH_PUBLIC_BUSINESS = 'SYNC_WITH_PUBLIC_BUSINESS',
  UPDATE_LOCAL_BUSINESS = 'UPDATE_LOCAL_BUSINESS',
  UPDATE_LOCAL_BUSINESS_ACCOUNT = 'UPDATE_LOCAL_BUSINESS_ACCOUNT',
}

export type MarketingVirtualCard = {
  account: Account
  accountingAccount: Maybe<AccountingAccount>
  accountingAccountId: Maybe<Scalars['ID']>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingMemo: Maybe<Scalars['String']>
  availableCredit: Money
  balance: Money
  cardNumberLast4: Scalars['String']
  currentPeriodAmount: Money
  deletable: Scalars['Boolean']
  expirationMonth: Scalars['Int']
  expirationYear: Scalars['Int']
  feeAccountingAccount: Maybe<AccountingAccount>
  feeAccountingAccountId: Maybe<Scalars['ID']>
  feeAccountingCategoryClassId: Maybe<Scalars['ID']>
  feeAccountingCategoryLocationId: Maybe<Scalars['ID']>
  feeAccountingMemo: Maybe<Scalars['String']>
  /** @deprecated Use fee_accounting_account */
  feeQuickBooksAccountId: Maybe<Scalars['ID']>
  /** @deprecated Use fee_accounting_category_class_id */
  feeQuickBooksClassId: Maybe<Scalars['ID']>
  /** @deprecated Use fee_accounting_category_location_id */
  feeQuickBooksLocationId: Maybe<Scalars['ID']>
  /** @deprecated Use fee_accounting_memo */
  feeQuickBooksMemo: Maybe<Scalars['String']>
  /** @deprecated Use fee_accounting_account_id */
  feeXeroAccountId: Maybe<Scalars['ID']>
  id: Scalars['ID']
  limit: Money
  name: Scalars['String']
  paymentSystem: Scalars['String']
  pendingAuthorizationsAmount: Money
  postalCode: Scalars['String']
  previousUnpaidAmount: Money
  /** @deprecated Use accounting_account_id */
  quickBooksAccountId: Maybe<Scalars['ID']>
  /** @deprecated Use accounting_category_class_id */
  quickBooksClassId: Maybe<Scalars['ID']>
  /** @deprecated Use accounting_category_location_id */
  quickBooksLocationId: Maybe<Scalars['ID']>
  /** @deprecated Use accounting_memo */
  quickBooksMemo: Maybe<Scalars['String']>
  repaymentPercent: Scalars['Float']
  repaymentPeriod: MarketingVirtualCardRepaymentPeriodEnum
  terms: Scalars['String']
  useSameExpanseDetailsForFee: Scalars['Boolean']
  /** @deprecated Use accounting_account */
  xeroAccountId: Maybe<Scalars['ID']>
}

export type MarketingVirtualCardAuthorization = {
  amount: Money
  createdAt: Scalars['ISO8601Date']
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: MarketingVirtualCardAuthorizationStatusEnum
}

export enum MarketingVirtualCardAuthorizationStatusEnum {
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  REVERSED = 'REVERSED',
}

export type MarketingVirtualCardInputObject = {
  accountId: Scalars['ID']
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
  feeAccountingAccountId?: InputMaybe<Scalars['ID']>
  feeAccountingCategoryClassId?: InputMaybe<Scalars['ID']>
  feeAccountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  feeAccountingMemo?: InputMaybe<Scalars['String']>
  feeQuickBooksAccountId?: InputMaybe<Scalars['ID']>
  feeQuickBooksClassId?: InputMaybe<Scalars['ID']>
  feeQuickBooksLocationId?: InputMaybe<Scalars['ID']>
  feeQuickBooksMemo?: InputMaybe<Scalars['String']>
  feeXeroAccountId?: InputMaybe<Scalars['ID']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  quickBooksClassId?: InputMaybe<Scalars['ID']>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  quickBooksMemo?: InputMaybe<Scalars['String']>
  repaymentPeriod: MarketingVirtualCardRepaymentPeriodEnum
  useSameExpanseDetailsForFee?: InputMaybe<Scalars['Boolean']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export enum MarketingVirtualCardRepaymentPeriodEnum {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type MarketingVirtualCardSecure = {
  data: Maybe<MarketingVirtualCardSecureData>
  reauthenticationRequired: Scalars['Boolean']
}

export type MarketingVirtualCardSecureData = {
  cvc: Scalars['String']
  expirationMonth: Scalars['Int']
  expirationYear: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
  number: Scalars['String']
  postalCode: Scalars['String']
}

export type MarketingVirtualCardTransaction = {
  amount: Money
  createdAt: Scalars['ISO8601Date']
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: MarketingVirtualCardTransactionStatusEnum
}

export enum MarketingVirtualCardTransactionStatusEnum {
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  UNDEFINED = 'UNDEFINED',
}

export type Membership = {
  /** Should only return null if the membership is deactivated. A null response is not expected in frontend flows. */
  business: Maybe<Business>
  externalBusiness: Maybe<PublicBusiness>
  id: Scalars['ID']
  lastTosAccepted: Maybe<Scalars['ISO8601Date']>
  role: MembershipRolesEnum
  roleAccountingAccounts: Maybe<Array<AccountingAccount>>
  user: Profile
  userRole: UserRole
}

export enum MembershipRolesEnum {
  ADMIN = 'ADMIN',
  APPROVER = 'APPROVER',
  CLERK = 'CLERK',
  CLIENT_MANAGER_ADMIN = 'CLIENT_MANAGER_ADMIN',
  CLIENT_MANAGER_TEAM_MEMBER = 'CLIENT_MANAGER_TEAM_MEMBER',
  CUSTOM = 'CUSTOM',
  REGULAR = 'REGULAR',
  VIEWER = 'VIEWER',
}

export type Message = {
  message: Scalars['String']
}

export enum MicrodepositsStatusEnum {
  PENDING = 'PENDING',
  VERIFIABLE = 'VERIFIABLE',
}

/** Search results from Middesk Name/State Search API */
export type MiddeskSearch = {
  maxAttemptsReached: Scalars['Boolean']
  providedInputMatched: Scalars['Boolean']
  suggestions: Array<MiddeskSearchSuggestion>
}

/** Middesk search result schema */
export type MiddeskSearchSuggestion = {
  name: Scalars['String']
  state: Scalars['String']
}

export type Money = {
  cents: Scalars['SafeInt']
  currency: Scalars['String']
  currencySymbol: Scalars['String']
  formatted: Scalars['String']
  formattedNoCents: Scalars['String']
  subunitToUnit: Scalars['Int']
}

export type MoneyInputObject = {
  cents: Scalars['SafeInt']
  currency: Scalars['String']
}

export type Mutation = {
  acceptBusinessMatch: Maybe<LocalBusiness>
  acceptInvitation: Maybe<AuthResponse>
  acceptInvitationAuthenticated: Maybe<Business>
  /** Create a new TosAcceptance entry for current user/business membership with specified version. */
  acceptTos: Maybe<Membership>
  acceptVendorInvitation: Maybe<AuthResponse>
  acceptVendorInvitationAuthenticated: Maybe<Business>
  activateRuleV2: Maybe<Rule>
  activateTeamMember: Maybe<TeamMember>
  activateTotp: Maybe<Scalars['Boolean']>
  addInvoicesLabels: Maybe<Array<Invoice>>
  addJourney: Maybe<Journey>
  addJourneyV2: Maybe<Array<Journey>>
  addPurchaseOrdersLabels: Maybe<Array<PurchaseOrder>>
  addTeamMemberToClient: Maybe<Scalars['Boolean']>
  addTransfer: Maybe<Transfer>
  addVendorCredit: Maybe<VendorCredit>
  amortizedRepayEarly: Maybe<Payment>
  apAgingV2: Maybe<Report>
  applyPromotionCode: Maybe<BillingPromotionCodeType>
  applyPromotionCodeV2: Maybe<BillingDiscountType>
  applyVendorCredit: Maybe<Invoice>
  approveInvoiceApprovalRequest: Maybe<Invoice>
  approvePayment: Maybe<Payment>
  approveReceivableDebit: Maybe<Receivable>
  archiveCatalogItemProperty: Maybe<CatalogItem>
  archiveCatalogItemPropertyTemplate: Maybe<CatalogItemProperty>
  /** Upload an additional document to existing invoice */
  attachInvoiceDocument: Maybe<Invoice>
  attachLocalBusinessDocument: Maybe<Attachment>
  attachPurchaseOrderDocument: Maybe<PurchaseOrder>
  attachReceiptDocument: Maybe<Receipt>
  attachW9: Maybe<LocalBusiness>
  authProviderAcceptInvitation: Maybe<AuthResponse>
  authProviderAcceptVendorInvitation: Maybe<AuthResponse>
  authProviderSignIn: Maybe<AuthResponse>
  authProviderSignUp: Maybe<AuthResponse>
  bulkPayAsapV2: Maybe<Array<BulkPayBase>>
  bulkPayOnDateV2: Maybe<Array<BulkPayBase>>
  bulkPayOnDueDateV2: Maybe<Array<BulkPayBase>>
  bulkUpdateTransfer: Maybe<Array<Transfer>>
  cancelInvoiceApprovalRequest: Maybe<Invoice>
  cancelJourneyStep: Maybe<Array<Journey>>
  cancelTransfer: Maybe<Invoice>
  cancelVendorFinancing: Maybe<ReceivableInvoice>
  changeBillingPlan: Maybe<Message>
  changeBulletEptTermLength: Maybe<Payment>
  clearPayerInvoice: Maybe<Invoice>
  convertVendorIntoPayer: Maybe<Business>
  copyOrUpdateLocalBusinessAccount: Maybe<LocalBusinessAccount>
  createAccount: Maybe<Account>
  createAmortizedLoanPaymentV2: Maybe<Payment>
  createAttachment: Maybe<Attachment>
  createBeneficialOwnersV2: Maybe<Array<BeneficialOwner>>
  createBillingMethod: Maybe<BillingMethod>
  createBulletEptPayment: Maybe<Payment>
  createBusiness: Maybe<Business>
  createByNumberPurchaseOrder: Maybe<PurchaseOrder>
  createCatalogItem: Maybe<CatalogItem>
  createCatalogItemProperty: Maybe<CatalogItem>
  createCatalogItemPropertyTemplate: Maybe<CatalogItemProperty>
  createClientBusiness: Maybe<Business>
  createCustomPurchaseOrder: Maybe<PurchaseOrder>
  createEmailOptOut: Maybe<EmailOptOut>
  createExtendedTermPayment: Maybe<Payment>
  createExtendedTermPaymentV2: Maybe<Payment>
  createFactoringAgreement: Maybe<Agreement>
  createFromPublicBusiness: Maybe<LocalBusiness>
  createInvitation: Maybe<CreateInvitationPayload>
  createLabel: Maybe<Label>
  createLocalBusiness: Maybe<LocalBusiness>
  createLocalBusinessAccount: Maybe<LocalBusinessAccount>
  createMarketingVirtualCard: Maybe<MarketingVirtualCard>
  createPayable: Maybe<Invoice>
  createPayableFromSettleInbox: Maybe<CreatePayableInvoiceResponse>
  createPayableWithoutAttachment: Maybe<Invoice>
  createPayerInvitation: Maybe<PayerInvitationV2>
  createPayment: Maybe<Payment>
  createPaymentPublicInvoice: Maybe<PublicInvoice>
  createPaymentV2: Maybe<Payment>
  /** Generates preview or signed agreement for amortized loans in Payments UX 3.0 */
  createPreliminaryAmortizedLoanAgreementV2: Maybe<Agreement>
  createPreliminaryBulletEptAgreement: Maybe<Agreement>
  createPreliminaryEptAgreement: Maybe<Agreement>
  createPreliminaryEptAgreementV2: Maybe<Agreement>
  createPurchaseOrder: Maybe<PurchaseOrder>
  createReceipt: Maybe<Receipt>
  createReceivable: Maybe<Receivable>
  createReceivableInvoice: Maybe<ReceivableInvoice>
  createRole: Maybe<UserRole>
  createRule: Maybe<Rule>
  createTotp: Maybe<CreateTotpResponse>
  createUploadedDocument: Maybe<UploadedDocument>
  createVendorCredit: Maybe<VendorCredit>
  createVendorCreditFromSettleInbox: Maybe<CreateVendorCreditResponseType>
  createVendorInvitationLink: Maybe<CreateInvitationLinkPayload>
  createW9FromSettleInbox: Maybe<CreateW9ResponseType>
  deactivateRule: Maybe<Message>
  deactivateRuleV2: Maybe<Rule>
  declineTermExtensions: Maybe<Message>
  deleteAttachment: Maybe<DeletePayload>
  deleteBillingMethod: Maybe<BillingMethod>
  deleteBusinessLogo: Maybe<Business>
  /** Clear out all instances of W9 forms attached to the business. Require BusinessDetailsPermissionsEnum::UPDATE_BUSINESS_DETAILS permission */
  deleteBusinessW9: Maybe<Business>
  deleteInvoiceAttachment: Maybe<Invoice>
  deleteInvoices: Maybe<DeletePayload>
  deleteLabels: Maybe<Message>
  deleteLocalBusinessAccount: Maybe<Message>
  deleteLocalBusinesses: Maybe<Message>
  deleteMarketingVirtualCard: Maybe<Message>
  deleteReceivable: Maybe<DeletePayload>
  deleteRoleV2: Maybe<DeleteRoleResponse>
  deleteRule: Maybe<Message>
  deleteSettleInboxMessages: Maybe<DeletePayload>
  deleteTeamMember: Maybe<Message>
  deleteTotp: Maybe<Scalars['Boolean']>
  deleteVendorCredit: Maybe<DeletePayload>
  deleteW9: Maybe<LocalBusiness>
  /** @deprecated Use rejectPayment mutation instead. */
  disapprovePayment: Maybe<Payment>
  excludeFromSync: Maybe<Array<SyncObject>>
  export1099Csv: Maybe<Report>
  exportApAgingDetails: Maybe<Report>
  exportApAgingSummary: Maybe<Report>
  exportCashOutflow: Maybe<Report>
  exportFactoringAgreements: Maybe<Report>
  exportInvoicesV2: Maybe<Report>
  exportLockboxTransactions: Maybe<Report>
  exportMvcTransactions: Maybe<Report>
  exportMyInvoices: Maybe<Report>
  exportOwedToSettle: Maybe<Report>
  exportPaymentConfirmationsV2: Maybe<Report>
  exportPurchaseOrders: Maybe<Report>
  exportReceivables: Maybe<Report>
  exportReconciliationItems: Maybe<Report>
  exportSignedDocumentsV2: Maybe<Report>
  exportVendorPaymentConfirmations: Maybe<Report>
  extendPaymentTerm: Maybe<Message>
  financeInvoice: Maybe<ReceivableInvoice>
  goBackPayerOnboardingStep: Maybe<PayerOnboarding>
  ignorePayerOnboardingStep: Maybe<PayerOnboarding>
  importBills: Maybe<Array<Invoice>>
  importHistoricalBills: Maybe<Scalars['Boolean']>
  initiateVerification: Maybe<Account>
  invitePayer: Maybe<InvitePayerPayload>
  inviteTeamMember: Maybe<Invitation>
  inviteVendor: Maybe<InviteVendorPayload>
  linkAuthProvider: Maybe<AuthResponse>
  linkFinicity: Maybe<Array<Account>>
  linkInvoiceDocuments: Maybe<Invoice>
  linkNetsuite: Maybe<NetsuiteState>
  linkPlaid: Maybe<Array<Account>>
  linkPlaidPublicInvoice: Maybe<Account>
  linkPurchaseOrderDocuments: Maybe<PurchaseOrder>
  linkQuickBooks: Maybe<QuickBooksState>
  linkReceiptDocuments: Maybe<Receipt>
  linkXero: Maybe<XeroState>
  manageAssignments: Maybe<Scalars['Boolean']>
  markAsFixed: Maybe<Array<SyncError>>
  markInvoicePaid: Maybe<Invoice>
  markInvoiceUnpaid: Maybe<Invoice>
  markReceivableInvoicePaid: Maybe<ReceivableInvoice>
  markTransferPaid: Maybe<Transfer>
  markTransferUnpaid: Maybe<Transfer>
  mergeTransfers: Maybe<Invoice>
  mergeWithPublicBusiness: Maybe<LocalBusiness>
  migrateBusinessAccount: Maybe<Account>
  newChangeBillingPlan: Maybe<Message>
  newPasswordRequest: Maybe<Message>
  /** @deprecated Use PasswordUpdateWithoutLogin instead */
  newPasswordUpdate: Maybe<AuthResponse>
  notifyEptRequest: Maybe<Message>
  notifyWrongInvoice: Maybe<Message>
  passwordUpdate: Maybe<Scalars['Boolean']>
  payNowPayment: Maybe<Payment>
  previewInvoice: Maybe<Blob>
  processKybDataRequest: Maybe<KybDataRequest>
  publicPurchaseOrderPreview: Maybe<Blob>
  purchaseOrderPreview: Maybe<PurchaseOrder>
  rejectAllBusinessMatch: Maybe<LocalBusiness>
  rejectInvoiceApprovalRequest: Maybe<Invoice>
  rejectPayment: Maybe<Payment>
  removeInvoicesLabels: Maybe<Array<Invoice>>
  removePayerAccountPublicInvoice: Maybe<PublicInvoice>
  removePurchaseOrdersLabels: Maybe<Array<PurchaseOrder>>
  repayEarlyV2: Maybe<Payment>
  reportLinkPlaidFailed: Maybe<Message>
  requestBillingInvoice: Maybe<Blob>
  requestCustomBillingPlan: Maybe<Message>
  requestInvoiceApproval: Maybe<Array<Invoice>>
  requestReceivableFactoring: Maybe<Receivable>
  requestRuleBasedApproval: Maybe<Invoice>
  requireMfa: Maybe<Business>
  reschedulePayment: Maybe<Payment>
  resetMicrodeposits: Maybe<Business>
  restoreLocalBusiness: Maybe<LocalBusiness>
  restorePaymentDate: Maybe<Payment>
  retryFailedBillingPayments: Maybe<Message>
  retryPayment: Maybe<Invoice>
  revokeInvitation: Maybe<Message>
  revokePayment: Maybe<Payment>
  revokePaymentPublicInvoice: Maybe<PublicInvoice>
  revokeReceivableFactoring: Maybe<Receivable>
  scheduleReceivableFactoring: Maybe<Receivable>
  sendInvoice: Maybe<ReceivableInvoice>
  sendPublicPurchaseOrderEmail: Maybe<Scalars['Boolean']>
  sendPurchaseOrderEmail: Maybe<PurchaseOrder>
  setBillingMethodDefault: Maybe<BillingMethod>
  setRoleV2: Maybe<Array<Membership>>
  signIn: Maybe<AuthResponse>
  signInByToken: Maybe<AuthResponse>
  signInWithTotp: Maybe<AuthResponse>
  signInWithTotpRecoveryCode: Maybe<AuthResponse>
  signUp: Maybe<AuthResponse>
  skipPayerOnboardingStep: Maybe<PayerOnboarding>
  splitTransfers: Maybe<Invoice>
  syncNetsuite: Maybe<NetsuiteState>
  syncQuickBooks: Maybe<QuickBooksState>
  syncWithPublicBusiness: Maybe<LocalBusiness>
  syncXero: Maybe<XeroState>
  testAutoEptSet: Maybe<Message>
  testBeneficialOwnersApprove: Maybe<BeneficialOwnersApprovePayload>
  testBeneficialOwnersDecline: Maybe<BeneficialOwnersDeclinePayload>
  testBeneficialOwnersStartReview: Maybe<BeneficialOwnersStartReviewPayload>
  testCreateBusiness: Maybe<CreateBusinessPayload>
  testCreateDocumentRequestItem: Maybe<CreateDocumentRequestItemPayload>
  testCreateUser: Maybe<CreateUserPayload>
  testFraudBusinessReviewApprove: Maybe<FraudBusinessReviewApprovePayload>
  testLinkPlaid: Maybe<Array<Account>>
  testMicroDepositsCompleted: Maybe<Account>
  testPayVendor: Maybe<Payment>
  testPayerKycOverride: Maybe<Business>
  testPaymentPaid: Maybe<Payment>
  testPaymentProcessed: Maybe<Payment>
  testSendPayment: Maybe<Payment>
  testVendorFinancingCreditLimit: Maybe<Business>
  unapplyVendorCredit: Maybe<Invoice>
  unlinkFinicity: Maybe<Array<Account>>
  unlinkInvoiceDocuments: Maybe<Invoice>
  unlinkNetsuite: Maybe<NetsuiteState>
  unlinkPlaid: Maybe<Array<Account>>
  unlinkPurchaseOrderDocuments: Maybe<PurchaseOrder>
  unlinkQuickBooks: Maybe<QuickBooksState>
  unlinkReceiptDocuments: Maybe<Receipt>
  unlinkXero: Maybe<XeroState>
  unrequireMfa: Maybe<Business>
  updateAccount: Maybe<Account>
  updateAccountingAutoImport: Maybe<Integration>
  updateAppliedVendorCredits: Maybe<Invoice>
  updateAttachment: Maybe<Attachment>
  updateBusinessDetails: Maybe<Business>
  updateBusinessDetailsPublicInvoice: Maybe<PublicInvoice>
  updateBusinessFeatures: Maybe<BusinessFeaturesType>
  updateBusinessLogo: Maybe<Business>
  /** Set new W9 form to the business. Require BusinessDetailsPermissionsEnum::UPDATE_BUSINESS_DETAILS permission */
  updateBusinessW9: Maybe<Business>
  updateCatalogItem: Maybe<CatalogItem>
  updateCatalogItemProperty: Maybe<CatalogItem>
  updateCatalogItemPropertyTemplate: Maybe<CatalogItemProperty>
  updateCatalogItemVariation: Maybe<CatalogItemVariation>
  updateCatalogItemsArchived: Maybe<Array<CatalogItem>>
  updateCombinedLineItems: Maybe<Invoice>
  updateExternalPayableInvoiceLineItems: Maybe<Invoice>
  updateInvoice: Maybe<Invoice>
  updateInvoiceAmount: Maybe<Invoice>
  updateInvoiceExternalAttributes: Maybe<Invoice>
  updateInvoiceInventoryLineItems: Maybe<Invoice>
  updateInvoicePayableData: Maybe<Invoice>
  updateInvoicesRead: Maybe<Array<Invoice>>
  updateLabel: Maybe<Label>
  updateLocalBusiness: Maybe<LocalBusiness>
  updateLocalBusinessAccount: Maybe<LocalBusinessAccount>
  updateLocalBusinessVisibility: Maybe<LocalBusiness>
  updateMarketingVirtualCard: Maybe<Message>
  updateNetsuitePreferences: Maybe<NetsuiteState>
  updateNotificationSettings: Maybe<NotificationSettings>
  updatePayableInvoiceLineItems: Maybe<Invoice>
  updatePayablesArchived: Maybe<Array<Invoice>>
  updatePayerInvitation: Maybe<PayerInvitationV2>
  updatePaymentAccount: Maybe<Payment>
  updatePurchaseOrder: Maybe<PurchaseOrder>
  updatePurchaseOrderLineItems: Maybe<PurchaseOrder>
  updatePurchaseOrdersArchived: Maybe<Array<PurchaseOrder>>
  updateQuickBooksPreferences: Maybe<QuickBooksState>
  updateReceipt: Maybe<Receipt>
  updateReceiptsArchived: Maybe<Array<Receipt>>
  updateReceivable: Maybe<Receivable>
  updateReceivableInvoice: Maybe<ReceivableInvoice>
  updateReceivableInvoiceLineItems: Maybe<ReceivableInvoice>
  updateReceivablesArchived: Maybe<Array<ReceivableInvoice>>
  updateRole: Maybe<UserRole>
  updateRule: Maybe<Rule>
  updateSettleInboxMessagesArchived: Maybe<Array<SettleInboxMessage>>
  updateSettleInboxMessagesRead: Maybe<Array<SettleInboxMessage>>
  updateTransfer: Maybe<Transfer>
  updateUserDetails: Maybe<Profile>
  updateVendorCredit: Maybe<VendorCredit>
  updateXeroPreferences: Maybe<XeroState>
  upsertAutopayableConfig: Maybe<SettleInboxAutopayableConfigType>
  validateInternationalWireField: Maybe<RoutingNumberValidation>
  validateRoutingNumber: Maybe<RoutingNumberValidation>
  verificationConfirm: Maybe<Profile>
  verificationRequest: Maybe<Message>
  verifyAccount: Maybe<Business>
  verifyAccountPublicInvoice: Maybe<Account>
  viewInvoiceV2: Maybe<Invoice>
  viewPublicInvoiceV2: Maybe<Invoice>
}

export type MutationacceptBusinessMatchArgs = {
  businessMatchId: Scalars['ID']
}

export type MutationacceptInvitationArgs = {
  email: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type MutationacceptInvitationAuthenticatedArgs = {
  invitationToken: Scalars['String']
}

export type MutationacceptTosArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  effectiveDate: Scalars['ISO8601Date']
}

export type MutationacceptVendorInvitationArgs = {
  email: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type MutationacceptVendorInvitationAuthenticatedArgs = {
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
}

export type MutationactivateRuleV2Args = {
  ruleId: Scalars['ID']
}

export type MutationactivateTeamMemberArgs = {
  teamMemberId: Scalars['ID']
}

export type MutationactivateTotpArgs = {
  mfaToken: Scalars['String']
  totpCode: Scalars['String']
}

export type MutationaddInvoicesLabelsArgs = {
  invoiceIds: Array<Scalars['ID']>
  labelIds: Array<Scalars['ID']>
}

export type MutationaddJourneyArgs = {
  journeyType: JourneyEnum
}

export type MutationaddJourneyV2Args = {
  journeyType: JourneyEnum
}

export type MutationaddPurchaseOrdersLabelsArgs = {
  labelIds: Array<Scalars['ID']>
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationaddTeamMemberToClientArgs = {
  clientBusinessId: Scalars['ID']
  role?: InputMaybe<MembershipRolesEnum>
  roleId?: InputMaybe<Scalars['ID']>
  teamMemberId: Scalars['ID']
}

export type MutationaddTransferArgs = {
  invoiceId: Scalars['ID']
}

export type MutationaddVendorCreditArgs = {
  vendorCreditId: Scalars['ID']
}

export type MutationamortizedRepayEarlyArgs = {
  paymentId: Scalars['ID']
}

export type MutationapAgingV2Args = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationapplyPromotionCodeArgs = {
  billingPromotionCodeId: Scalars['ID']
}

export type MutationapplyPromotionCodeV2Args = {
  billingPromotionCodeId: Scalars['ID']
}

export type MutationapplyVendorCreditArgs = {
  invoiceId: Scalars['ID']
  vendorCreditId: Scalars['ID']
}

export type MutationapproveInvoiceApprovalRequestArgs = {
  invoiceId: Scalars['ID']
}

export type MutationapprovePaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationapproveReceivableDebitArgs = {
  receivableId: Scalars['ID']
}

export type MutationarchiveCatalogItemPropertyArgs = {
  propertyId: Scalars['ID']
}

export type MutationarchiveCatalogItemPropertyTemplateArgs = {
  propertyId: Scalars['ID']
}

export type MutationattachInvoiceDocumentArgs = {
  invoiceId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachLocalBusinessDocumentArgs = {
  category: AttachmentCategoryEnum
  localBusinessId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachPurchaseOrderDocumentArgs = {
  purchaseOrderId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachReceiptDocumentArgs = {
  receiptId: Scalars['ID']
  uploadSignedId: Scalars['ID']
}

export type MutationattachW9Args = {
  attachedDocumentId: Scalars['ID']
  localBusinessId: Scalars['ID']
}

export type MutationauthProviderAcceptInvitationArgs = {
  data: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationauthProviderAcceptVendorInvitationArgs = {
  data: Scalars['String']
  firstName: Scalars['String']
  invitationToken: Scalars['String']
  lastName: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationauthProviderSignInArgs = {
  data: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationauthProviderSignUpArgs = {
  businessStructure?: InputMaybe<BusinessStructureEnum>
  company?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  data: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  invitationCode: Scalars['String']
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  provider: AuthProviderTypeEnum
  website?: InputMaybe<Scalars['String']>
}

export type MutationbulkPayAsapV2Args = {
  fromAccountId: Scalars['ID']
  invoices: Array<BulkPayInputObjectV2>
  paymentMethod: PaymentMethodEnum
}

export type MutationbulkPayOnDateV2Args = {
  fromAccountId: Scalars['ID']
  invoices: Array<BulkPayInputObjectV2>
  paymentMethod: PaymentMethodEnum
  processDate: Scalars['ISO8601Date']
}

export type MutationbulkPayOnDueDateV2Args = {
  fromAccountId: Scalars['ID']
  invoices: Array<BulkPayInputObjectV2>
  paymentMethod: PaymentMethodEnum
}

export type MutationbulkUpdateTransferArgs = {
  data: Array<TransferBulkInputObject>
  invoiceId: Scalars['ID']
}

export type MutationcancelInvoiceApprovalRequestArgs = {
  invoiceId: Scalars['ID']
}

export type MutationcancelJourneyStepArgs = {
  stepType: JourneyStepEnum
}

export type MutationcancelTransferArgs = {
  transferId: Scalars['ID']
}

export type MutationcancelVendorFinancingArgs = {
  invoiceId: Scalars['ID']
}

export type MutationchangeBillingPlanArgs = {
  billingPlanId: Scalars['ID']
}

export type MutationchangeBulletEptTermLengthArgs = {
  paymentId: Scalars['ID']
  periods: Scalars['Int']
}

export type MutationclearPayerInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationcopyOrUpdateLocalBusinessAccountArgs = {
  accountId: Scalars['ID']
  currency: Scalars['String']
  migrateInvoices: Scalars['Boolean']
  wireFields: Array<InternationalWireFieldsDataFieldInputObject>
}

export type MutationcreateAccountArgs = {
  attributes: AccountInputObject
  receiving?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateAmortizedLoanPaymentV2Args = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
}

export type MutationcreateAttachmentArgs = {
  category: AttachmentCategoryEnum
  uploadSignedId: Scalars['ID']
}

export type MutationcreateBeneficialOwnersV2Args = {
  businessBeneficialOwners?: InputMaybe<Array<BusinessBeneficialOwnerInput>>
  individualBeneficialOwners?: InputMaybe<Array<BeneficialOwnerIndividualInputType>>
}

export type MutationcreateBillingMethodArgs = {
  accountId?: InputMaybe<Scalars['ID']>
  default?: InputMaybe<Scalars['Boolean']>
  stripeCardToken?: InputMaybe<Scalars['String']>
}

export type MutationcreateBulletEptPaymentArgs = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
  periods: Scalars['Int']
}

export type MutationcreateBusinessArgs = {
  businessStructure: BusinessStructureEnum
  firstName?: InputMaybe<Scalars['String']>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type MutationcreateByNumberPurchaseOrderArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateCatalogItemArgs = {
  attributes: CatalogItemInputObject
}

export type MutationcreateCatalogItemPropertyArgs = {
  attributes?: InputMaybe<CatalogItemPropertyInputObject>
  catalogItemId: Scalars['ID']
  templateId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateCatalogItemPropertyTemplateArgs = {
  attributes: CatalogItemPropertyInputObject
}

export type MutationcreateClientBusinessArgs = {
  businessStructure: BusinessStructureEnum
  firstName?: InputMaybe<Scalars['String']>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type MutationcreateCustomPurchaseOrderArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateEmailOptOutArgs = {
  optOutToken: Scalars['String']
}

export type MutationcreateExtendedTermPaymentArgs = {
  invoiceId: Scalars['ID']
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  transferId?: InputMaybe<Scalars['ID']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreateExtendedTermPaymentV2Args = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
  offerIds: Array<Scalars['ID']>
}

export type MutationcreateFactoringAgreementArgs = {
  invoiceId: Scalars['ID']
  signed?: InputMaybe<Scalars['Boolean']>
}

export type MutationcreateFromPublicBusinessArgs = {
  attributes: LocalBusinessPublicInputObject
  catalogItemId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  publicBusinessId: Scalars['ID']
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateLabelArgs = {
  attributes: LabelInputObject
}

export type MutationcreateLocalBusinessArgs = {
  accountAttributes?: InputMaybe<LocalBusinessAccountInputObject>
  attributes: LocalBusinessInputObject
  catalogItemId?: InputMaybe<Scalars['ID']>
  invoiceId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateLocalBusinessAccountArgs = {
  attributes: LocalBusinessAccountInputObject
  localBusinessId: Scalars['ID']
}

export type MutationcreateMarketingVirtualCardArgs = {
  data?: InputMaybe<MarketingVirtualCardInputObject>
}

export type MutationcreatePayableArgs = {
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
  uploadSignedId: Scalars['ID']
}

export type MutationcreatePayableFromSettleInboxArgs = {
  attributes: PayableInvoiceInputObject
  settleInboxAttachmentId?: InputMaybe<Scalars['ID']>
  settleInboxMessageId: Scalars['ID']
}

export type MutationcreatePayableWithoutAttachmentArgs = {
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type MutationcreatePayerInvitationArgs = {
  accountingFirmId?: InputMaybe<Scalars['ID']>
  billingPromotionCodeId?: InputMaybe<Scalars['ID']>
  email: Scalars['String']
  interestedIn: Array<SignupInterestsEnum>
  referralCode?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
}

export type MutationcreatePaymentArgs = {
  invoiceId: Scalars['ID']
  processDate?: InputMaybe<Scalars['ISO8601Date']>
  transferId?: InputMaybe<Scalars['ID']>
}

export type MutationcreatePaymentPublicInvoiceArgs = {
  processDate?: InputMaybe<Scalars['ISO8601Date']>
  uuid: Scalars['String']
}

export type MutationcreatePaymentV2Args = {
  data: PaymentInputObject
  invoiceId: Scalars['ID']
}

export type MutationcreatePreliminaryAmortizedLoanAgreementV2Args = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  signed?: InputMaybe<Scalars['Boolean']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePreliminaryBulletEptAgreementArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  periods: Scalars['Int']
  signed?: InputMaybe<Scalars['Boolean']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePreliminaryEptAgreementArgs = {
  offerIds: Array<Scalars['ID']>
  signed?: InputMaybe<Scalars['Boolean']>
  transferId: Scalars['ID']
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePreliminaryEptAgreementV2Args = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  offerIds: Array<Scalars['ID']>
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  signed?: InputMaybe<Scalars['Boolean']>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationcreatePurchaseOrderArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  receiptId?: InputMaybe<Scalars['ID']>
  uploadSignedId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateReceiptArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  number?: InputMaybe<Scalars['String']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  uploadSignedId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateReceivableArgs = {
  uploadSignedId: Scalars['ID']
}

export type MutationcreateRoleArgs = {
  description: Scalars['String']
  name: Scalars['String']
  permissionSets: Array<PermissionSetInputObject>
}

export type MutationcreateRuleArgs = {
  actions?: InputMaybe<Array<RuleActionInputObject>>
  conditions?: InputMaybe<Array<RuleConditionInputObject>>
  name: Scalars['String']
  targetType?: InputMaybe<RuleTypeEnum>
}

export type MutationcreateUploadedDocumentArgs = {
  documentRequestId: Scalars['ID']
  uploadSignedIds: Array<Scalars['ID']>
}

export type MutationcreateVendorCreditArgs = {
  uploadSignedId: Scalars['ID']
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type MutationcreateVendorCreditFromSettleInboxArgs = {
  settleInboxAttachmentId: Scalars['ID']
  settleInboxMessageId: Scalars['ID']
}

export type MutationcreateVendorInvitationLinkArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationcreateW9FromSettleInboxArgs = {
  settleInboxAttachmentId: Scalars['ID']
  settleInboxMessageId: Scalars['ID']
  vendorLocalBusinessId: Scalars['ID']
}

export type MutationdeactivateRuleArgs = {
  ruleId: Scalars['ID']
}

export type MutationdeactivateRuleV2Args = {
  ruleId: Scalars['ID']
}

export type MutationdeclineTermExtensionsArgs = {
  offerIds: Array<Scalars['ID']>
  paymentId: Scalars['ID']
}

export type MutationdeleteAttachmentArgs = {
  attachmentId: Scalars['ID']
}

export type MutationdeleteBillingMethodArgs = {
  billingMethodId: Scalars['ID']
}

export type MutationdeleteInvoiceAttachmentArgs = {
  attachmentId?: InputMaybe<Scalars['ID']>
  invoiceId: Scalars['ID']
}

export type MutationdeleteInvoicesArgs = {
  invoiceIds: Array<Scalars['ID']>
}

export type MutationdeleteLabelsArgs = {
  labelIds: Array<Scalars['ID']>
}

export type MutationdeleteLocalBusinessAccountArgs = {
  accountId: Scalars['ID']
}

export type MutationdeleteLocalBusinessesArgs = {
  localBusinessIds: Array<Scalars['ID']>
}

export type MutationdeleteReceivableArgs = {
  receivableIds: Array<Scalars['ID']>
}

export type MutationdeleteRoleV2Args = {
  roleId: Scalars['ID']
  roleIdToAssign?: InputMaybe<Scalars['ID']>
}

export type MutationdeleteRuleArgs = {
  ruleId: Scalars['ID']
}

export type MutationdeleteSettleInboxMessagesArgs = {
  settleInboxMessageIds: Array<Scalars['ID']>
}

export type MutationdeleteTeamMemberArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  teamMemberId: Scalars['ID']
}

export type MutationdeleteVendorCreditArgs = {
  vendorCreditId: Scalars['ID']
}

export type MutationdeleteW9Args = {
  localBusinessId: Scalars['ID']
}

export type MutationdisapprovePaymentArgs = {
  notifyUserIds?: InputMaybe<Array<Scalars['ID']>>
  paymentId: Scalars['ID']
  rejectionNote?: InputMaybe<Scalars['String']>
}

export type MutationexcludeFromSyncArgs = {
  excludeFromSync: Scalars['Boolean']
  syncObjectIds: Array<Scalars['ID']>
  syncObjectType: SyncObjectTypeEnum
}

export type Mutationexport1099CsvArgs = {
  year?: InputMaybe<Scalars['Int']>
}

export type MutationexportApAgingDetailsArgs = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
  includeVendorCredits?: InputMaybe<Scalars['Boolean']>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportApAgingSummaryArgs = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportFactoringAgreementsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  receivableIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationexportInvoicesV2Args = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
  sorting?: InputMaybe<InvoiceSortingInputObject>
}

export type MutationexportLockboxTransactionsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationexportMvcTransactionsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationexportMyInvoicesArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationexportOwedToSettleArgs = {
  asOfDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationexportPaymentConfirmationsV2Args = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportPurchaseOrdersArgs = {
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrdersFilter?: InputMaybe<PurchaseOrdersFilteringInputObject>
}

export type MutationexportReceivablesArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  receivableIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationexportReconciliationItemsArgs = {
  reconciliationItemIds?: InputMaybe<Array<Scalars['ID']>>
  reconciliationItemsFilter?: InputMaybe<ReconciliationItemsFilteringInputObject>
  sorting?: InputMaybe<ReconciliationItemSortingInputObject>
}

export type MutationexportSignedDocumentsV2Args = {
  dateRange?: InputMaybe<DateFilteringInputObject>
  documentType: SignedDocumentTypesEnum
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
}

export type MutationexportVendorPaymentConfirmationsArgs = {
  dateRange?: InputMaybe<DateFilteringInputObject>
}

export type MutationextendPaymentTermArgs = {
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  paymentId: Scalars['ID']
}

export type MutationfinanceInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationignorePayerOnboardingStepArgs = {
  step: PayerOnboardingStepEnum
}

export type MutationimportBillsArgs = {
  billIds: Array<Scalars['ID']>
}

export type MutationimportHistoricalBillsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  startDate: Scalars['ISO8601Date']
}

export type MutationinitiateVerificationArgs = {
  accountId: Scalars['ID']
  accountNumber: Scalars['String']
  routingNumber?: InputMaybe<Scalars['String']>
  wireRoutingNumber?: InputMaybe<Scalars['String']>
}

export type MutationinvitePayerArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationinviteTeamMemberArgs = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  email: Scalars['String']
  role?: InputMaybe<MembershipRolesEnum>
  roleId?: InputMaybe<Scalars['ID']>
}

export type MutationinviteVendorArgs = {
  email?: InputMaybe<Scalars['String']>
  invoiceId?: InputMaybe<Scalars['ID']>
  localBusinessId: Scalars['ID']
}

export type MutationlinkAuthProviderArgs = {
  data: Scalars['String']
  provider: AuthProviderTypeEnum
}

export type MutationlinkInvoiceDocumentsArgs = {
  invoiceId: Scalars['ID']
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  receiptIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationlinkNetsuiteArgs = {
  accountId: Scalars['String']
  analyticsRoleId?: InputMaybe<Scalars['String']>
  analyticsTokenId?: InputMaybe<Scalars['String']>
  analyticsTokenSecret?: InputMaybe<Scalars['String']>
  apiTokenId: Scalars['String']
  apiTokenSecret: Scalars['String']
  businessId?: InputMaybe<Scalars['ID']>
  integrationKey: Scalars['String']
  integrationSecret: Scalars['String']
  subsidiaryId: Scalars['String']
}

export type MutationlinkPlaidArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  publicToken: Scalars['String']
  selectedAccountIds?: InputMaybe<Array<Scalars['String']>>
}

export type MutationlinkPlaidPublicInvoiceArgs = {
  publicToken: Scalars['String']
  selectedAccountId: Scalars['String']
  uuid: Scalars['String']
}

export type MutationlinkPurchaseOrderDocumentsArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrderId: Scalars['ID']
  receiptIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationlinkQuickBooksArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  code: Scalars['String']
}

export type MutationlinkReceiptDocumentsArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  receiptId: Scalars['ID']
}

export type MutationlinkXeroArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  code: Scalars['String']
}

export type MutationmanageAssignmentsArgs = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  accountingFirmId: Scalars['ID']
  assignments: Array<ClientManagerAssignmentInputObject>
  clientId: Scalars['ID']
}

export type MutationmarkAsFixedArgs = {
  markAsFixed: Scalars['Boolean']
  syncErrorIds: Array<Scalars['ID']>
}

export type MutationmarkInvoicePaidArgs = {
  accountId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  datePaid?: InputMaybe<Scalars['ISO8601Date']>
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
}

export type MutationmarkInvoiceUnpaidArgs = {
  invoiceId: Scalars['ID']
}

export type MutationmarkReceivableInvoicePaidArgs = {
  datePaid: Scalars['ISO8601Date']
  invoiceId: Scalars['ID']
  paymentMethod: PaymentMethodEnum
}

export type MutationmarkTransferPaidArgs = {
  invoiceId: Scalars['ID']
  transferId: Scalars['ID']
}

export type MutationmarkTransferUnpaidArgs = {
  invoiceId: Scalars['ID']
  transferId: Scalars['ID']
}

export type MutationmergeTransfersArgs = {
  invoiceId: Scalars['ID']
}

export type MutationmergeWithPublicBusinessArgs = {
  localBusinessId: Scalars['ID']
  publicBusinessId: Scalars['ID']
}

export type MutationmigrateBusinessAccountArgs = {
  accountId: Scalars['ID']
  newAccountId: Scalars['ID']
}

export type MutationnewChangeBillingPlanArgs = {
  billingPlanId: Scalars['ID']
}

export type MutationnewPasswordRequestArgs = {
  email: Scalars['String']
}

export type MutationnewPasswordUpdateArgs = {
  password: Scalars['String']
  resetToken: Scalars['String']
}

export type MutationnotifyEptRequestArgs = {
  invoiceId: Scalars['ID']
  reason?: InputMaybe<InvoicesEptReasonEnum>
}

export type MutationnotifyWrongInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationpasswordUpdateArgs = {
  password: Scalars['String']
  resetToken: Scalars['String']
}

export type MutationpayNowPaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationpreviewInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationprocessKybDataRequestArgs = {
  dataRequestInput: KybDataRequestInput
}

export type MutationpublicPurchaseOrderPreviewArgs = {
  purchaseOrderData: PublicPurchaseOrderInputObject
}

export type MutationpurchaseOrderPreviewArgs = {
  purchaseOrderId: Scalars['ID']
}

export type MutationrejectAllBusinessMatchArgs = {
  businessMatchIds: Array<Scalars['ID']>
}

export type MutationrejectInvoiceApprovalRequestArgs = {
  invoiceId: Scalars['ID']
  notifyUserIds: Array<Scalars['ID']>
  rejectionNote?: InputMaybe<Scalars['String']>
}

export type MutationrejectPaymentArgs = {
  notifyUserIds?: InputMaybe<Array<Scalars['ID']>>
  paymentId: Scalars['ID']
  rejectionNote?: InputMaybe<Scalars['String']>
}

export type MutationremoveInvoicesLabelsArgs = {
  invoiceIds: Array<Scalars['ID']>
  labelIds: Array<Scalars['ID']>
}

export type MutationremovePayerAccountPublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type MutationremovePurchaseOrdersLabelsArgs = {
  labelIds: Array<Scalars['ID']>
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationrepayEarlyV2Args = {
  paymentId: Scalars['ID']
  repaymentAmount: MoneyInputObject
}

export type MutationreportLinkPlaidFailedArgs = {
  displayMessage?: InputMaybe<Scalars['String']>
  errorCode?: InputMaybe<Scalars['String']>
  errorMessage?: InputMaybe<Scalars['String']>
  errorType?: InputMaybe<Scalars['String']>
  institutionId?: InputMaybe<Scalars['String']>
  linkSessionId: Scalars['String']
  status?: InputMaybe<Scalars['String']>
}

export type MutationrequestBillingInvoiceArgs = {
  billingPaymentId: Scalars['ID']
}

export type MutationrequestInvoiceApprovalArgs = {
  condition: RuleActionConditionEnum
  invoiceIds: Array<Scalars['ID']>
  values: Array<Scalars['String']>
}

export type MutationrequestReceivableFactoringArgs = {
  receivableId: Scalars['ID']
}

export type MutationrequestRuleBasedApprovalArgs = {
  invoiceId: Scalars['ID']
}

export type MutationreschedulePaymentArgs = {
  paymentId: Scalars['ID']
  processOn: Scalars['ISO8601Date']
}

export type MutationresetMicrodepositsArgs = {
  accountId: Scalars['ID']
  businessId?: InputMaybe<Scalars['ID']>
}

export type MutationrestoreLocalBusinessArgs = {
  localBusinessId: Scalars['ID']
}

export type MutationrestorePaymentDateArgs = {
  paymentId: Scalars['ID']
}

export type MutationretryPaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationrevokeInvitationArgs = {
  invitationId: Scalars['ID']
}

export type MutationrevokePaymentArgs = {
  paymentId: Scalars['ID']
}

export type MutationrevokePaymentPublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type MutationrevokeReceivableFactoringArgs = {
  receivableId: Scalars['ID']
}

export type MutationscheduleReceivableFactoringArgs = {
  ccAddress?: InputMaybe<Scalars['String']>
  receivableId: Scalars['ID']
}

export type MutationsendInvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type MutationsendPublicPurchaseOrderEmailArgs = {
  blobId: Scalars['ID']
  receivingEmail: Scalars['String']
}

export type MutationsendPurchaseOrderEmailArgs = {
  body: Scalars['String']
  purchaseOrderId: Scalars['ID']
  receivingEmails: Array<Scalars['String']>
}

export type MutationsetBillingMethodDefaultArgs = {
  billingMethodId: Scalars['ID']
}

export type MutationsetRoleV2Args = {
  accountingAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  businessId?: InputMaybe<Scalars['ID']>
  roleId: Scalars['ID']
  teamMemberIds: Array<Scalars['ID']>
}

export type MutationsignInArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationsignInByTokenArgs = {
  signInToken: Scalars['String']
}

export type MutationsignInWithTotpArgs = {
  email: Scalars['String']
  mfaToken: Scalars['String']
  totpCode: Scalars['String']
}

export type MutationsignInWithTotpRecoveryCodeArgs = {
  mfaToken: Scalars['ID']
  recoveryCode: Scalars['String']
}

export type MutationsignUpArgs = {
  businessStructure?: InputMaybe<BusinessStructureEnum>
  company?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  invitationCode: Scalars['String']
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type MutationskipPayerOnboardingStepArgs = {
  step: PayerOnboardingStepEnum
}

export type MutationsplitTransfersArgs = {
  invoiceId: Scalars['ID']
}

export type MutationsyncWithPublicBusinessArgs = {
  localBusinessId: Scalars['ID']
  publicBusinessId: Scalars['ID']
}

export type MutationtestAutoEptSetArgs = {
  amortizedPeriod?: InputMaybe<Scalars['Int']>
  amortizedPeriods?: InputMaybe<Scalars['Int']>
  amortizedRate?: InputMaybe<Scalars['Float']>
  businessId?: InputMaybe<Scalars['ID']>
  creditLimitCents: Scalars['Int']
  eptLength?: InputMaybe<Scalars['Int']>
  eptRate?: InputMaybe<Scalars['Float']>
  numEptMax?: InputMaybe<Scalars['Int']>
}

export type MutationtestBeneficialOwnersApproveArgs = {
  beneficialOwnerId: Scalars['ID']
}

export type MutationtestBeneficialOwnersDeclineArgs = {
  beneficialOwnerId: Scalars['ID']
}

export type MutationtestBeneficialOwnersStartReviewArgs = {
  beneficialOwnerId: Scalars['ID']
}

export type MutationtestCreateBusinessArgs = {
  companyName: Scalars['String']
  traits?: InputMaybe<Array<BusinessTraitsEnum>>
}

export type MutationtestCreateDocumentRequestItemArgs = {
  beneficialOwnerId?: InputMaybe<Scalars['ID']>
  category: DocumentCategoryEnum
}

export type MutationtestCreateUserArgs = {
  businessId: Scalars['ID']
  email: Scalars['String']
  password: Scalars['String']
  traits?: InputMaybe<Array<UserTraitsEnum>>
}

export type MutationtestFraudBusinessReviewApproveArgs = {
  businessId: Scalars['ID']
}

export type MutationtestLinkPlaidArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type MutationtestMicroDepositsCompletedArgs = {
  accountId: Scalars['ID']
}

export type MutationtestPayVendorArgs = {
  paymentId: Scalars['ID']
}

export type MutationtestPayerKycOverrideArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  verified?: InputMaybe<Scalars['Boolean']>
}

export type MutationtestPaymentPaidArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type MutationtestPaymentProcessedArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type MutationtestSendPaymentArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  paymentId?: InputMaybe<Scalars['ID']>
}

export type MutationtestVendorFinancingCreditLimitArgs = {
  businessId: Scalars['ID']
  creditLimitCents: Scalars['Int']
  expirationDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type MutationunapplyVendorCreditArgs = {
  invoiceId: Scalars['ID']
  vendorCreditId: Scalars['ID']
}

export type MutationunlinkFinicityArgs = {
  finicityConnectionId: Scalars['ID']
}

export type MutationunlinkInvoiceDocumentsArgs = {
  invoiceId: Scalars['ID']
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  receiptIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationunlinkPlaidArgs = {
  plaidConnectionId: Scalars['ID']
}

export type MutationunlinkPurchaseOrderDocumentsArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrderId: Scalars['ID']
  receiptIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationunlinkReceiptDocumentsArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  receiptId: Scalars['ID']
}

export type MutationupdateAccountArgs = {
  accountId: Scalars['ID']
  accountType?: InputMaybe<AccountTypeEnum>
  accountingAccountId?: InputMaybe<Scalars['ID']>
  currency?: InputMaybe<Scalars['String']>
  hidden?: InputMaybe<Scalars['Boolean']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  wireFields?: InputMaybe<Array<InternationalWireFieldsDataFieldInputObject>>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateAccountingAutoImportArgs = {
  autoImportBills: Scalars['Boolean']
}

export type MutationupdateAppliedVendorCreditsArgs = {
  invoiceId: Scalars['ID']
  scheduledFor?: InputMaybe<Scalars['ISO8601Date']>
  vendorCreditApplications: Array<VendorCreditApplicationInputObject>
}

export type MutationupdateAttachmentArgs = {
  attachmentId: Scalars['ID']
  category?: InputMaybe<AttachmentCategoryEnum>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type MutationupdateBusinessDetailsArgs = {
  address?: InputMaybe<AddressInputObject>
  bankAccount?: InputMaybe<LocalBusinessAccountInputObject>
  businessStructure?: InputMaybe<BusinessStructureEnum>
  doingBusinessAsNames?: InputMaybe<Array<Scalars['String']>>
  firstName?: InputMaybe<Scalars['String']>
  industry?: InputMaybe<BusinessIndustryEnum>
  inputStateOfFormation?: InputMaybe<Scalars['String']>
  is1099Eligible?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  mailingAddress?: InputMaybe<AddressInputObject>
  name?: InputMaybe<Scalars['String']>
  preferredPayingAccountId?: InputMaybe<Scalars['ID']>
  preferredReceivingAccountId?: InputMaybe<Scalars['ID']>
  prohibitedIndustryConfirmation?: InputMaybe<Scalars['Boolean']>
  taxpayerId?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type MutationupdateBusinessDetailsPublicInvoiceArgs = {
  bankAccount?: InputMaybe<AccountInputObject>
  name?: InputMaybe<Scalars['String']>
  payerNotificationsEmail?: InputMaybe<Scalars['String']>
  uuid: Scalars['String']
}

export type MutationupdateBusinessFeaturesArgs = {
  features: BusinessFeaturesInputObject
}

export type MutationupdateBusinessLogoArgs = {
  uploadSignedId: Scalars['ID']
}

export type MutationupdateBusinessW9Args = {
  uploadSignedId: Scalars['ID']
}

export type MutationupdateCatalogItemArgs = {
  attributes: CatalogItemInputObject
  id: Scalars['ID']
}

export type MutationupdateCatalogItemPropertyArgs = {
  attributes: CatalogItemPropertyInputObject
  propertyId: Scalars['ID']
}

export type MutationupdateCatalogItemPropertyTemplateArgs = {
  attributes: CatalogItemPropertyInputObject
  propertyId: Scalars['ID']
}

export type MutationupdateCatalogItemVariationArgs = {
  attributes: CatalogItemVariationInputObject
  variationId: Scalars['ID']
}

export type MutationupdateCatalogItemsArchivedArgs = {
  archived: Scalars['Boolean']
  catalogItemIds: Array<Scalars['ID']>
}

export type MutationupdateCombinedLineItemsArgs = {
  invoiceCombinedLineItems?: InputMaybe<Array<CombinedLineItemInputObject>>
  invoiceId: Scalars['ID']
  invoiceLineItems?: InputMaybe<Array<InvoiceLineItemInputObject>>
}

export type MutationupdateExternalPayableInvoiceLineItemsArgs = {
  invoiceId: Scalars['ID']
  lineItems: Array<ExternalPayableLineItemInputObject>
}

export type MutationupdateInvoiceArgs = {
  invoiceData: InvoiceInputObject
  invoiceId: Scalars['ID']
  paperCheckDetails?: InputMaybe<PaperCheckDetailsInputObject>
}

export type MutationupdateInvoiceAmountArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
}

export type MutationupdateInvoiceExternalAttributesArgs = {
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingMemo?: InputMaybe<Scalars['String']>
  invoiceId: Scalars['ID']
  lineItems?: InputMaybe<Array<InvoiceLineItemInputObject>>
  paymentAccountingAccountId?: InputMaybe<Scalars['ID']>
  quickbooksLocationId?: InputMaybe<Scalars['ID']>
  quickbooksMemo?: InputMaybe<Scalars['String']>
  quickbooksPaymentAccountId?: InputMaybe<Scalars['ID']>
  xeroPaymentAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateInvoiceInventoryLineItemsArgs = {
  invoiceId: Scalars['ID']
  invoiceInventoryLineItems: Array<InvoiceInventoryLineItemInputObject>
}

export type MutationupdateInvoicePayableDataArgs = {
  invoiceId: Scalars['ID']
  payableData: InvoicePayableDataInputObject
}

export type MutationupdateInvoicesReadArgs = {
  invoiceIds: Array<Scalars['ID']>
  read: Scalars['Boolean']
}

export type MutationupdateLabelArgs = {
  attributes: LabelInputObject
  labelId: Scalars['ID']
}

export type MutationupdateLocalBusinessArgs = {
  accountAttributes?: InputMaybe<LocalBusinessAccountInputObject>
  attributes: LocalBusinessInputObject
  localBusinessId: Scalars['ID']
}

export type MutationupdateLocalBusinessAccountArgs = {
  accountId: Scalars['ID']
  attributes: LocalBusinessAccountInputObject
}

export type MutationupdateLocalBusinessVisibilityArgs = {
  localBusinessId: Scalars['ID']
  visible: Scalars['Boolean']
}

export type MutationupdateMarketingVirtualCardArgs = {
  data: MarketingVirtualCardInputObject
}

export type MutationupdateNetsuitePreferencesArgs = {
  accountMappings: Array<NetsuiteAccountMappingInputObject>
  eptSyncEnabled?: InputMaybe<Scalars['Boolean']>
  feeExpensesAccountId?: InputMaybe<Scalars['ID']>
  readOnly?: InputMaybe<Scalars['Boolean']>
  receivableBankAccountId?: InputMaybe<Scalars['ID']>
  settleLiabilityAccountId?: InputMaybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncStartDate?: InputMaybe<Scalars['ISO8601Date']>
  syncUncategorizedExpenses?: InputMaybe<Scalars['Boolean']>
  unallocatedExpensesAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateNotificationSettingsArgs = {
  notificationSettings: NotificationSettingsInputObject
}

export type MutationupdatePayableInvoiceLineItemsArgs = {
  invoiceId: Scalars['ID']
  lineItems: Array<PayableLineItemInputObject>
}

export type MutationupdatePayablesArchivedArgs = {
  archived: Scalars['Boolean']
  invoiceIds: Array<Scalars['ID']>
}

export type MutationupdatePayerInvitationArgs = {
  approximateRevenue?: InputMaybe<Scalars['String']>
  bankAccountLocation?: InputMaybe<Scalars['String']>
  billingPromotionCodeId?: InputMaybe<Scalars['ID']>
  businessName?: InputMaybe<Scalars['String']>
  countryOfRegistration?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  interestedIn?: InputMaybe<Array<SignupInterestsEnum>>
  invitationCode: Scalars['String']
  lastName?: InputMaybe<Scalars['String']>
  prohibitedIndustryConfirmation?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type MutationupdatePaymentAccountArgs = {
  accountId: Scalars['ID']
  paymentId: Scalars['ID']
}

export type MutationupdatePurchaseOrderArgs = {
  purchaseOrderData?: InputMaybe<PurchaseOrderInputObject>
  purchaseOrderId: Scalars['ID']
}

export type MutationupdatePurchaseOrderLineItemsArgs = {
  purchaseOrderId: Scalars['ID']
  purchaseOrderLineItems: Array<PurchaseOrderLineItemInputObject>
}

export type MutationupdatePurchaseOrdersArchivedArgs = {
  archived: Scalars['Boolean']
  purchaseOrderIds: Array<Scalars['ID']>
}

export type MutationupdateQuickBooksPreferencesArgs = {
  accountMappings: Array<QuickBooksAccountMappingInputObject>
  eptSyncEnabled?: InputMaybe<Scalars['Boolean']>
  feeExpensesAccountId?: InputMaybe<Scalars['ID']>
  readOnly?: InputMaybe<Scalars['Boolean']>
  receivableBankAccountId?: InputMaybe<Scalars['ID']>
  settleLiabilityAccountId?: InputMaybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncStartDate?: InputMaybe<Scalars['ISO8601Date']>
  syncUncategorizedExpenses?: InputMaybe<Scalars['Boolean']>
  trackClasses: Scalars['Boolean']
  trackLocations: Scalars['Boolean']
  unallocatedExpensesAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupdateReceiptArgs = {
  receiptData?: InputMaybe<ReceiptInputObject>
  receiptId: Scalars['ID']
}

export type MutationupdateReceiptsArchivedArgs = {
  archived: Scalars['Boolean']
  receiptIds: Array<Scalars['ID']>
}

export type MutationupdateReceivableArgs = {
  data: ReceivableInputObject
  receivableId: Scalars['ID']
}

export type MutationupdateReceivableInvoiceArgs = {
  invoiceData: ReceivableInvoiceInputObject
  invoiceId: Scalars['ID']
}

export type MutationupdateReceivableInvoiceLineItemsArgs = {
  invoiceId: Scalars['ID']
  lineItems: Array<ReceivableLineItemInputObject>
}

export type MutationupdateReceivablesArchivedArgs = {
  archived: Scalars['Boolean']
  invoiceIds: Array<Scalars['ID']>
}

export type MutationupdateRoleArgs = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  permissionSets?: InputMaybe<Array<PermissionSetInputObject>>
  roleId: Scalars['ID']
}

export type MutationupdateRuleArgs = {
  actions?: InputMaybe<Array<RuleActionInputObject>>
  conditions?: InputMaybe<Array<RuleConditionInputObject>>
  name: Scalars['String']
  ruleId: Scalars['ID']
  targetType?: InputMaybe<RuleTypeEnum>
}

export type MutationupdateSettleInboxMessagesArchivedArgs = {
  archived: Scalars['Boolean']
  settleInboxMessageIds: Array<Scalars['ID']>
}

export type MutationupdateSettleInboxMessagesReadArgs = {
  settleInboxMessageIds: Array<Scalars['ID']>
}

export type MutationupdateTransferArgs = {
  data: TransferInputObject
  invoiceId: Scalars['ID']
  transferId: Scalars['ID']
}

export type MutationupdateUserDetailsArgs = {
  email?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type MutationupdateVendorCreditArgs = {
  vendorCreditData: VendorCreditInputObject
  vendorCreditId: Scalars['ID']
}

export type MutationupdateXeroPreferencesArgs = {
  accountMappings: Array<XeroAccountMappingInputObject>
  eptSyncEnabled?: InputMaybe<Scalars['Boolean']>
  feeExpensesAccountId?: InputMaybe<Scalars['ID']>
  readOnly?: InputMaybe<Scalars['Boolean']>
  settleLiabilityAccountId?: InputMaybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncStartDate?: InputMaybe<Scalars['ISO8601Date']>
  syncUncategorizedExpenses?: InputMaybe<Scalars['Boolean']>
  trackCategoryOne: Scalars['Boolean']
  trackCategoryTwo: Scalars['Boolean']
  unallocatedExpensesAccountId?: InputMaybe<Scalars['ID']>
}

export type MutationupsertAutopayableConfigArgs = {
  allowlistEmails: Array<Scalars['String']>
}

export type MutationvalidateInternationalWireFieldArgs = {
  wireField: InternationalWireFieldsDataFieldInputObject
}

export type MutationvalidateRoutingNumberArgs = {
  routingNumber: Scalars['String']
}

export type MutationverificationConfirmArgs = {
  verificationToken: Scalars['String']
}

export type MutationverificationRequestArgs = {
  payerOnboarding?: InputMaybe<Scalars['Boolean']>
}

export type MutationverifyAccountArgs = {
  accountId?: InputMaybe<Scalars['ID']>
  amountCents1: Scalars['Int']
  amountCents2: Scalars['Int']
}

export type MutationverifyAccountPublicInvoiceArgs = {
  amountCents1: Scalars['Int']
  amountCents2: Scalars['Int']
  uuid: Scalars['String']
}

export type MutationviewInvoiceV2Args = {
  invoiceId: Scalars['ID']
}

export type MutationviewPublicInvoiceV2Args = {
  uuid: Scalars['String']
}

export enum MvcPermissionsEnum {
  CREATE_MARKETING_VIRTUAL_CARD = 'CREATE_MARKETING_VIRTUAL_CARD',
  DELETE_MARKETING_VIRTUAL_CARD = 'DELETE_MARKETING_VIRTUAL_CARD',
  UPDATE_MARKETING_VIRTUAL_CARD = 'UPDATE_MARKETING_VIRTUAL_CARD',
}

export type NetsuiteAccountMapping = {
  accountId: Scalars['ID']
  externalAccountId: Maybe<Scalars['ID']>
  netsuiteAccountId: Maybe<Scalars['ID']>
}

export type NetsuiteAccountMappingInputObject = {
  accountId: Scalars['ID']
  externalAccountId?: InputMaybe<Scalars['ID']>
  netsuiteAccountId?: InputMaybe<Scalars['ID']>
}

export type NetsuiteState = {
  accountMappings: Array<NetsuiteAccountMapping>
  accounts: Array<AccountingAccount>
  analyticsConnected: Scalars['Boolean']
  apiConnected: Scalars['Boolean']
  autoImportBills: Scalars['Boolean']
  bankAccounts: Array<AccountingAccount>
  bookCloseDate: Maybe<Scalars['ISO8601Date']>
  creditCardAccounts: Array<AccountingAccount>
  eptSyncEnabled: Scalars['Boolean']
  eptSyncStartDate: Maybe<Scalars['ISO8601Date']>
  expenseAccounts: Array<AccountingAccount>
  feeExpensesAccountId: Maybe<Scalars['ID']>
  filteredAccounts: Array<AccountingAccount>
  liabilityAccounts: Array<AccountingAccount>
  readOnly: Scalars['Boolean']
  receivableBankAccountId: Maybe<Scalars['ID']>
  settleLiabilityAccountId: Maybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartDate: Maybe<Scalars['ISO8601Date']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: SyncStateEnum
  syncUncategorizedExpenses: Scalars['Boolean']
  syncedAt: Maybe<Scalars['ISO8601DateTime']>
  unallocatedExpensesAccountId: Maybe<Scalars['ID']>
}

export type NetsuiteUnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export type NotificationSettings = {
  accountsReceivable: AccountsReceivableNotificationSettings
  announcementsEmail: Scalars['Boolean']
  approvalRequestStatusUpdateEmail: Scalars['Boolean']
  approveInvoiceEmail: Scalars['Boolean']
  approvePaymentEmail: Scalars['Boolean']
  extendPaymentEmail: Scalars['Boolean']
  failedPaymentsDigestEmail: Scalars['Boolean']
  pendingInvoiceEmail: Scalars['Boolean']
  ruleModifiedEmail: Scalars['Boolean']
  settleInboxWeeklyDigestEmail: Scalars['Boolean']
  upcomingPaymentsDigestEmail: Scalars['Boolean']
}

export type NotificationSettingsInputObject = {
  accountsReceivable?: InputMaybe<AccountsReceivableNotificationSettingsInputObject>
  announcementsEmail?: InputMaybe<Scalars['Boolean']>
  approvalRequestStatusUpdateEmail?: InputMaybe<Scalars['Boolean']>
  approveInvoiceEmail?: InputMaybe<Scalars['Boolean']>
  approvePaymentEmail?: InputMaybe<Scalars['Boolean']>
  extendPaymentEmail?: InputMaybe<Scalars['Boolean']>
  failedPaymentsDigestEmail?: InputMaybe<Scalars['Boolean']>
  pendingInvoiceEmail?: InputMaybe<Scalars['Boolean']>
  ruleModifiedEmail?: InputMaybe<Scalars['Boolean']>
  settleInboxWeeklyDigestEmail?: InputMaybe<Scalars['Boolean']>
  upcomingPaymentsDigestEmail?: InputMaybe<Scalars['Boolean']>
}

export enum ObjectSyncStatusEnum {
  EXCLUDED_BY_BOOK_CLOSE_DATE = 'EXCLUDED_BY_BOOK_CLOSE_DATE',
  EXCLUDED_BY_SYNC_START_DATE = 'EXCLUDED_BY_SYNC_START_DATE',
  EXCLUDED_BY_USER = 'EXCLUDED_BY_USER',
  SYNCED = 'SYNCED',
  SYNCED_WITH_ERROR = 'SYNCED_WITH_ERROR',
  UNSYNCED = 'UNSYNCED',
}

export enum OfferStatusEnum {
  ACTIVATED = 'ACTIVATED',
  AVAILABLE = 'AVAILABLE',
}

export enum OnboardingStatusEnum {
  MICRO_DEPOSITS_SENT = 'MICRO_DEPOSITS_SENT',
  MICRO_DEPOSITS_WAITING = 'MICRO_DEPOSITS_WAITING',
  UNVERIFIED = 'UNVERIFIED',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>
}

export enum PagePermissionsEnum {
  ACCOUNTING_FIRM_DASHBOARD_PAGE = 'ACCOUNTING_FIRM_DASHBOARD_PAGE',
  CATALOG_PAGE = 'CATALOG_PAGE',
  DASHBOARD_PAGE = 'DASHBOARD_PAGE',
  INBOX_PAGE = 'INBOX_PAGE',
  INVOICES_PAGE = 'INVOICES_PAGE',
  MARKETING_CARD_PAGE = 'MARKETING_CARD_PAGE',
  MY_INVOICES_PAGE = 'MY_INVOICES_PAGE',
  PAYABLES_PAGE = 'PAYABLES_PAGE',
  PAYMENTS_PAGE = 'PAYMENTS_PAGE',
  PURCHASERS_PAGE = 'PURCHASERS_PAGE',
  PURCHASE_ORDERS_PAGE = 'PURCHASE_ORDERS_PAGE',
  RECEIPTS_PAGE = 'RECEIPTS_PAGE',
  RECEIVABLES_PAGE = 'RECEIVABLES_PAGE',
  VENDORS_PAGE = 'VENDORS_PAGE',
}

export type Pagination = {
  page: Scalars['Int']
  pageSize: Scalars['Int']
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
}

export type PaperCheckDetails = {
  address: Maybe<Address>
  email: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
}

export type PaperCheckDetailsInputObject = {
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export enum PayableActionIndicatorEnum {
  INCOMPLETE = 'INCOMPLETE',
  READY = 'READY',
}

export type PayableInvoiceInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  lineItems?: InputMaybe<Array<BaseExternalPayableLineItemInputObject>>
  number?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type PayableInvoicePreview = {
  amount: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  vendor: Maybe<LocalBusiness>
}

export type PayableLineItemInputObject = {
  externalLineItemDetails?: InputMaybe<BaseExternalPayableLineItemInputObject>
  lineItemDetails: InvoiceLineItemDetailsInputObject
}

export type PayerInvitation = {
  businessName: Maybe<Scalars['String']>
  demoAvailable: Scalars['Boolean']
  valid: Scalars['Boolean']
}

export type PayerInvitationV2 = {
  approximateRevenue: Maybe<Scalars['String']>
  bankAccountLocation: Maybe<Scalars['String']>
  businessName: Maybe<Scalars['String']>
  countryOfRegistration: Maybe<Scalars['String']>
  demoAvailable: Maybe<Scalars['Boolean']>
  email: Scalars['String']
  firstName: Maybe<Scalars['String']>
  id: Scalars['String']
  interestedIn: Array<SignupInterestsEnum>
  invitationCode: Scalars['String']
  lastName: Maybe<Scalars['String']>
  prohibitedIndustryConfirmation: Maybe<Scalars['Boolean']>
  promotionCode: Maybe<BillingPromotionCodeType>
  referralCode: Maybe<Scalars['String']>
  source: Maybe<Scalars['String']>
  website: Maybe<Scalars['String']>
}

export type PayerOnboarding = {
  all: Array<PayerOnboardingStepEnum>
  completed: Scalars['Boolean']
  current: Maybe<PayerOnboardingStepEnum>
  skipped: Array<PayerOnboardingStepEnum>
}

export enum PayerOnboardingStepEnum {
  ADD_BENEFICIAL_OWNERS = 'ADD_BENEFICIAL_OWNERS',
  COMPLETE_BUSINESS_DETAILS = 'COMPLETE_BUSINESS_DETAILS',
  CONNECT_ACCOUNTING = 'CONNECT_ACCOUNTING',
  CONNECT_BANK_ACCOUNT = 'CONNECT_BANK_ACCOUNT',
  CREATE_OR_UPLOAD_PURCHASE_ORDER = 'CREATE_OR_UPLOAD_PURCHASE_ORDER',
  IMPORT_BILLS = 'IMPORT_BILLS',
  UPLOAD_BILL = 'UPLOAD_BILL',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export type Payment = {
  account: Maybe<Account>
  actions: PaymentActions
  agreementSignRequired: Scalars['Boolean']
  allowAccountChange: Scalars['Boolean']
  allowPayNow: Scalars['Boolean']
  allowReschedule: Scalars['Boolean']
  allowRestorePaymentDate: Scalars['Boolean']
  amortizedLoan: Maybe<AmortizedLoan>
  amount: Money
  anyEarlyRepayments: Scalars['Boolean']
  approvalRequestedAt: Maybe<Scalars['ISO8601DateTime']>
  approvalSteps: Array<PaymentApprovalStep>
  awaitingApproval: Scalars['Boolean']
  bulletEpt: Maybe<BulletEpt>
  canChangeExtendedPaymentTerms: Maybe<Scalars['Boolean']>
  cancelledAt: Maybe<Scalars['ISO8601DateTime']>
  cancelledBy: Maybe<Approver>
  createdAt: Scalars['ISO8601DateTime']
  createdBy: Maybe<Approver>
  debitDate: Maybe<Scalars['ISO8601Date']>
  errorCode: Maybe<Scalars['String']>
  errorDirection: Maybe<Scalars['String']>
  errorMessage: Maybe<Scalars['String']>
  etaFromPayer: Maybe<Scalars['ISO8601Date']>
  etaToVendor: Maybe<Array<Scalars['ISO8601Date']>>
  extendedTerm: Scalars['Boolean']
  extendedTermPayerPaymentDate: Maybe<Scalars['ISO8601Date']>
  extendedTermUnrefundedFee: Maybe<Money>
  failedAt: Maybe<Scalars['ISO8601Date']>
  feeInvoices: Maybe<Array<Invoice>>
  feePercent: Scalars['Float']
  financed: Scalars['Boolean']
  id: Scalars['ID']
  /** @deprecated Use transfer.payment instead */
  invoice: Maybe<Invoice>
  originalInvoice: Maybe<Invoice>
  paidWithMethod: Maybe<PaymentMethodEnum>
  payerAmount: Money
  payerCharged: Scalars['Boolean']
  payerPaymentDate: Scalars['ISO8601Date']
  payerPaymentStatus: PaymentStatusEnum
  payerTransferDetails: Maybe<TransferDetails>
  payerTransfersDetails: Maybe<Array<TransferDetails>>
  paymentConfirmationDocumentUrl: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethodEnum>
  paymentNumber: Maybe<Scalars['Int']>
  paymentRestoreDate: Maybe<Scalars['ISO8601Date']>
  paymentRestoreDay: Maybe<Scalars['Int']>
  paymentTo: LocalBusiness
  paymentType: Maybe<PaymentTypeEnum>
  purposeCode: Maybe<Scalars['String']>
  reconciliationItems: Array<ReconciliationItem>
  refundItems: Array<ReconciliationItem>
  repaidCompletely: Scalars['Boolean']
  repaymentInitiated: Scalars['Boolean']
  revokable: Scalars['Boolean']
  scheduleWarningMessage: Maybe<Scalars['String']>
  scheduledOn: Scalars['ISO8601Date']
  secondaryStatusDescription: Maybe<Scalars['String']>
  sentDate: Maybe<Scalars['ISO8601Date']>
  status: PaymentStatusEnum
  statusDescription: Maybe<Scalars['String']>
  statusDescriptions: Maybe<Array<Scalars['String']>>
  transfer: Maybe<Transfer>
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorAmount: Money
  vendorLocalBusiness: LocalBusiness
  vendorPaid: Scalars['Boolean']
  vendorPaymentDate: Scalars['ISO8601Date']
  vendorPaymentStatus: PaymentStatusEnum
  vendorReceivedOn: Maybe<Scalars['ISO8601Date']>
  vendorTransferDetails: Maybe<TransferDetails>
}

export type PaymentActions = {
  amortizedRepayEarly: Action
  eptRepayEarly: Action
}

export type PaymentApprovalRequest = {
  approvedAt: Maybe<Scalars['ISO8601DateTime']>
  approver: Approver
  rejectedAt: Maybe<Scalars['ISO8601DateTime']>
  status: ApprovalStatusEnum
}

export type PaymentApprovalStep = {
  approvalType: RuleActionConditionEnum
  requests: Array<PaymentApprovalRequest>
  status: ApprovalStatusEnum
  stepNumber: Scalars['Int']
}

export type PaymentCard = {
  default: Scalars['Boolean']
  expirationMonth: Scalars['Int']
  expirationYear: Scalars['Int']
  id: Scalars['ID']
  maskedNumber: Scalars['String']
  paymentSystem: Maybe<Scalars['String']>
}

export type PaymentEstimatedDelay = {
  processingDelayDays: Array<Scalars['Int']>
  scheduleNowProcessingDelayDays: Array<Scalars['Int']>
}

export type PaymentInputObject = {
  accountId: Scalars['ID']
  amount: MoneyInputObject
  paymentMethod: PaymentMethodEnum
  processDate: Scalars['ISO8601Date']
}

export enum PaymentMethodEnum {
  ACH = 'ACH',
  PAPER_CHECK = 'PAPER_CHECK',
  PAYMENT_CARD = 'PAYMENT_CARD',
  SLOW_ACH = 'SLOW_ACH',
  WIRE = 'WIRE',
}

export enum PaymentStatusEnum {
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  FULLY_REVERSED = 'FULLY_REVERSED',
  PAID = 'PAID',
  PARTIALLY_REPAID = 'PARTIALLY_REPAID',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  PROCESSED = 'PROCESSED',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}

export enum PaymentTypeEnum {
  AMORTIZED_LOAN = 'AMORTIZED_LOAN',
  BULLET_EPT = 'BULLET_EPT',
  DIRECT = 'DIRECT',
  EPT = 'EPT',
  EPT_FEE = 'EPT_FEE',
}

export enum PaymentsPermissionsEnum {
  CREATE_AMORTIZED_LOAN_PAYMENT = 'CREATE_AMORTIZED_LOAN_PAYMENT',
  CREATE_EXTENDED_TERM_PAYMENT = 'CREATE_EXTENDED_TERM_PAYMENT',
  CREATE_PAYMENT = 'CREATE_PAYMENT',
  CREATE_PRELIMINARY_AMORTIZED_LOAN_AGREEMENT = 'CREATE_PRELIMINARY_AMORTIZED_LOAN_AGREEMENT',
  CREATE_PRELIMINARY_EPT_AGREEMENT = 'CREATE_PRELIMINARY_EPT_AGREEMENT',
  DECLINE_TERM_EXTENSIONS = 'DECLINE_TERM_EXTENSIONS',
  EXTEND_PAYMENT_TERM = 'EXTEND_PAYMENT_TERM',
  PAY_NOW_PAYMENT = 'PAY_NOW_PAYMENT',
  REPAY_EARLY = 'REPAY_EARLY',
  RESCHEDULE_PAYMENT = 'RESCHEDULE_PAYMENT',
  REVOKE_PAYMENT = 'REVOKE_PAYMENT',
  UPDATE_PAYMENT_ACCOUNT = 'UPDATE_PAYMENT_ACCOUNT',
}

export type PaymentsSummary = {
  failedCount: Scalars['Int']
  rejectedCount: Scalars['Int']
}

export type PermissionSet = {
  availableLevels: Maybe<Array<AccessLevelEnum>>
  availableOptions: Maybe<Array<PermissionSetOptionEnum>>
  grantedLevel: Maybe<AccessLevelEnum>
  grantedOptions: Maybe<Array<PermissionSetOptionEnum>>
  name: PermissionSetEnum
}

export enum PermissionSetEnum {
  ACCOUNTING_SYNC = 'ACCOUNTING_SYNC',
  APPROVAL_RULES = 'APPROVAL_RULES',
  BILLS = 'BILLS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
  CONNECTIONS = 'CONNECTIONS',
  DASHBOARD = 'DASHBOARD',
  INBOX = 'INBOX',
  MARKETING_VIRTUAL_CARD = 'MARKETING_VIRTUAL_CARD',
  PAYMENTS = 'PAYMENTS',
  PURCHASE_ORDERS_AND_RECEIPTS = 'PURCHASE_ORDERS_AND_RECEIPTS',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
}

export type PermissionSetInputObject = {
  grantedLevel?: InputMaybe<AccessLevelEnum>
  grantedOptions?: InputMaybe<Array<PermissionSetOptionEnum>>
  name: PermissionSetEnum
}

export enum PermissionSetOptionEnum {
  APPLY_VENDOR_CREDITS = 'APPLY_VENDOR_CREDITS',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
  CUSTOM_ROLES = 'CUSTOM_ROLES',
  EXPORT_REPORTS = 'EXPORT_REPORTS',
  LABEL_INVOICES_AS_PAID_OUTSIDE_SETTLE = 'LABEL_INVOICES_AS_PAID_OUTSIDE_SETTLE',
  LINK_RECEIPTS_TO_PURCHASE_ORDERS_AND_INVOICES = 'LINK_RECEIPTS_TO_PURCHASE_ORDERS_AND_INVOICES',
  MANAGE_BANK_ACCOUNTS = 'MANAGE_BANK_ACCOUNTS',
  MARKETING_VIRTUAL_CARD = 'MARKETING_VIRTUAL_CARD',
}

export type Permissions = {
  accountingPermissions: Array<AccountingPermissionsEnum>
  bankAccountsPermissions: Array<BankAccountsPermissionsEnum>
  billingPlanPermissions: Array<BillingPlanPermissionsEnum>
  businessDetailsPermissions: Array<BusinessDetailsPermissionsEnum>
  businessFeaturesPermissions: Array<BusinessFeaturesPermissionsEnum>
  clientManagerPermissions: Array<ClientManagerPermissionsEnum>
  dashboardPermissions: Array<DashboardPermissionsEnum>
  invoicesPermissions: Array<InvoicesPermissionsEnum>
  localBusinessesPermissions: Array<LocalBusinessesPermissionsEnum>
  mvcPermissions: Array<MvcPermissionsEnum>
  pagePermissions: Array<PagePermissionsEnum>
  paymentsPermissions: Array<PaymentsPermissionsEnum>
  purchaseOrderPermissions: Array<PurchaseOrderPermissionsEnum>
  receiptPermissions: Array<ReceiptPermissionsEnum>
  receivablesPermissions: Array<ReceivablesPermissionsEnum>
  reconciliationItemsPermissions: Array<ReconciliationItemsPermissionsEnum>
  rulesPermissions: Array<RulesPermissionsEnum>
  settleInboxConfigPermissions: Array<SettleInboxConfigPermissionsEnum>
  settleInboxPermissions: Array<SettleInboxPermissionsEnum>
  teamPermissions: Array<TeamPermissionsEnum>
  vendorCreditsPermissions: Array<VendorCreditsPermissionsEnum>
}

export type PlaidLink = BankLink & {
  deleted: Scalars['Boolean']
  fixAvailable: Maybe<BankLinkFixEnum>
  /** @deprecated Use banners instead */
  fixFlowMessage: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type ProcessingEstimate = {
  nearestProcessingDate: Scalars['ISO8601Date']
  payerDebitEta: Array<Scalars['ISO8601Date']>
  processingDate: Scalars['ISO8601Date']
  vendorCreditEta: Array<Scalars['ISO8601Date']>
}

export type ProcessingEstimates = {
  direct: ProcessingEstimate
  financed: ProcessingEstimate
}

export type Profile = {
  accountingFirms: Maybe<Array<Business>>
  actions: UserActions
  applicationTheme: ApplicationThemeEnum
  authProvider: Maybe<AuthProviderTypeEnum>
  clients: Maybe<Array<UserClient>>
  company: Scalars['String']
  country: Maybe<Scalars['String']>
  email: Scalars['String']
  emailConfirmationPending: Scalars['Boolean']
  firstName: Scalars['String']
  id: Scalars['String']
  internalEmail: Maybe<Scalars['String']>
  lastName: Scalars['String']
  loginEmail: Maybe<Scalars['String']>
  memberships: Array<Membership>
  multiBusiness: Scalars['Boolean']
  name: Scalars['String']
  operational: Scalars['Boolean']
  phoneNumber: Maybe<Scalars['String']>
  totp: Maybe<Totp>
}

export enum ProviderEnum {
  NETSUITE = 'NETSUITE',
  QUICK_BOOKS = 'QUICK_BOOKS',
  XERO = 'XERO',
}

export type PublicBusiness = {
  /** @deprecated Use preferredReceivingAccount instead */
  account: Maybe<PublicLocalBusinessAccount>
  address: Maybe<Address>
  businessStructure: Maybe<BusinessStructureEnum>
  companyName: Maybe<Scalars['String']>
  displayName: Scalars['String']
  emailDomains: Array<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['String']
  is1099Eligible: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  officialName: Scalars['String']
  preferredReceivingAccount: Maybe<PublicLocalBusinessAccount>
  /** Active verified accounts connected by the business */
  receivingAccounts: Array<PublicLocalBusinessAccount>
  taxpayerId: Maybe<Scalars['String']>
  verified: Scalars['Boolean']
  /** W9 form uploaded by the business */
  w9Form: Maybe<Attachment>
  website: Maybe<Scalars['String']>
}

export type PublicInvoice = {
  account: Maybe<Account>
  actions: PublicInvoiceActions
  amount: Maybe<Money>
  attachments: Array<Attachment>
  dueDate: Maybe<Scalars['ISO8601Date']>
  issueDate: Maybe<Scalars['ISO8601Date']>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  paidOn: Maybe<Scalars['ISO8601Date']>
  payableUrl: Maybe<Scalars['String']>
  payerBusiness: Maybe<PublicBusiness>
  payerLocalBusiness: Maybe<LocalBusiness>
  payerNotificationsEmail: Maybe<Scalars['String']>
  scheduledOn: Maybe<Scalars['ISO8601Date']>
  sentAt: Maybe<Scalars['ISO8601DateTime']>
  status: InvoiceStatusEnum
  uuid: Maybe<Scalars['String']>
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorBusiness: Maybe<PublicBusiness>
}

export type PublicInvoiceActions = {
  createPayment: Action
  createPublicLinkToken: Action
  link: Action
  removePayerAccount: Action
  revokePayment: Action
  updateBusinessDetails: Action
  verifyAccount: Action
}

export type PublicLocalBusinessAccount = {
  accountNumberType: Maybe<VendorAccountNumberTypeEnum>
  bankAddress: Maybe<Address>
  bankName: Maybe<Scalars['String']>
  bicSwift: Maybe<Scalars['String']>
  destinationCountry: Scalars['String']
  id: Scalars['ID']
  lastUsedOn: Maybe<Scalars['ISO8601Date']>
  maskedAccountNumber: Scalars['String']
  maskedRoutingNumber: Maybe<Scalars['String']>
  maskedWireRoutingNumber: Maybe<Scalars['String']>
  preferredReceivingAccount: Scalars['Boolean']
}

export type PublicPurchaseOrderInputObject = {
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  lineItems?: InputMaybe<Array<PublicPurchaseOrderLineItemInputObject>>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  paymentTerms?: InputMaybe<Scalars['String']>
  purchaserName?: InputMaybe<Scalars['String']>
  receiveByDate?: InputMaybe<Scalars['ISO8601Date']>
  shippingMethod?: InputMaybe<Scalars['String']>
  shippingTerms?: InputMaybe<Scalars['String']>
  taxPercentage?: InputMaybe<Scalars['Float']>
  vendorName?: InputMaybe<Scalars['String']>
  warehouse?: InputMaybe<WarehouseInputObject>
}

export type PublicPurchaseOrderLineItemInputObject = {
  amountCents?: InputMaybe<Scalars['Float']>
  amountCurrency?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Float']>
  sku?: InputMaybe<Scalars['String']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export type PurchaseDetailsType = {
  amount: Maybe<Money>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
}

export type PurchaseOrder = {
  actions: PurchaseOrderActions
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  attachment: Maybe<Attachment>
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  invoices: Array<Invoice>
  issueDate: Maybe<Scalars['ISO8601Date']>
  labels: Array<Label>
  lineItems: Array<PurchaseOrderLineItem>
  lineItemsSubtotal: Maybe<Money>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  receipts: Array<Receipt>
  receiveByDate: Maybe<Scalars['ISO8601Date']>
  shippingMethod: Maybe<Scalars['String']>
  shippingTerms: Maybe<Scalars['String']>
  source: PurchaseOrderSourceEnum
  status: PurchaseOrderStatusEnum
  taxPercentage: Maybe<Scalars['Float']>
  timelineEvents: Maybe<Array<PurchaseOrderTimelineEvent>>
  vendorLocalBusiness: Maybe<LocalBusiness>
  warehouse: Maybe<Warehouse>
}

export type PurchaseOrderActions = {
  attachDocumentPurchaseOrder: Action
  createPurchaseOrder: Action
  updatePurchaseOrder: Action
  updateVendor: Action
}

export enum PurchaseOrderArchivedStatusEnum {
  ACTIVE_AND_ARCHIVED = 'ACTIVE_AND_ARCHIVED',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
}

export enum PurchaseOrderEventTypeEnum {
  PO_ADDED = 'PO_ADDED',
  PO_CANCELED = 'PO_CANCELED',
  PO_CLOSED = 'PO_CLOSED',
  PO_CREATED = 'PO_CREATED',
  PO_OPENED = 'PO_OPENED',
  PO_SENT_TO_VENDOR = 'PO_SENT_TO_VENDOR',
}

export type PurchaseOrderInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  receiveByDate?: InputMaybe<Scalars['ISO8601Date']>
  shippingMethod?: InputMaybe<Scalars['String']>
  shippingTerms?: InputMaybe<Scalars['String']>
  status?: InputMaybe<PurchaseOrderStatusEnum>
  taxPercentage?: InputMaybe<Scalars['Float']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  warehouse?: InputMaybe<WarehouseInputObject>
}

export type PurchaseOrderLineItem = {
  amountCents: Maybe<Scalars['Float']>
  amountCurrency: Maybe<Scalars['String']>
  catalogItem: Maybe<CatalogItem>
  catalogItemVariation: Maybe<CatalogItemVariation>
  children: Maybe<Array<PurchaseOrderLineItem>>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
  unitOfMeasure: Maybe<Scalars['String']>
}

export type PurchaseOrderLineItemInputObject = {
  amountCents?: InputMaybe<Scalars['Float']>
  amountCurrency?: InputMaybe<Scalars['String']>
  catalogItemId: Scalars['ID']
  catalogItemVariationId?: InputMaybe<Scalars['ID']>
  children?: InputMaybe<Array<PurchaseOrderLineItemInputObject>>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Float']>
  unitOfMeasure?: InputMaybe<Scalars['String']>
}

export enum PurchaseOrderPermissionsEnum {
  ATTACH_PURCHASE_ORDER_DOCUMENT = 'ATTACH_PURCHASE_ORDER_DOCUMENT',
  CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER',
  DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER',
  LINK_DOCUMENTS_TO_PURCHASE_ORDER = 'LINK_DOCUMENTS_TO_PURCHASE_ORDER',
  READ_PURCHASE_ORDER = 'READ_PURCHASE_ORDER',
  UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER',
  UPDATE_PURCHASE_ORDER_ARCHIVED = 'UPDATE_PURCHASE_ORDER_ARCHIVED',
  UPDATE_PURCHASE_ORDER_LABELS = 'UPDATE_PURCHASE_ORDER_LABELS',
}

export enum PurchaseOrderSortableFieldEnum {
  CREATED_AT = 'CREATED_AT',
  ISSUE_DATE = 'ISSUE_DATE',
  NUMBER = 'NUMBER',
  RECEIVE_BY_DATE = 'RECEIVE_BY_DATE',
}

export enum PurchaseOrderSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PurchaseOrderSortingInputObject = {
  direction: PurchaseOrderSortingDirectionEnum
  field: PurchaseOrderSortableFieldEnum
}

export enum PurchaseOrderSourceEnum {
  GENERATED = 'GENERATED',
  UPLOADED = 'UPLOADED',
}

export enum PurchaseOrderStatusEnum {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export type PurchaseOrderTimelineEvent = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: PurchaseOrderEventTypeEnum
  icon: IconEnum
  user: Maybe<Approver>
}

export type PurchaseOrders = {
  data: Array<PurchaseOrder>
  pagination: Pagination
}

export type PurchaseOrdersFilteringInputObject = {
  archivedStatus?: InputMaybe<PurchaseOrderArchivedStatusEnum>
  createdAt?: InputMaybe<DateFilteringInputObject>
  invoiceNumber?: InputMaybe<Scalars['String']>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  number?: InputMaybe<Scalars['String']>
  purchaseOrderIds?: InputMaybe<Array<Scalars['ID']>>
  statuses?: InputMaybe<Array<PurchaseOrderStatusEnum>>
  vendorLocalBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  vendorName?: InputMaybe<Scalars['String']>
}

export type PurposeCode = {
  code: Scalars['String']
  description: Scalars['String']
}

export type Query = {
  account: Account
  accountantDashboard: AccountantDashboard
  accountingCounters: AccountingCounters
  accountingObjectsWithError: SyncObjects
  accountingState: Maybe<AccountingState>
  accountingSyncErrorSummaries: SyncErrorSummaries
  accountingSyncEvents: Maybe<SyncEvents>
  accounts: Array<Account>
  accountsForBilling: Array<Account>
  addressValidation: AddressValidation
  amortizedEarlyRepaymentInterestSavings: Money
  banners: Banners
  beneficialOwnerDataFields: BeneficialOwnersDataFields
  beneficialOwners: Array<BeneficialOwner>
  billingHistory: Array<BillingHistoryItem>
  billingMethods: Array<BillingMethod>
  billingPlanFeatures: Array<BillingPlanFeatures>
  billingPlans: Array<BillingPlan>
  businessFeatures: BusinessFeaturesType
  businesses: Array<Business>
  businessesForSwitcher: Array<Business>
  cashOutflows: Array<CashOutflow>
  catalogItemPropertyTemplates: Array<CatalogItemProperty>
  catalogItems: CatalogItems
  catalogItemsWaitingForSync: Maybe<CatalogItems>
  checkBillingPlanUpgrade: BillingPlanUpgrade
  checkEmail: EmailTypeEnum
  checkInvitation: Maybe<Invitation>
  checkPayerInvitation: PayerInvitation
  clientApplication: ClientApplication
  clientManagerDashboard: ClientManagerDashboard
  counters: Counters
  countries: Array<Country>
  currencies: Array<Currency>
  dashboard: Dashboard
  deactivatedBusinesses: Array<PublicBusiness>
  disclosures: Disclosures
  discounts: Array<BillingDiscountType>
  earlyRepaymentBalance: RepaymentBalance
  eptGrandTotal: Maybe<Money>
  eptMetrics: EptMetrics
  /** @deprecated Use financingOptions instead. */
  eptOffers: Maybe<InvoiceExtendedPaymentTermOffers>
  exchangeFeeData: ExchangeFeeData
  excludedFromSync: Array<SyncObject>
  extendedDowngradeDetails: Maybe<Array<BillingExtendedDowngradeDetailsType>>
  financingOptions: Maybe<FinancingOptions>
  finicityConnectUrl: Scalars['String']
  finicityConnections: Array<FinicityLink>
  holidays: Array<Holiday>
  internationalWireFields: Array<InternationalWireFieldsDataFieldType>
  invoice: Invoice
  invoiceOcrData: Maybe<InvoiceOcr>
  invoiceStatusCounts: InvoiceStatusCounts
  invoices: Array<Invoice>
  invoicesFilteringOptions: InvoiceFilteringOptions
  invoicesWaitingForSync: Maybe<Invoices>
  journeys: Array<Journey>
  kybDataRequest: KybDataRequest
  labels: Array<Label>
  linkedDocuments: Array<LinkedDocument>
  listReports: Maybe<Reports>
  localBusinessAccounts: Array<LocalBusinessAccount>
  localBusinesses: LocalBusinesses
  marketingVirtualCard: Maybe<MarketingVirtualCard>
  marketingVirtualCardAuthorizations: AuthorizationPaginated
  marketingVirtualCardSecure: Maybe<MarketingVirtualCardSecure>
  marketingVirtualCardTransactions: TransactionPaginated
  middeskSearch: MiddeskSearch
  /** @deprecated Use processingEstimates instead */
  nearestProcessingDate: Scalars['ISO8601Date']
  netsuiteState: Maybe<NetsuiteState>
  /** @deprecated Use generic unpaid_bills instead. */
  netsuiteUnpaidBills: Maybe<Array<NetsuiteUnpaidBill>>
  newBillingHistory: Array<BillingHistoryEntryType>
  newBillingPlans: Array<BillingPlanType>
  newEptGrandTotal: Maybe<Money>
  /** @deprecated Use processingEstimates instead */
  newPaymentEtaFromPayer: AccountEstimatedDebitDate
  newPaymentFinancingOptions: FinancingOptions
  notificationSettings: NotificationSettings
  pagedInvoices: Invoices
  pagedReconciliationItems: ReconciliationItems
  payerInvitation: PayerInvitationV2
  /** @deprecated Use processingEstimates instead */
  paymentEtaFromPayer: AccountEstimatedDebitDate
  /** @deprecated Use processingEstimates instead */
  paymentEtaToVendor: PaymentEstimatedDelay
  paymentsPageCutoffWarning: Maybe<Scalars['String']>
  permissionSets: Array<PermissionSet>
  permissions: Permissions
  plaidConnections: Array<PlaidLink>
  plaidCreateLinkToken: Scalars['String']
  plaidCreateLinkTokenPublicInvoice: Scalars['String']
  previewAsPayableInvoice: PayableInvoicePreview
  processingEstimates: ProcessingEstimates
  profile: Profile
  promotionCodes: Array<BillingPromotionCodeType>
  publicBusinesses: Array<PublicBusiness>
  publicInvoice: PublicInvoice
  purchaseOrders: PurchaseOrders
  purposeCodes: Array<PurposeCode>
  quickBooksLoginUrl: Scalars['String']
  quickBooksState: Maybe<QuickBooksState>
  /** @deprecated Use generic unpaid_bills instead. */
  quickBooksUnpaidBills: Maybe<Array<QuickBooksUnpaidBill>>
  receipts: Receipts
  receivableInvoices: ReceivableInvoices
  receivables: Receivables
  reconciliationItem: ReconciliationItem
  reconciliationItemsFilteringOptions: ReconciliationItemFilteringOptions
  referralCode: Code
  referralCodeV2: Maybe<CodeTypeV2>
  report: Maybe<Report>
  reportsFilteringOptions: ReportFilteringOptions
  ruleMetadata: RuleMetadata
  rules: Array<Rule>
  settleInboxAutopayableConfig: Maybe<SettleInboxAutopayableConfigType>
  settleInboxMessages: SettleInboxMessages
  signIns: Array<SignIn>
  subscriptionFreezingDetails: Maybe<Message>
  suggestedVendorAccounts: Maybe<Array<InvoiceSuggestedVendorAccount>>
  teamMember: TeamMember
  teamMembers: TeamMembers
  testingMicroDeposits: TestingMicroDeposits
  testingTokens: TestingTokens
  totalDebits: TotalDebits
  totp: Maybe<Totp>
  transactionalTodoListSummary: Array<TransactionalTodoListSummary>
  transfer: Transfer
  unitsOfMeasure: Array<Scalars['String']>
  unpaidBills: Maybe<Array<UnpaidBill>>
  unpaidBillsPaginated: Maybe<UnpaidBillsPaginated>
  userRole: Maybe<UserRole>
  userRoles: Array<UserRole>
  validateReferralCode: ValidateReferralCode
  vendorCredit: VendorCredit
  vendorCreditsWaitingForSync: Maybe<VendorCredits>
  vendorFinancingOffer: VendorFinancingOffer
  vendorInvitation: VendorInvitation
  xeroLoginUrl: Scalars['String']
  xeroState: Maybe<XeroState>
  /** @deprecated Use generic unpaid_bills instead. */
  xeroUnpaidBills: Maybe<Array<XeroUnpaidBill>>
}

export type QueryaccountArgs = {
  accountId: Scalars['ID']
}

export type QueryaccountingObjectsWithErrorArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  syncErrorMessage: Scalars['String']
  syncObjectType: SyncObjectTypeEnum
}

export type QueryaccountingSyncErrorSummariesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryaccountingSyncEventsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['ISO8601Date']>
  syncObjectType: SyncObjectTypeEnum
}

export type QueryaccountsArgs = {
  all?: InputMaybe<Scalars['Boolean']>
  lockbox?: InputMaybe<Scalars['Boolean']>
  paying?: InputMaybe<Scalars['Boolean']>
}

export type QueryaddressValidationArgs = {
  addressId?: InputMaybe<Scalars['ID']>
  inputAddress?: InputMaybe<AddressInputObject>
}

export type QueryamortizedEarlyRepaymentInterestSavingsArgs = {
  date: Scalars['ISO8601Date']
  paymentId: Scalars['ID']
}

export type QuerybannersArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerybeneficialOwnerDataFieldsArgs = {
  countryCode: Scalars['String']
}

export type QuerybusinessesArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerybusinessesForSwitcherArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerycatalogItemsArgs = {
  catalogItemsFilter?: InputMaybe<CatalogItemsFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerycatalogItemsWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerycheckBillingPlanUpgradeArgs = {
  billingPlanId: Scalars['ID']
}

export type QuerycheckEmailArgs = {
  email: Scalars['String']
}

export type QuerycheckInvitationArgs = {
  invitationToken: Scalars['String']
}

export type QuerycheckPayerInvitationArgs = {
  invitationCode: Scalars['String']
}

export type QueryclientApplicationArgs = {
  applicationId: Scalars['ID']
}

export type QueryclientManagerDashboardArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerydeactivatedBusinessesArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QuerydisclosuresArgs = {
  template?: InputMaybe<Scalars['Boolean']>
}

export type QueryearlyRepaymentBalanceArgs = {
  amount: MoneyInputObject
  paymentId: Scalars['ID']
  repaymentDate: Scalars['ISO8601Date']
}

export type QueryeptGrandTotalArgs = {
  offerIds: Array<Scalars['ID']>
  transferId: Scalars['ID']
}

export type QueryeptOffersArgs = {
  transferId: Scalars['ID']
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryexchangeFeeDataArgs = {
  amount: MoneyInputObject
}

export type QueryextendedDowngradeDetailsArgs = {
  planFromId: Scalars['ID']
  planToId: Scalars['ID']
}

export type QueryfinancingOptionsArgs = {
  transferId: Scalars['ID']
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryfinicityConnectUrlArgs = {
  finicityConnectionId?: InputMaybe<Scalars['ID']>
}

export type QueryholidaysArgs = {
  beforeDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QueryinternationalWireFieldsArgs = {
  country: Scalars['String']
  currency: Scalars['String']
  targetType: InternationalWireTargetTypeEnum
}

export type QueryinvoiceArgs = {
  invoiceId: Scalars['ID']
}

export type QueryinvoiceOcrDataArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  receivableId?: InputMaybe<Scalars['ID']>
}

export type QueryinvoicesArgs = {
  invoiceIds: Array<Scalars['ID']>
}

export type QueryinvoicesFilteringOptionsArgs = {
  businessId?: InputMaybe<Scalars['ID']>
}

export type QueryinvoicesWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerykybDataRequestArgs = {
  dataRequestId: Scalars['ID']
}

export type QuerylabelsArgs = {
  labelId?: InputMaybe<Scalars['ID']>
  search?: InputMaybe<Scalars['String']>
}

export type QuerylinkedDocumentsArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>
  purchaseOrderId?: InputMaybe<Scalars['ID']>
  receiptId?: InputMaybe<Scalars['ID']>
}

export type QuerylistReportsArgs = {
  category?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  statuses?: InputMaybe<Array<StatusEnum>>
}

export type QuerylocalBusinessAccountsArgs = {
  all?: InputMaybe<Scalars['Boolean']>
  destinationCountry?: InputMaybe<Scalars['String']>
  localBusinessId: Scalars['ID']
}

export type QuerylocalBusinessesArgs = {
  fulltext?: InputMaybe<Scalars['Boolean']>
  localBusinessId?: InputMaybe<Scalars['ID']>
  localBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  role?: InputMaybe<LocalBusinessRoleEnum>
  search?: InputMaybe<Scalars['String']>
  withDeleted?: InputMaybe<Scalars['Boolean']>
  withEmptyName?: InputMaybe<Scalars['Boolean']>
  withInvoices?: InputMaybe<Scalars['Boolean']>
}

export type QuerymarketingVirtualCardAuthorizationsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerymarketingVirtualCardTransactionsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerymiddeskSearchArgs = {
  businessId: Scalars['ID']
  legalName: Scalars['String']
  stateOfFormation: Scalars['String']
}

export type QuerynearestProcessingDateArgs = {
  ept: Scalars['Boolean']
  paymentMethod: PaymentMethodEnum
}

export type QuerynewEptGrandTotalArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  offerIds: Array<Scalars['ID']>
}

export type QuerynewPaymentEtaFromPayerArgs = {
  amount?: InputMaybe<MoneyInputObject>
  date: Scalars['ISO8601Date']
  invoiceId: Scalars['ID']
  offerIds?: InputMaybe<Array<Scalars['ID']>>
}

export type QuerynewPaymentFinancingOptionsArgs = {
  amount: MoneyInputObject
  invoiceId: Scalars['ID']
  paymentMethod?: InputMaybe<PaymentMethodEnum>
  vendorPaymentDate?: InputMaybe<Scalars['ISO8601Date']>
}

export type QuerypagedInvoicesArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorting?: InputMaybe<InvoiceSortingInputObject>
}

export type QuerypagedReconciliationItemsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  reconciliationItemIds?: InputMaybe<Array<Scalars['ID']>>
  reconciliationItemsFilter?: InputMaybe<ReconciliationItemsFilteringInputObject>
  sorting?: InputMaybe<ReconciliationItemSortingInputObject>
}

export type QuerypayerInvitationArgs = {
  invitationCode: Scalars['String']
}

export type QuerypaymentEtaFromPayerArgs = {
  date: Scalars['ISO8601Date']
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  transferId: Scalars['ID']
}

export type QuerypaymentEtaToVendorArgs = {
  invoiceId: Scalars['ID']
  isExtendedTerm: Scalars['Boolean']
  paymentMethod: PaymentMethodEnum
}

export type QueryplaidCreateLinkTokenArgs = {
  plaidItemId?: InputMaybe<Scalars['ID']>
}

export type QueryplaidCreateLinkTokenPublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type QuerypreviewAsPayableInvoiceArgs = {
  settleInboxAttachmentId: Scalars['ID']
}

export type QueryprocessingEstimatesArgs = {
  financingDays?: InputMaybe<Scalars['Int']>
  invoiceId: Scalars['ID']
  paymentMethod: PaymentMethodEnum
  scheduledFor?: InputMaybe<Scalars['ISO8601Date']>
}

export type QuerypromotionCodesArgs = {
  code: Scalars['String']
}

export type QuerypublicBusinessesArgs = {
  publicBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type QuerypublicInvoiceArgs = {
  uuid: Scalars['String']
}

export type QuerypurchaseOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  purchaseOrdersFilter?: InputMaybe<PurchaseOrdersFilteringInputObject>
  purchaseOrdersSorting?: InputMaybe<PurchaseOrderSortingInputObject>
}

export type QuerypurposeCodesArgs = {
  currency?: InputMaybe<Scalars['String']>
  destinationCountry?: InputMaybe<Scalars['String']>
}

export type QueryreceiptsArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  receiptsFilter?: InputMaybe<ReceiptsFilteringInputObject>
}

export type QueryreceivableInvoicesArgs = {
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>
  invoicesFilter?: InputMaybe<InvoicesFilteringInputObject>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sorting?: InputMaybe<ReceivableInvoicesSortingInputObject>
}

export type QueryreceivablesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  receivableId?: InputMaybe<Scalars['ID']>
}

export type QueryreconciliationItemArgs = {
  reconciliationItemId: Scalars['ID']
}

export type QueryreferralCodeArgs = {
  userId: Scalars['ID']
}

export type QueryreportArgs = {
  reportId: Scalars['ID']
}

export type QueryrulesArgs = {
  ruleId?: InputMaybe<Scalars['ID']>
}

export type QuerysettleInboxMessagesArgs = {
  archived: Scalars['Boolean']
  messageId?: InputMaybe<Scalars['ID']>
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QuerysuggestedVendorAccountsArgs = {
  invoiceId: Scalars['ID']
}

export type QueryteamMemberArgs = {
  teamMemberId: Scalars['ID']
}

export type QueryteamMembersArgs = {
  teamMemberStatus?: InputMaybe<TeamMemberStatusEnum>
  userRoleGroup?: InputMaybe<UserRoleGroupEnum>
}

export type QuerytestingMicroDepositsArgs = {
  email: Scalars['String']
}

export type QuerytestingTokensArgs = {
  email: Scalars['String']
}

export type QuerytotalDebitsArgs = {
  reconciliationItemIds?: InputMaybe<Array<Scalars['ID']>>
  reconciliationItemsFilter?: InputMaybe<ReconciliationItemsFilteringInputObject>
}

export type QuerytransferArgs = {
  transferId: Scalars['ID']
}

export type QueryunpaidBillsPaginatedArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryuserRoleArgs = {
  roleId: Scalars['ID']
}

export type QueryuserRolesArgs = {
  businessId?: InputMaybe<Scalars['ID']>
  userRoleGroup?: InputMaybe<UserRoleGroupEnum>
}

export type QueryvalidateReferralCodeArgs = {
  referralCode: Scalars['String']
}

export type QueryvendorCreditArgs = {
  vendorCreditId: Scalars['ID']
}

export type QueryvendorCreditsWaitingForSyncArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}

export type QueryvendorFinancingOfferArgs = {
  invoiceId: Scalars['ID']
}

export type QueryvendorInvitationArgs = {
  invitationToken: Scalars['String']
}

export type QuickBooksAccount = {
  accountSubType: Scalars['String']
  accountType: Scalars['String']
  active: Scalars['Boolean']
  children: Array<QuickBooksAccount>
  classification: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  number: Maybe<Scalars['String']>
  subAccount: Scalars['Boolean']
}

export type QuickBooksAccountMapping = {
  accountId: Scalars['ID']
  accountingAccountId: Maybe<Scalars['ID']>
  externalAccountId: Maybe<Scalars['ID']>
  quickBooksAccountId: Maybe<Scalars['ID']>
}

export type QuickBooksAccountMappingInputObject = {
  accountId: Scalars['ID']
  externalAccountId?: InputMaybe<Scalars['ID']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
}

export type QuickBooksDictionary = {
  children: Array<QuickBooksDictionary>
  id: Scalars['ID']
  name: Scalars['String']
}

export type QuickBooksState = {
  accountMappings: Array<QuickBooksAccountMapping>
  accounts: Array<QuickBooksAccount>
  autoImportBills: Scalars['Boolean']
  bankAccounts: Array<QuickBooksAccount>
  bookCloseDate: Maybe<Scalars['ISO8601Date']>
  classes: Array<QuickBooksDictionary>
  classesAvailable: Scalars['Boolean']
  connected: Scalars['Boolean']
  creditCardAccounts: Array<QuickBooksAccount>
  eptSyncEnabled: Scalars['Boolean']
  eptSyncStartDate: Maybe<Scalars['ISO8601Date']>
  expenseAccounts: Array<QuickBooksAccount>
  feeExpensesAccountId: Maybe<Scalars['ID']>
  filteredAccounts: Array<QuickBooksAccount>
  liabilityAccounts: Array<QuickBooksAccount>
  locations: Array<QuickBooksDictionary>
  locationsAvailable: Scalars['Boolean']
  multiCurrency: Scalars['Boolean']
  readOnly: Scalars['Boolean']
  receivableBankAccountId: Maybe<Scalars['ID']>
  settleLiabilityAccountId: Maybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartDate: Maybe<Scalars['ISO8601Date']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: SyncStateEnum
  syncUncategorizedExpenses: Scalars['Boolean']
  syncedAt: Maybe<Scalars['ISO8601DateTime']>
  trackClasses: Scalars['Boolean']
  trackLocations: Scalars['Boolean']
  unallocatedExpensesAccountId: Maybe<Scalars['ID']>
}

export type QuickBooksUnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export enum ReasonEnum {
  BLOCKED = 'BLOCKED',
  IN_REVIEW = 'IN_REVIEW',
  NEED_BENEFICIAL_OWNER = 'NEED_BENEFICIAL_OWNER',
  NEED_BUSINESS_INFO = 'NEED_BUSINESS_INFO',
  NEED_SIGNIFICANT_CONTROL = 'NEED_SIGNIFICANT_CONTROL',
}

export type Receipt = {
  actions: ReceiptActions
  amount: Maybe<Money>
  attachment: Maybe<Attachment>
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  invoices: Array<Invoice>
  issueDate: Maybe<Scalars['ISO8601Date']>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  purchaseOrders: Array<PurchaseOrder>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type ReceiptActions = {
  createReceipt: Action
  updateReceipt: Action
}

export type ReceiptInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export enum ReceiptPermissionsEnum {
  ATTACH_RECEIPT_DOCUMENT = 'ATTACH_RECEIPT_DOCUMENT',
  CREATE_RECEIPT = 'CREATE_RECEIPT',
  DELETE_RECEIPT = 'DELETE_RECEIPT',
  LINK_DOCUMENTS_TO_RECEIPT = 'LINK_DOCUMENTS_TO_RECEIPT',
  READ_RECEIPT = 'READ_RECEIPT',
  UPDATE_RECEIPT = 'UPDATE_RECEIPT',
  UPDATE_RECEIPT_ARCHIVED = 'UPDATE_RECEIPT_ARCHIVED',
}

export type Receipts = {
  data: Array<Receipt>
  pagination: Pagination
}

export type ReceiptsFilteringInputObject = {
  number?: InputMaybe<Scalars['String']>
  receiptIds?: InputMaybe<Array<Scalars['ID']>>
  vendorLocalBusinessIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type Receivable = {
  account: Maybe<Account>
  accountNumber: Maybe<Scalars['String']>
  agreement: Maybe<Scalars['String']>
  amount: Maybe<Money>
  attachments: Array<Attachment>
  balancePaymentAmount: Maybe<Money>
  balancePaymentDate: Maybe<Scalars['ISO8601Date']>
  balancePaymentStatus: Maybe<BalancePaymentStatusEnum>
  ccAddress: Maybe<Scalars['String']>
  collectionDate: Maybe<Scalars['ISO8601Date']>
  collectionMethod: Maybe<ReceivableCollectionMethodEnum>
  createdAt: Scalars['ISO8601DateTime']
  /** @deprecated use 'payer.first_name'/'payer.last_name' instead */
  customerContactName: Maybe<Scalars['String']>
  /** @deprecated use 'payer.notifications_email' instead */
  customerEmail: Maybe<Scalars['String']>
  /** @deprecated use 'payer.name' instead */
  customerName: Maybe<Scalars['String']>
  debitApproved: Maybe<Scalars['Boolean']>
  deletable: Scalars['Boolean']
  depositDate: Scalars['ISO8601Date']
  dueDate: Maybe<Scalars['ISO8601Date']>
  expectedRepaymentDate: Maybe<Scalars['ISO8601Date']>
  feeAmount: Maybe<Money>
  feePercent: Maybe<Scalars['Float']>
  id: Scalars['ID']
  initialDepositAmount: Maybe<Money>
  issueDate: Maybe<Scalars['ISO8601Date']>
  labels: Array<Label>
  number: Maybe<Scalars['String']>
  payerBusiness: Maybe<LocalBusiness>
  payment: Maybe<Payment>
  paymentMethod: Maybe<PaymentMethodEnum>
  receivableAmount: Maybe<Money>
  requestedAt: Maybe<Scalars['ISO8601DateTime']>
  revokable: Scalars['Boolean']
  routingNumber: Maybe<Scalars['String']>
  settleRepaymentAmount: Maybe<Money>
  showsDetails: Scalars['Boolean']
  signedDocuments: Array<SignedDocument>
  status: ReceivableStatusEnum
}

export enum ReceivableCollectionMethodEnum {
  SETTLE_DEBITS_MY_BUSINESS = 'SETTLE_DEBITS_MY_BUSINESS',
  SETTLE_INVOICES_CUSTOMER = 'SETTLE_INVOICES_CUSTOMER',
}

export type ReceivableInputObject = {
  accountId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  collectionMethod?: InputMaybe<ReceivableCollectionMethodEnum>
  customerContactName?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  expectedRepaymentDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  number?: InputMaybe<Scalars['String']>
  payerBusinessId?: InputMaybe<Scalars['ID']>
}

export type ReceivableInvoice = {
  actions: InvoiceActions
  amount: Maybe<Money>
  archived: Scalars['Boolean']
  attachments: Array<Attachment>
  balanceDue: Maybe<Money>
  createdAt: Scalars['ISO8601DateTime']
  dueDate: Maybe<Scalars['ISO8601Date']>
  dueDays: Maybe<Scalars['Int']>
  dueToVendorAmount: Maybe<Money>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  liabilityAmount: Maybe<Money>
  lineItems: Array<ReceivableInvoiceLineItem>
  memo: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
  paidOn: Maybe<Scalars['ISO8601Date']>
  paidWithMethod: Maybe<PaymentMethodEnum>
  paperCheckDetails: Maybe<PaperCheckDetails>
  payerBusiness: Maybe<PublicBusiness>
  payerLocalBusiness: Maybe<LocalBusiness>
  paymentsTotalAmount: Money
  possibleDuplicates: Array<ReceivableInvoice>
  purchaseOrderNumber: Maybe<Scalars['String']>
  purposeCode: Maybe<Scalars['String']>
  sentAt: Maybe<Scalars['ISO8601DateTime']>
  status: InvoiceStatusEnum
  statuses: Array<InvoiceStatusEnum>
  statusesForBadge: Array<InvoiceStatusEnum>
  timelineEvents: Maybe<Array<TimelineEvent>>
  totalUsdAmount: Maybe<Money>
  transfers: Array<ReceivableTransfer>
  unscheduledAmount: Maybe<Money>
  uploadedByCurrentBusiness: Scalars['Boolean']
  url: Maybe<Scalars['String']>
  vendorAccount: Maybe<LocalBusinessAccount>
  vendorAmount: Maybe<Money>
  vendorBusiness: Maybe<PublicBusiness>
  vendorCreditApplications: Array<VendorCreditApplication>
  vendorCreditAppliedTotal: Money
  vendorCurrency: Maybe<Currency>
  vendorFinancingConfirmation: Maybe<VendorFinancingConfirmation>
  vendorLocalBusiness: Maybe<LocalBusiness>
  vendorReceivedAmount: Money
}

export type ReceivableInvoiceInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  description?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['ISO8601Date']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  memo?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  payerLocalBusinessId?: InputMaybe<Scalars['ID']>
}

export type ReceivableInvoiceLineItem = {
  amount: Money
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
  tax: Scalars['Boolean']
  unitAmount: Maybe<Money>
}

export type ReceivableInvoices = {
  data: Array<ReceivableInvoice>
  pagination: Pagination
}

export enum ReceivableInvoicesSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ReceivableInvoicesSortingInputObject = {
  direction: ReceivableInvoicesSortingDirectionEnum
  field: ReceivableSortableFieldEnum
}

export type ReceivableLineItemInputObject = {
  lineItemDetails: InvoiceLineItemDetailsInputObject
}

export type ReceivablePayment = {
  errorMessage: Maybe<Scalars['String']>
  eta: Maybe<Array<Scalars['ISO8601Date']>>
  failedAt: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  paidWithMethod: Maybe<PaymentMethodEnum>
  /** @deprecated Use processedOn instead */
  processedAt: Maybe<Scalars['ISO8601DateTime']>
  processedOn: Maybe<Scalars['ISO8601Date']>
  purposeCode: Maybe<Scalars['String']>
  receivedOn: Maybe<Scalars['ISO8601Date']>
  scheduledOn: Scalars['ISO8601Date']
  secondaryStatusDescription: Maybe<Scalars['String']>
  statusDescription: Maybe<Scalars['String']>
  vendorAmount: Money
  vendorPaymentDate: Scalars['ISO8601Date']
}

export enum ReceivableSortableFieldEnum {
  AMOUNT = 'AMOUNT',
  AMOUNT_CURRENCY = 'AMOUNT_CURRENCY',
  CREATED_AT = 'CREATED_AT',
  DUE_DATE = 'DUE_DATE',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  ISSUE_DATE = 'ISSUE_DATE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
}

export enum ReceivableStatusEnum {
  APPROVED = 'APPROVED',
  DEBIT_APPROVAL_REQUESTED = 'DEBIT_APPROVAL_REQUESTED',
  DEBIT_PROCESSED = 'DEBIT_PROCESSED',
  DEBIT_SCHEDULED = 'DEBIT_SCHEDULED',
  DENIED = 'DENIED',
  DEPOSIT_PAID = 'DEPOSIT_PAID',
  DEPOSIT_PROCESSED = 'DEPOSIT_PROCESSED',
  DEPOSIT_SCHEDULED = 'DEPOSIT_SCHEDULED',
  DRAFT = 'DRAFT',
  OVERDUE = 'OVERDUE',
  OVERPAID = 'OVERPAID',
  REPAID = 'REPAID',
  REPAID_PARTIAL = 'REPAID_PARTIAL',
  WAITING = 'WAITING',
}

export type ReceivableTransfer = {
  amount: Maybe<Money>
  id: Scalars['ID']
  payment: Maybe<ReceivablePayment>
  status: InvoiceStatusEnum
}

export type Receivables = {
  data: Array<Receivable>
  pagination: Pagination
}

export enum ReceivablesPermissionsEnum {
  RECEIVABLES = 'RECEIVABLES',
}

export type ReconciliationItem = {
  amount: Money
  approvers: Array<Approver>
  arrivedOn: Maybe<Scalars['ISO8601Date']>
  canceledAt: Maybe<Scalars['ISO8601DateTime']>
  debitedOn: Maybe<Scalars['ISO8601Date']>
  earlyRepayment: Scalars['Boolean']
  etaRange: Maybe<Array<Scalars['ISO8601Date']>>
  id: Scalars['ID']
  interest: Maybe<Money>
  paidFromAccount: Scalars['String']
  paidFromAccountObject: Maybe<Account>
  paidToAccount: Scalars['String']
  paidToAccountObject: Maybe<LocalBusinessAccount>
  /** Only set for refund items, in which case this field returns the business' name */
  paidToBusinessName: Maybe<Scalars['String']>
  payment: Payment
  paymentMethod: PaymentMethodEnum
  principal: Maybe<Money>
  refundItem: Maybe<ReconciliationItem>
  retriedAt: Maybe<Scalars['ISO8601DateTime']>
  retriedBy: Maybe<Approver>
  scheduledFor: Scalars['ISO8601Date']
  sentAt: Maybe<Scalars['ISO8601DateTime']>
  status: ReconciliationItemStatusEnum
  type: ReconciliationItemTypeEnum
  usdAmount: Money
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type ReconciliationItemDateFilteringOption = {
  code: Maybe<Scalars['String']>
  empty: Scalars['Boolean']
  from: Maybe<Scalars['ISO8601Date']>
  name: Maybe<Scalars['String']>
  to: Maybe<Scalars['ISO8601Date']>
}

export type ReconciliationItemDateFilteringOptions = {
  field: ReconciliationItemSortableFieldEnum
  presets: Array<ReconciliationItemDateFilteringOption>
}

export type ReconciliationItemFilteringOptions = {
  dates: Array<ReconciliationItemDateFilteringOptions>
  epts: Maybe<Array<EptFilterEnum>>
}

export enum ReconciliationItemSortableFieldEnum {
  AMOUNT = 'AMOUNT',
  ARRIVED_AT = 'ARRIVED_AT',
  DEBITED_ON = 'DEBITED_ON',
  SCHEDULED_FOR = 'SCHEDULED_FOR',
  SENT_AT = 'SENT_AT',
  STATUS = 'STATUS',
  VENDOR_NAME = 'VENDOR_NAME',
}

export enum ReconciliationItemSortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ReconciliationItemSortingInputObject = {
  direction: ReconciliationItemSortingDirectionEnum
  field: ReconciliationItemSortableFieldEnum
}

export enum ReconciliationItemStatusEnum {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REFUNDED = 'REFUNDED',
  SCHEDULED = 'SCHEDULED',
}

export enum ReconciliationItemTypeEnum {
  AMORTIZED_INSTALLMENT = 'AMORTIZED_INSTALLMENT',
  AMORTIZED_VENDOR_PAYMENT = 'AMORTIZED_VENDOR_PAYMENT',
  DIRECT = 'DIRECT',
  EPT_FEE = 'EPT_FEE',
  EPT_REPAYMENT = 'EPT_REPAYMENT',
  EPT_VENDOR_PAYMENT = 'EPT_VENDOR_PAYMENT',
  REFUND = 'REFUND',
}

export type ReconciliationItems = {
  data: Array<ReconciliationItem>
  pagination: Pagination
}

export type ReconciliationItemsFilteringInputObject = {
  amountCents?: InputMaybe<InvoiceAmountFilteringInputObject>
  amountCurrencies?: InputMaybe<Array<Scalars['String']>>
  approverIds?: InputMaybe<Array<Scalars['ID']>>
  approverName?: InputMaybe<Scalars['String']>
  debitedOn?: InputMaybe<DateFilteringInputObject>
  extensionAvailableDueSoon?: InputMaybe<Scalars['Boolean']>
  extensionFees?: InputMaybe<Scalars['Boolean']>
  financingPeriod?: InputMaybe<Array<EptFilterEnum>>
  fromPayerAccount?: InputMaybe<Scalars['Boolean']>
  fromSettleAccount?: InputMaybe<Scalars['Boolean']>
  installments?: InputMaybe<Scalars['Boolean']>
  itemType?: InputMaybe<Array<ReconciliationItemTypeEnum>>
  payFromAccountIds?: InputMaybe<Array<Scalars['ID']>>
  payToSettle?: InputMaybe<Scalars['Boolean']>
  paymentMethods?: InputMaybe<Array<PaymentMethodEnum>>
  scheduledByIds?: InputMaybe<Array<Scalars['ID']>>
  scheduledByName?: InputMaybe<Scalars['String']>
  scheduledFor?: InputMaybe<DateFilteringInputObject>
  statuses?: InputMaybe<Array<ReconciliationItemStatusEnum>>
  vendorLocalBusinessIds?: InputMaybe<Array<Scalars['ID']>>
  vendorName?: InputMaybe<Scalars['String']>
}

export enum ReconciliationItemsPermissionsEnum {
  PAGED_RECONCILIATION_ITEMS = 'PAGED_RECONCILIATION_ITEMS',
  RECONCILIATION_ITEM = 'RECONCILIATION_ITEM',
}

export type RepaymentBalance = {
  currentAccruedInterest: Money
  currentInterestPaid: Money
  currentPrincipalBalance: Money
  daysOfPeriodPaid: Scalars['Int']
  outstandingBalance: Money
  outstandingInterest: Money
  outstandingPrincipal: Money
  paymentId: Scalars['ID']
  periodDays: Scalars['Int']
  postRepaymentInterestPaid: Money
  principalPaid: Money
  repaymentAmount: Money
  repaymentDate: Scalars['ISO8601Date']
}

export type Report = {
  attachedReports: Array<AttachedReport>
  category: CategoryEnum
  createdAt: Scalars['ISO8601DateTime']
  error: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: StatusEnum
}

export type ReportDateFilteringOption = {
  code: Maybe<Scalars['String']>
  from: Maybe<Scalars['ISO8601Date']>
  name: Maybe<Scalars['String']>
  to: Maybe<Scalars['ISO8601Date']>
}

export type ReportFilteringOptions = {
  reportInputDateRanges: Array<ReportDateFilteringOption>
}

export type Reports = {
  data: Array<Report>
  pagination: Pagination
}

export type RoutingNumberValidation = {
  bankName: Maybe<Scalars['String']>
  validationMessage: Maybe<Scalars['String']>
  validationStatus: RoutingNumberValidationStatusEnum
}

export enum RoutingNumberValidationStatusEnum {
  ACH_AND_WIRE = 'ACH_AND_WIRE',
  ACH_ONLY = 'ACH_ONLY',
  INVALID = 'INVALID',
  WIRE_ONLY = 'WIRE_ONLY',
}

export type Rule = {
  accountingRelated: Scalars['Boolean']
  actions: Array<RuleAction>
  conditions: Array<RuleCondition>
  description: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  status: RuleStatusEnum
  targetType: Maybe<RuleTypeEnum>
}

export type RuleAction = {
  action: RuleActionEnum
  condition: Maybe<RuleActionConditionEnum>
  values: Maybe<Array<Scalars['String']>>
}

export enum RuleActionConditionEnum {
  ALL_OF_THESE_COWORKERS = 'ALL_OF_THESE_COWORKERS',
  ANY_OF_THESE_COWORKERS = 'ANY_OF_THESE_COWORKERS',
}

export enum RuleActionEnum {
  REQUIRE_APPROVAL = 'REQUIRE_APPROVAL',
}

export type RuleActionInputObject = {
  action: RuleActionEnum
  condition?: InputMaybe<RuleActionConditionEnum>
  values?: InputMaybe<Array<Scalars['String']>>
}

export type RuleCondition = {
  attributeName: RuleConditionAttributeNameEnum
  condition: RuleConditionEnum
  value: Maybe<Scalars['String']>
  values: Maybe<Array<RuleConditionValueType>>
}

export enum RuleConditionAttributeNameEnum {
  ACCOUNT = 'ACCOUNT',
  AMOUNT = 'AMOUNT',
  CLASS = 'CLASS',
  DEPARTMENT = 'DEPARTMENT',
  LOCAL_BUSINESS = 'LOCAL_BUSINESS',
  LOCATION = 'LOCATION',
  TRACKING_CATEGORY_ONE = 'TRACKING_CATEGORY_ONE',
  TRACKING_CATEGORY_TWO = 'TRACKING_CATEGORY_TWO',
}

export enum RuleConditionEnum {
  ANY_IN = 'ANY_IN',
  EQUALS = 'EQUALS',
  IN = 'IN',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_GREATER_THAN_OR_EQUAL = 'IS_GREATER_THAN_OR_EQUAL',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_LESS_THAN_OR_EQUAL = 'IS_LESS_THAN_OR_EQUAL',
  NOT_IN = 'NOT_IN',
}

export type RuleConditionInputObject = {
  attributeName: RuleConditionAttributeNameEnum
  condition: RuleConditionEnum
  value?: InputMaybe<Scalars['String']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export type RuleConditionValueType = AccountingAccount | AccountingCategoryType | LocalBusiness

export type RuleMetadata = {
  actions: Array<RuleMetadataAction>
  conditions: Array<RuleMetadataCondition>
  defaultAction: RuleActionEnum
  defaultCondition: RuleConditionAttributeNameEnum
  defaultName: Scalars['String']
}

export type RuleMetadataAction = {
  code: RuleActionEnum
  conditionLabel: Maybe<Scalars['String']>
  conditions: Maybe<Array<RuleMetadataActionCondition>>
  defaultCondition: Maybe<RuleActionConditionEnum>
  name: Scalars['String']
  values: Maybe<Array<SelectItem>>
}

export type RuleMetadataActionCondition = {
  code: RuleActionConditionEnum
  name: Scalars['String']
}

export type RuleMetadataCondition = {
  code: RuleConditionAttributeNameEnum
  conditions: Array<RuleMetadataConditionCondition>
  defaultCondition: RuleConditionEnum
  defaultValue: Maybe<Scalars['String']>
  multipleValues: Scalars['Boolean']
  name: Scalars['String']
}

export type RuleMetadataConditionCondition = {
  code: RuleConditionEnum
  name: Scalars['String']
}

export enum RuleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum RuleTypeEnum {
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
}

export enum RulesPermissionsEnum {
  ACTIVATE_RULE = 'ACTIVATE_RULE',
  CREATE_RULE = 'CREATE_RULE',
  DEACTIVATE_RULE = 'DEACTIVATE_RULE',
  DELETE_RULE = 'DELETE_RULE',
  RULES = 'RULES',
  UPDATE_RULE = 'UPDATE_RULE',
}

export type SectionFieldsInput = {
  additionalFields?: InputMaybe<Array<SectionFieldsInput>>
  intValue?: InputMaybe<Scalars['Int']>
  key: Scalars['String']
  stringValue?: InputMaybe<Scalars['String']>
}

export type SelectItem = {
  code: Scalars['String']
  name: Scalars['String']
}

export type SettleInboxAttachment = {
  attachment: Attachment
  convertedAt: Maybe<Scalars['ISO8601DateTime']>
  createdDocument: Maybe<CreatedDocument>
  id: Scalars['ID']
  payableAutocreated: Maybe<Scalars['Boolean']>
}

export type SettleInboxAutopayableConfigType = {
  allowlistCount: Scalars['Int']
  allowlistEmails: Maybe<Array<Scalars['String']>>
  id: Scalars['ID']
}

export enum SettleInboxConfigPermissionsEnum {
  UPSERT_SETTLE_INBOX_AUTOPAYABLE_CONFIG = 'UPSERT_SETTLE_INBOX_AUTOPAYABLE_CONFIG',
}

export enum SettleInboxEventTypeEnum {
  CONVERTED_TO_PAYABLE = 'CONVERTED_TO_PAYABLE',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
}

export type SettleInboxMessage = {
  archived: Scalars['Boolean']
  attachments: Array<SettleInboxAttachment>
  body: Maybe<Scalars['String']>
  createdInvoices: Array<Invoice>
  id: Scalars['ID']
  read: Scalars['Boolean']
  receivedAt: Scalars['ISO8601DateTime']
  senderEmail: Scalars['String']
  senderName: Maybe<Scalars['String']>
  subject: Maybe<Scalars['String']>
  timelineEvents: Array<SettleInboxTimelineEvent>
}

export type SettleInboxMessages = {
  data: Array<SettleInboxMessage>
  pagination: Pagination
}

export enum SettleInboxPermissionsEnum {
  CREATE_PAYABLE_FROM_SETTLE_INBOX = 'CREATE_PAYABLE_FROM_SETTLE_INBOX',
  CREATE_VENDOR_CREDIT_FROM_SETTLE_INBOX = 'CREATE_VENDOR_CREDIT_FROM_SETTLE_INBOX',
  CREATE_W9_FROM_SETTLE_INBOX = 'CREATE_W9_FROM_SETTLE_INBOX',
  DELETE_SETTLE_INBOX_MESSAGES = 'DELETE_SETTLE_INBOX_MESSAGES',
  READ_SETTLE_INBOX_AUTOPAYABLE_CONFIG = 'READ_SETTLE_INBOX_AUTOPAYABLE_CONFIG',
  UPDATE_SETTLE_INBOX_MESSAGE_ARCHIVED = 'UPDATE_SETTLE_INBOX_MESSAGE_ARCHIVED',
  UPDATE_SETTLE_INBOX_MESSAGE_READ = 'UPDATE_SETTLE_INBOX_MESSAGE_READ',
}

export type SettleInboxTimelineEvent = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: SettleInboxEventTypeEnum
  icon: IconEnum
  user: Maybe<Approver>
}

export type SignIn = {
  active: Scalars['Boolean']
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  current: Scalars['Boolean']
  ip: Scalars['String']
  jwtId: Scalars['String']
  revoked: Scalars['Boolean']
  revokedAt: Maybe<Scalars['ISO8601DateTime']>
}

export type SignedDocument = {
  byteSize: Scalars['Int']
  contentType: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  type: Maybe<Scalars['String']>
  url: Scalars['String']
}

export enum SignedDocumentTypesEnum {
  AMORTIZED_LOAN_AGREEMENT = 'AMORTIZED_LOAN_AGREEMENT',
  BULLET_EPT_AGREEMENT = 'BULLET_EPT_AGREEMENT',
  EXTENDED_PAYMENT_TERM_AGREEMENT = 'EXTENDED_PAYMENT_TERM_AGREEMENT',
  FACTORING_AGREEMENT = 'FACTORING_AGREEMENT',
  FACTORING_AGREEMENT_V2 = 'FACTORING_AGREEMENT_V2',
  PAYEE_AGENT_DISCLOSURE = 'PAYEE_AGENT_DISCLOSURE',
}

export enum SignupInterestsEnum {
  BILL_PAY = 'BILL_PAY',
  PURCHASING = 'PURCHASING',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
}

export enum StatusEnum {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  STARTED = 'STARTED',
  WAITING = 'WAITING',
}

export enum SuggestedVendorAccountTypeEnum {
  EMAIL = 'EMAIL',
  OCR = 'OCR',
}

export type SyncError = {
  date: Maybe<Scalars['ISO8601Date']>
  errorMessage: Maybe<Scalars['String']>
  fixClaimed: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['ID']>
}

export type SyncErrorSummaries = {
  data: Array<SyncErrorSummary>
  pagination: Pagination
}

export type SyncErrorSummary = {
  count: Scalars['Int']
  syncErrorMessage: Scalars['String']
  syncObjectType: SyncObjectTypeEnum
}

export type SyncEvent = {
  displaySubtext: Maybe<Scalars['String']>
  displayText: Scalars['String']
  eventType: SyncEventTypeEnum
  icon: SyncEventIconEnum
  id: Scalars['ID']
  occurredAt: Scalars['ISO8601DateTime']
  syncObject: Maybe<SyncObject>
  syncObjectType: Maybe<SyncObjectTypeEnum>
}

export enum SyncEventIconEnum {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  ERRORED = 'ERRORED',
  SYNCED = 'SYNCED',
}

export enum SyncEventTypeEnum {
  CREATED_EXTERNALLY = 'CREATED_EXTERNALLY',
  DELETED_EXTERNALLY = 'DELETED_EXTERNALLY',
  DELETED_LOCALLY = 'DELETED_LOCALLY',
  EXCLUDED_FROM_SYNC = 'EXCLUDED_FROM_SYNC',
  FIX_CLAIMED = 'FIX_CLAIMED',
  FIX_UNCLAIMED = 'FIX_UNCLAIMED',
  INCLUDED_IN_SYNC = 'INCLUDED_IN_SYNC',
  JOURNAL_ENTRY_EXPORTED = 'JOURNAL_ENTRY_EXPORTED',
  MARKED_AS_PAID = 'MARKED_AS_PAID',
  PAYMENT_DELETED = 'PAYMENT_DELETED',
  PAYMENT_EXPORTED = 'PAYMENT_EXPORTED',
  PAYMENT_REVERSAL_EXPORTED = 'PAYMENT_REVERSAL_EXPORTED',
  SYNC_ERRORED = 'SYNC_ERRORED',
  SYNC_INITIATED = 'SYNC_INITIATED',
  UPDATED_EXTERNALLY = 'UPDATED_EXTERNALLY',
  UPDATED_LOCALLY = 'UPDATED_LOCALLY',
}

export type SyncEvents = {
  data: Array<SyncEvent>
  pagination: Pagination
}

export type SyncObject = CatalogItem | Invoice | VendorCredit

export type SyncObjectStatus = {
  excludedFromSync: Scalars['Boolean']
  objectSyncStatusEnum: ObjectSyncStatusEnum
  syncDisabledBy: Maybe<Scalars['String']>
}

export enum SyncObjectTypeEnum {
  CATALOG_ITEM = 'CATALOG_ITEM',
  INVOICE = 'INVOICE',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
}

export type SyncObjects = {
  data: Array<SyncObject>
  pagination: Pagination
}

export enum SyncStateEnum {
  NOT_SYNCED = 'NOT_SYNCED',
  READY_TO_SYNC = 'READY_TO_SYNC',
  SYNCED = 'SYNCED',
  SYNCED_WITH_ERRORS = 'SYNCED_WITH_ERRORS',
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',
}

export type TeamMember = {
  clients: Maybe<Array<UserClient>>
  deactivated: Scalars['Boolean']
  deletable: Scalars['Boolean']
  email: Scalars['String']
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName: Maybe<Scalars['String']>
  membership: Membership
  name: Maybe<Scalars['String']>
  reactivateable: Scalars['Boolean']
  totp: Maybe<Totp>
}

export enum TeamMemberStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type TeamMembers = {
  invitations: Array<Invitation>
  users: Array<TeamMember>
}

export enum TeamPermissionsEnum {
  ACTIVATE_TEAM_MEMBER = 'ACTIVATE_TEAM_MEMBER',
  CUSTOM_ROLES = 'CUSTOM_ROLES',
  DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER',
  INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER',
  REQUIRE_MFA = 'REQUIRE_MFA',
  REVOKE_INVITATION = 'REVOKE_INVITATION',
  SET_OWN_ROLE = 'SET_OWN_ROLE',
  SET_TEAM_MEMBER_ROLE = 'SET_TEAM_MEMBER_ROLE',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
}

export type TestingMicroDeposits = {
  amount1: Maybe<Scalars['Int']>
  amount2: Maybe<Scalars['Int']>
}

export type TestingTokens = {
  emailVerificationToken: Maybe<Scalars['String']>
  lastLocalBusinessInvitationToken: Maybe<Scalars['String']>
  lastPaymentConfirmationToken: Maybe<Scalars['String']>
  lastTeamInvitationToken: Maybe<Scalars['String']>
  lastVendorInvitationToken: Maybe<Scalars['String']>
  passwordResetToken: Maybe<Scalars['String']>
}

export type TimelineEvent = {
  createdAt: Maybe<Scalars['ISO8601DateTime']>
  displayText: Scalars['String']
  eventType: TimelineEventTypeEnum
  icon: IconEnum
  invoiceEventType: InvoiceEventTypeEnum
  user: Maybe<Approver>
}

export enum TimelineEventTypeEnum {
  APPLIED_VENDOR_CREDITS_CLOSED = 'APPLIED_VENDOR_CREDITS_CLOSED',
  APPLIED_VENDOR_CREDITS_UPDATED = 'APPLIED_VENDOR_CREDITS_UPDATED',
  COLLECTION_EMAIL_SENT = 'COLLECTION_EMAIL_SENT',
  EPT_AGREEMENT_SIGNED = 'EPT_AGREEMENT_SIGNED',
  EPT_TERMS_CHANGED = 'EPT_TERMS_CHANGED',
  INVOICE_APPROVAL_CANCELLED = 'INVOICE_APPROVAL_CANCELLED',
  INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE = 'INVOICE_APPROVAL_CANCELLED_PLAN_CHANGE',
  INVOICE_APPROVAL_REQUESTED = 'INVOICE_APPROVAL_REQUESTED',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_ARCHIVED = 'INVOICE_ARCHIVED',
  INVOICE_CREATED_BY_EMAIL = 'INVOICE_CREATED_BY_EMAIL',
  INVOICE_CREATED_BY_IMPORT = 'INVOICE_CREATED_BY_IMPORT',
  INVOICE_CREATED_BY_USER = 'INVOICE_CREATED_BY_USER',
  INVOICE_CREATED_FROM_SETTLE_INBOX = 'INVOICE_CREATED_FROM_SETTLE_INBOX',
  INVOICE_MARKED_AS_PAID = 'INVOICE_MARKED_AS_PAID',
  INVOICE_MARKED_AS_UNPAID = 'INVOICE_MARKED_AS_UNPAID',
  INVOICE_RECEIVED_IN_SETTLE_INBOX = 'INVOICE_RECEIVED_IN_SETTLE_INBOX',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_VIEWED_BY_PAYER = 'INVOICE_VIEWED_BY_PAYER',
  PAYMENT_APPROVAL_CANCELLED = 'PAYMENT_APPROVAL_CANCELLED',
  PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE = 'PAYMENT_APPROVAL_CANCELLED_PLAN_CHANGE',
  PAYMENT_APPROVAL_REQUESTED = 'PAYMENT_APPROVAL_REQUESTED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_FROM_PAYER_PAID = 'PAYMENT_FROM_PAYER_PAID',
  PAYMENT_FROM_PAYER_PROCESSED = 'PAYMENT_FROM_PAYER_PROCESSED',
  PAYMENT_FULLY_REVERSED = 'PAYMENT_FULLY_REVERSED',
  PAYMENT_PARTIALLY_REPAID_EARLY = 'PAYMENT_PARTIALLY_REPAID_EARLY',
  PAYMENT_REFUND_COMPLETED = 'PAYMENT_REFUND_COMPLETED',
  PAYMENT_REFUND_INITIATED = 'PAYMENT_REFUND_INITIATED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_REPAID_EARLY = 'PAYMENT_REPAID_EARLY',
  PAYMENT_RESCHEDULED = 'PAYMENT_RESCHEDULED',
  PAYMENT_RETRIED = 'PAYMENT_RETRIED',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_TO_VENDOR_PAID = 'PAYMENT_TO_VENDOR_PAID',
  PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED = 'PAYMENT_TO_VENDOR_PAPER_CHECK_CASHED',
  PAYMENT_TO_VENDOR_PROCESSED = 'PAYMENT_TO_VENDOR_PROCESSED',
  RECEIVABLE_PURCHASED = 'RECEIVABLE_PURCHASED',
  RECEIVABLE_PURCHASE_CANCELED = 'RECEIVABLE_PURCHASE_CANCELED',
  SWITCH_TO_PRO = 'SWITCH_TO_PRO',
  VENDOR_CREDIT_ADDED = 'VENDOR_CREDIT_ADDED',
  VENDOR_CREDIT_APPLIED = 'VENDOR_CREDIT_APPLIED',
  VENDOR_CREDIT_CLOSED = 'VENDOR_CREDIT_CLOSED',
  VENDOR_CREDIT_CREATED = 'VENDOR_CREDIT_CREATED',
  VENDOR_CREDIT_DELETED = 'VENDOR_CREDIT_DELETED',
  VENDOR_CREDIT_UNAPPLIED = 'VENDOR_CREDIT_UNAPPLIED',
}

export type TotalDebits = {
  creditsToBusinessAccounts: Array<Money>
  debitsFromBusinessAccounts: Array<Money>
  debitsFromSettleAccounts: Array<Money>
  paymentsToVendorAccounts: Array<Money>
  repaymentsToSettle: Array<Money>
}

export type Totp = {
  activatedAt: Scalars['ISO8601DateTime']
}

export type TotpSetupInfo = {
  qrCodeUri: Scalars['String']
  recoveryCodes: Array<Scalars['String']>
  secret: Scalars['String']
}

export type TransactionPaginated = {
  data: Array<MarketingVirtualCardTransaction>
  pagination: Pagination
}

export type TransactionalTodoListSummary = {
  count: Scalars['Int']
  reason: DashboardActionableItemReasonEnum
}

export type Transfer = {
  actions: TransferActions
  amount: Maybe<Money>
  approvedBy: Maybe<Array<Approver>>
  approvers: Maybe<Array<Scalars['ID']>>
  balanceDue: Maybe<Money>
  currency: Maybe<Scalars['String']>
  currentExtendedPaymentTermConfirmation: Maybe<InvoiceExtendedPaymentTermConfirmation>
  dueDays: Maybe<Scalars['Int']>
  /** @deprecated Use paymentFromPayerStatus */
  eptPaymentFromPayerStatus: Maybe<InvoiceStatusEnum>
  /** @deprecated Use paymentToVendorStatus */
  eptPaymentToVendorStatus: Maybe<InvoiceStatusEnum>
  eptRequestErrorDialog: Maybe<ErrorDialog>
  exchangeFeeAmount: Maybe<Money>
  exchangeRate: Maybe<Scalars['String']>
  extendedPaymentTermAdditionalDays: Maybe<Scalars['Int']>
  extendedPaymentTermConfirmations: Maybe<Array<InvoiceExtendedPaymentTermConfirmation>>
  extendedPaymentTermOffer: Maybe<InvoiceExtendedPaymentTermOffer>
  /** @deprecated Use financingOptions instead */
  extendedPaymentTermOffers: Maybe<InvoiceExtendedPaymentTermOffers>
  /** @deprecated Use :payment instead */
  failedPayment: Maybe<Payment>
  /** Total of all fee associated with EPT agreements accepted by customer for the transfer. */
  feeAmount: Maybe<Money>
  financingOptions: FinancingOptions
  id: Scalars['ID']
  isExtendedPaymentTerm: Scalars['Boolean']
  markedAsPaid: Scalars['Boolean']
  /** Returns true when the transfer matches applied filter. Eg. when splitted invoice has 2 transfers: PENDING and SCHEDULED, and filter by PENDING status applied, PENDING transfer will have the field value 'true' and SCHEDULED transfer - 'false'. */
  matchesFilter: Scalars['Boolean']
  payment: Maybe<Payment>
  /** Error code for Inactive Payment */
  paymentErrorCode: Maybe<Scalars['String']>
  /** Error message for Inactive Payment */
  paymentErrorMessage: Maybe<Scalars['String']>
  paymentFromPayerStatus: Maybe<InvoiceStatusEnum>
  paymentToVendorStatus: Maybe<InvoiceStatusEnum>
  reconciliationItems: Array<ReconciliationItem>
  status: InvoiceStatusEnum
  statuses: Array<InvoiceStatusEnum>
  totalUsdAmount: Maybe<Money>
  usdAmount: Maybe<Money>
}

export type TransferActionableItem = {
  reason: DashboardActionableItemReasonEnum
  transfer: Transfer
}

export type TransferActions = {
  retryPayment: Action
}

export type TransferBulkInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type TransferDetails = {
  achTraceNumber: Maybe<Scalars['String']>
  amount: Money
  checkNumber: Maybe<Scalars['String']>
  confirmationNumber: Maybe<Scalars['String']>
  earlyRepayment: Scalars['Boolean']
  /** @deprecated Use payment.etaToVendor or payment.etaFromPayer instead */
  estimatedArrivalDate: Scalars['ISO8601Date']
  memo: Maybe<Scalars['String']>
  paidFrom: Maybe<Scalars['String']>
  paidTo: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethodEnum>
  processedAt: Maybe<Scalars['ISO8601DateTime']>
  status: PaymentStatusEnum
  userInitiated: Scalars['Boolean']
}

export type TransferInputObject = {
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
}

export type UnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}

export type UnpaidBillsPaginated = {
  data: Maybe<Array<UnpaidBill>>
  pagination: Maybe<Pagination>
}

export type UploadedDocument = {
  documentRequest: Maybe<Scalars['ID']>
  documents: Maybe<Array<Scalars['ID']>>
}

export type UserActions = {
  addBusiness: Action
  viewAccountantDashboard: Action
}

export type UserClient = {
  business: PublicBusiness
  /** @deprecated Use user_role instead */
  role: MembershipRolesEnum
  user: TeamMember
  userRole: UserRole
}

export type UserRole = {
  available: Scalars['Boolean']
  business: Maybe<Business>
  clientManager: Scalars['Boolean']
  deleted: Scalars['Boolean']
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  permissionSets: Array<PermissionSet>
  roleType: MembershipRolesEnum
}

export enum UserRoleGroupEnum {
  BUSINESS = 'BUSINESS',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
}

export enum UserTraitsEnum {
  APPROVER = 'APPROVER',
  CLERK = 'CLERK',
  CLIENT_MANAGER_ADMIN = 'CLIENT_MANAGER_ADMIN',
  CLIENT_MANAGER_TEAM_MEMBER = 'CLIENT_MANAGER_TEAM_MEMBER',
  MULTI_BUSINESS = 'MULTI_BUSINESS',
  REGULAR_MEMBER = 'REGULAR_MEMBER',
  SIGN_IN_LOCKED = 'SIGN_IN_LOCKED',
  VIEWER = 'VIEWER',
  WITH_MFA = 'WITH_MFA',
}

/** Validates referral codes passed to sign up flow */
export type ValidateReferralCode = {
  valid: Scalars['Boolean']
}

export enum VendorAccountNumberTypeEnum {
  ACH = 'ACH',
  CLABE = 'CLABE',
  IBAN = 'IBAN',
  OTHER = 'OTHER',
}

export type VendorCredit = {
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingSyncEvents: Maybe<Array<SyncEvent>>
  accountingUrl: Maybe<Scalars['String']>
  actions: VendorCreditActions
  amount: Maybe<Money>
  amountRemaining: Maybe<Money>
  amountUsed: Maybe<Money>
  associatedInvoice: Maybe<Invoice>
  attachment: Attachment
  createdAt: Scalars['ISO8601DateTime']
  /** @deprecated Use sync_object_status instead */
  excludedFromSync: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  lineItem: VendorCreditLineItem
  number: Maybe<Scalars['String']>
  quickbooksLocationId: Maybe<Scalars['ID']>
  showSyncHistory: Maybe<Scalars['Boolean']>
  status: VendorCreditStatusEnum
  syncError: Maybe<SyncError>
  syncObjectStatus: Maybe<SyncObjectStatus>
  vendorCreditApplications: Array<VendorCreditApplication>
  vendorLocalBusiness: Maybe<LocalBusiness>
}

export type VendorCreditActions = {
  add: Action
  delete: Action
}

export type VendorCreditApplication = {
  amount: Maybe<Money>
  appliedBy: Maybe<Approver>
  appliedOn: Scalars['ISO8601DateTime']
  closed: Scalars['Boolean']
  closedAt: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['ID']
  invoice: Maybe<Invoice>
  scheduledFor: Maybe<Scalars['ISO8601Date']>
  vendorCredit: VendorCredit
}

export type VendorCreditApplicationInputObject = {
  amountCents: Scalars['SafeInt']
  amountCurrency: Scalars['String']
  vendorCreditId: Scalars['ID']
}

export type VendorCreditInputObject = {
  accountingAccountId?: InputMaybe<Scalars['ID']>
  accountingCategoryClassId?: InputMaybe<Scalars['ID']>
  accountingCategoryDepartmentId?: InputMaybe<Scalars['ID']>
  accountingCategoryLocationId?: InputMaybe<Scalars['ID']>
  accountingCategoryOneId?: InputMaybe<Scalars['ID']>
  accountingCategoryTwoId?: InputMaybe<Scalars['ID']>
  amountCents?: InputMaybe<Scalars['SafeInt']>
  amountCurrency?: InputMaybe<Scalars['String']>
  associatedInvoiceId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  issueDate?: InputMaybe<Scalars['ISO8601Date']>
  number?: InputMaybe<Scalars['String']>
  quickBooksAccountId?: InputMaybe<Scalars['ID']>
  quickBooksClassId?: InputMaybe<Scalars['ID']>
  quickBooksLocationId?: InputMaybe<Scalars['ID']>
  trackingCategoryOneId?: InputMaybe<Scalars['ID']>
  trackingCategoryTwoId?: InputMaybe<Scalars['ID']>
  vendorLocalBusinessId?: InputMaybe<Scalars['ID']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type VendorCreditLineItem = {
  accountingAccount: Maybe<AccountingAccount>
  accountingAccountId: Maybe<Scalars['ID']>
  accountingCategoryClassId: Maybe<Scalars['ID']>
  accountingCategoryDepartmentId: Maybe<Scalars['ID']>
  accountingCategoryLocationId: Maybe<Scalars['ID']>
  accountingCategoryOneId: Maybe<Scalars['ID']>
  accountingCategoryTwoId: Maybe<Scalars['ID']>
  amount: Money
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  quantity: Maybe<Scalars['Float']>
  quickbooksCategory: Maybe<AccountingAccount>
  quickbooksCategoryId: Maybe<Scalars['ID']>
  quickbooksClass: Maybe<QuickBooksDictionary>
  quickbooksClassId: Maybe<Scalars['ID']>
  trackingCategoryOne: Maybe<AccountingDictionary>
  trackingCategoryOneId: Maybe<Scalars['ID']>
  trackingCategoryTwo: Maybe<AccountingDictionary>
  trackingCategoryTwoId: Maybe<Scalars['ID']>
  unitAmount: Maybe<Money>
  xeroCategory: Maybe<AccountingAccount>
  xeroCategoryId: Maybe<Scalars['String']>
}

export enum VendorCreditStatusEnum {
  APPLIED = 'APPLIED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  PARTIALLY_APPLIED = 'PARTIALLY_APPLIED',
  UNAPPLIED = 'UNAPPLIED',
}

export type VendorCredits = {
  data: Array<VendorCredit>
  pagination: Pagination
}

export enum VendorCreditsPermissionsEnum {
  ADD_VENDOR_CREDIT = 'ADD_VENDOR_CREDIT',
  APPLY_VENDOR_CREDIT = 'APPLY_VENDOR_CREDIT',
  CREATE_VENDOR_CREDIT = 'CREATE_VENDOR_CREDIT',
  DELETE_VENDOR_CREDIT = 'DELETE_VENDOR_CREDIT',
  UNAPPLY_VENDOR_CREDIT = 'UNAPPLY_VENDOR_CREDIT',
  UPDATE_VENDOR_CREDIT = 'UPDATE_VENDOR_CREDIT',
  VENDOR_CREDIT = 'VENDOR_CREDIT',
}

export type VendorFinancingConfirmation = {
  agreementDocument: SignedDocument
  feeAmount: Money
  feePercent: Scalars['Float']
  id: Scalars['ID']
  originalInvoiceAmount: Money
  payment: ReceivablePayment
}

export type VendorFinancingOffer = {
  feeAmount: Money
  feePercent: Scalars['Float']
  invoiceId: Scalars['ID']
  vendorAmount: Money
  vendorPaymentDate: Scalars['ISO8601Date']
  vendorPaymentEta: Maybe<Array<Scalars['ISO8601Date']>>
}

export type VendorInvitation = {
  address: Maybe<Address>
  businessName: Scalars['String']
  email: Maybe<Scalars['String']>
  hasSettleUser: Scalars['Boolean']
  invitedBy: Maybe<VendorInviter>
  name: Maybe<Scalars['String']>
  receivableInvoice: Maybe<ReceivableInvoice>
  senderName: Maybe<Scalars['String']>
  status: Maybe<VendorInvitationStatusEnum>
  vendorHasBusiness: Scalars['Boolean']
}

export enum VendorInvitationStatusEnum {
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED',
  SENT = 'SENT',
}

export type VendorInviter = {
  businessName: Scalars['String']
  senderName: Scalars['String']
}

export type VendorPaymentTerm = {
  id: Scalars['ID']
  localBusinessId: Scalars['ID']
  percentage: Scalars['Int']
  term: VendorPaymentTermsEnum
}

export type VendorPaymentTermInputObject = {
  id?: InputMaybe<Scalars['ID']>
  localBusinessId?: InputMaybe<Scalars['ID']>
  percentage?: InputMaybe<Scalars['Int']>
  term?: InputMaybe<VendorPaymentTermsEnum>
}

export enum VendorPaymentTermsEnum {
  DEPOSIT = 'DEPOSIT',
  NINETY = 'NINETY',
  RECEIPT = 'RECEIPT',
  SIXTY = 'SIXTY',
  THIRTY = 'THIRTY',
}

export enum VerificationSourceEnum {
  FINICITY = 'FINICITY',
  MODERN_TREASURY = 'MODERN_TREASURY',
  OTHER = 'OTHER',
  PLAID = 'PLAID',
}

export type Warehouse = {
  address: Maybe<Address>
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export type WarehouseInputObject = {
  address?: InputMaybe<AddressInputObject>
  name?: InputMaybe<Scalars['String']>
}

export type XeroAccount = {
  accountSubType: Scalars['String']
  accountType: Scalars['String']
  active: Scalars['Boolean']
  children: Array<XeroAccount>
  classification: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  number: Maybe<Scalars['String']>
  subAccount: Scalars['Boolean']
}

export type XeroAccountMapping = {
  accountId: Scalars['ID']
  accountingAccountId: Maybe<Scalars['ID']>
  externalAccountId: Maybe<Scalars['ID']>
  xeroAccountId: Maybe<Scalars['ID']>
}

export type XeroAccountMappingInputObject = {
  accountId: Scalars['ID']
  externalAccountId?: InputMaybe<Scalars['ID']>
  xeroAccountId?: InputMaybe<Scalars['ID']>
}

export type XeroDictionary = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type XeroState = {
  accountMappings: Array<XeroAccountMapping>
  accounts: Array<XeroAccount>
  bankAccounts: Array<XeroAccount>
  bookCloseDate: Maybe<Scalars['ISO8601Date']>
  connected: Scalars['Boolean']
  creditCardAccounts: Array<XeroAccount>
  eptSyncEnabled: Scalars['Boolean']
  eptSyncStartDate: Maybe<Scalars['ISO8601Date']>
  expenseAccounts: Array<XeroAccount>
  feeExpensesAccountId: Maybe<Scalars['ID']>
  filteredAccounts: Array<XeroAccount>
  liabilityAccounts: Array<XeroAccount>
  readOnly: Scalars['Boolean']
  settleLiabilityAccountId: Maybe<Scalars['ID']>
  syncAutomatically: Scalars['Boolean']
  syncErrorMessage: Maybe<Scalars['String']>
  syncScheduledAt: Maybe<Scalars['ISO8601DateTime']>
  syncStartDate: Maybe<Scalars['ISO8601Date']>
  syncStartedAt: Maybe<Scalars['ISO8601DateTime']>
  syncState: SyncStateEnum
  syncUncategorizedExpenses: Scalars['Boolean']
  syncedAt: Maybe<Scalars['ISO8601DateTime']>
  trackCategoryOne: Scalars['Boolean']
  trackCategoryTwo: Scalars['Boolean']
  trackingCategoryOneName: Maybe<Scalars['String']>
  trackingCategoryOneOptions: Maybe<Array<AccountingDictionary>>
  trackingCategoryTwoName: Maybe<Scalars['String']>
  trackingCategoryTwoOptions: Maybe<Array<AccountingDictionary>>
  unallocatedExpensesAccountId: Maybe<Scalars['ID']>
}

export type XeroUnpaidBill = {
  amount: Maybe<Money>
  balance: Maybe<Money>
  dueDate: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  issueDate: Maybe<Scalars['ISO8601Date']>
  number: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
  vendorName: Maybe<Scalars['String']>
}
