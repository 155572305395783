import { useSegmentIdentityTracking } from './hooks/useSegmentIdentityTracking/useSegmentIdentityTracking'

interface ISegmentIdentityTrackingProps {
  profileId: string
}
// SegmentIdentityTracking component is a wrapper around useSegmentIdentityTracking, so it can be used inside App.tsx
// It component should be rendered inside ApolloProvider
export const SegmentIdentityTracking: React.FC<ISegmentIdentityTrackingProps> = ({ profileId }) => {
  useSegmentIdentityTracking(profileId)
  return null
}
