import { styled } from '@mui/material'
import { Z_INDEX } from 'settle-ui/theme'

export const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: Z_INDEX.snackbar,
  left: '50%',
  top: theme.spacing(2),
  transform: 'translateX(-50%)',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textAlign: 'center',
  maxWidth: '600px',
}))
