/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonTertiaryBorderWidthPositiveDefault = '0px'
export const IconButtonTertiaryBorderWidthPositiveHovered = '0px'
export const IconButtonTertiaryBorderWidthPositivePressed = '0px'
export const IconButtonTertiaryBorderWidthPositiveDisabled = '0px'
export const IconButtonTertiaryBorderWidthDestructiveDefault = '0px'
export const IconButtonTertiaryBorderWidthDestructiveHovered = '0px'
export const IconButtonTertiaryBorderWidthDestructivePressed = '0px'
export const IconButtonTertiaryBorderWidthDestructiveDisabled = '0px'
export const IconButtonTertiaryColorPositiveDefaultBackground = '#ffffff00'
export const IconButtonTertiaryColorPositiveDefaultOnBackground = '#00000099'
export const IconButtonTertiaryColorPositiveDefaultOutline = '#ffffff00'
export const IconButtonTertiaryColorPositiveHoveredBackground = '#00000014'
export const IconButtonTertiaryColorPositiveHoveredOnBackground = '#000000de'
export const IconButtonTertiaryColorPositiveHoveredOutline = '#ffffff00'
export const IconButtonTertiaryColorPositivePressedBackground = '#00000029'
export const IconButtonTertiaryColorPositivePressedOnBackground = '#000000de'
export const IconButtonTertiaryColorPositivePressedOutline = '#ffffff00'
export const IconButtonTertiaryColorPositiveDisabledBackground = '#ffffff00'
export const IconButtonTertiaryColorPositiveDisabledOnBackground = '#00000061'
export const IconButtonTertiaryColorPositiveDisabledOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDefaultBackground = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDefaultOnBackground = '#f4511e'
export const IconButtonTertiaryColorDestructiveDefaultOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructiveHoveredBackground = '#f4511e14'
export const IconButtonTertiaryColorDestructiveHoveredOnBackground = '#f4511e'
export const IconButtonTertiaryColorDestructiveHoveredOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructivePressedBackground = '#f4511e29'
export const IconButtonTertiaryColorDestructivePressedOnBackground = '#f4511e'
export const IconButtonTertiaryColorDestructivePressedOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDisabledBackground = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDisabledOnBackground = '#00000061'
export const IconButtonTertiaryColorDestructiveDisabledOutline = '#ffffff00'
