import { styled, ButtonBase } from '@mui/material'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import type { TIconButtonProps } from './IconButton.types'
import * as tkn from './IconButton.tokens'

const primaryNeutralActive = {
  backgroundColor: tkn.IconButtonPrimaryColorPositivePressedBackground,
  color: tkn.IconButtonPrimaryColorPositivePressedOnBackground,
  borderWidth: tkn.IconButtonPrimaryBorderWidthPositivePressed,
  borderColor: tkn.IconButtonPrimaryColorPositivePressedOutline,
}

const secondaryNeutralActive = {
  backgroundColor: tkn.IconButtonSecondaryColorPositivePressedBackground,
  color: tkn.IconButtonSecondaryColorPositivePressedOnBackground,
  borderWidth: tkn.IconButtonSecondaryBorderWidthPositivePressed,
  borderColor: tkn.IconButtonSecondaryColorPositivePressedOutline,
}

const tertiaryNeutralActive = {
  backgroundColor: tkn.IconButtonTertiaryColorPositivePressedBackground,
  color: tkn.IconButtonTertiaryColorPositivePressedOnBackground,
  borderWidth: tkn.IconButtonTertiaryBorderWidthPositivePressed,
  borderColor: tkn.IconButtonTertiaryColorPositivePressedOutline,
}

const primaryDestructiveActive = {
  backgroundColor: tkn.IconButtonPrimaryColorDestructivePressedBackground,
  color: tkn.IconButtonPrimaryColorDestructivePressedOnBackground,
  borderWidth: tkn.IconButtonPrimaryBorderWidthDestructivePressed,
  borderColor: tkn.IconButtonPrimaryColorDestructivePressedOutline,
}

const secondaryDestructiveActive = {
  backgroundColor: tkn.IconButtonSecondaryColorDestructivePressedBackground,
  color: tkn.IconButtonSecondaryColorDestructivePressedOnBackground,
  borderWidth: tkn.IconButtonSecondaryBorderWidthDestructivePressed,
  borderColor: tkn.IconButtonSecondaryColorDestructivePressedOutline,
}

const tertiaryDestructiveActive = {}

type TIconButton = Pick<TIconButtonProps, 'variant' | 'kind' | 'size' | 'active'>

export const IconButton = styled(ButtonBase, {
  shouldForwardProp: youShallNotPass('kind', 'variant', 'size', 'active'),
})<TIconButton>(({ variant, kind, size, active }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  ...(size === 'large' && {
    height: tkn.IconButtonBaseSizeDesktopLarge,
    width: tkn.IconButtonBaseSizeDesktopLarge,
    borderRadius: tkn.IconButtonBaseBorderRadiusDesktopLarge,
    paddingLeft: tkn.IconButtonBaseSpacingDesktopLargeLeft,
    paddingRight: tkn.IconButtonBaseSpacingDesktopLargeRight,
  }),
  ...(size === 'medium' && {
    height: tkn.IconButtonBaseSizeDesktopMedium,
    width: tkn.IconButtonBaseSizeDesktopMedium,
    borderRadius: tkn.IconButtonBaseBorderRadiusDesktopMedium,
    paddingLeft: tkn.IconButtonBaseSpacingDesktopMediumLeft,
    paddingRight: tkn.IconButtonBaseSpacingDesktopMediumRight,
  }),
  ...(size === 'small' && {
    height: tkn.IconButtonBaseSizeDesktopSmall,
    width: tkn.IconButtonBaseSizeDesktopSmall,
    borderRadius: tkn.IconButtonBaseBorderRadiusDesktopSmall,
    paddingLeft: tkn.IconButtonBaseSpacingDesktopSmallLeft,
    paddingRight: tkn.IconButtonBaseSpacingDesktopSmallRight,
  }),

  // [theme.breakpoints.down('md')]: {
  //   ...(size === 'large' && {
  //     height: tkn.IconButtonBaseSizeMobileLarge,
  //     width: tkn.IconButtonBaseSizeMobileLarge,
  //     borderRadius: tkn.IconButtonBaseBorderRadiusMobileLarge,
  //     paddingLeft: tkn.IconButtonBaseSpacingMobileLargeLeft,
  //     paddingRight: tkn.IconButtonBaseSpacingMobileLargeRight,
  //   }),
  //   ...(size === 'medium' && {
  //     height: tkn.IconButtonBaseSizeMobileMedium,
  //     width: tkn.IconButtonBaseSizeMobileMedium,
  //     borderRadius: tkn.IconButtonBaseBorderRadiusMobileMedium,
  //     paddingLeft: tkn.IconButtonBaseSpacingMobileMediumLeft,
  //     paddingRight: tkn.IconButtonBaseSpacingMobileMediumRight,
  //   }),
  //   ...(size === 'small' && {
  //     height: tkn.IconButtonBaseSizeMobileSmall,
  //     width: tkn.IconButtonBaseSizeMobileSmall,
  //     borderRadius: tkn.IconButtonBaseBorderRadiusMobileSmall,
  //     paddingLeft: tkn.IconButtonBaseSpacingMobileSmallLeft,
  //     paddingRight: tkn.IconButtonBaseSpacingMobileSmallRight,
  //   }),
  // },
  ...(variant === 'primary' &&
    kind === 'neutral' && {
      backgroundColor: tkn.IconButtonPrimaryColorPositiveDefaultBackground,
      color: tkn.IconButtonPrimaryColorPositiveDefaultOnBackground,
      borderWidth: tkn.IconButtonPrimaryBorderWidthPositiveDefault,
      borderColor: tkn.IconButtonPrimaryColorPositiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && primaryNeutralActive),
      ...(!active && {
        '&:active': primaryNeutralActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn.IconButtonPrimaryColorPositiveHoveredBackground,
          color: tkn.IconButtonPrimaryColorPositiveHoveredOnBackground,
          borderWidth: tkn.IconButtonPrimaryBorderWidthPositiveHovered,
          borderColor: tkn.IconButtonPrimaryColorPositiveHoveredBackground,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn.IconButtonPrimaryColorPositiveDisabledBackground,
        color: tkn.IconButtonPrimaryColorPositiveDisabledOnBackground,
        borderWidth: tkn.IconButtonPrimaryBorderWidthPositiveDisabled,
        borderColor: tkn.IconButtonPrimaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'neutral' && {
      backgroundColor: tkn.IconButtonSecondaryColorPositiveDefaultBackground,
      color: tkn.IconButtonSecondaryColorPositiveDefaultOnBackground,
      borderWidth: tkn.IconButtonSecondaryBorderWidthPositiveDefault,
      borderColor: tkn.IconButtonSecondaryColorPositiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && secondaryNeutralActive),
      ...(!active && {
        '&:active': secondaryNeutralActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn.IconButtonSecondaryColorPositiveHoveredBackground,
          color: tkn.IconButtonSecondaryColorPositiveHoveredOnBackground,
          borderWidth: tkn.IconButtonSecondaryBorderWidthPositiveHovered,
          borderColor: tkn.IconButtonSecondaryColorPositiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn.IconButtonSecondaryColorPositiveDisabledBackground,
        color: tkn.IconButtonSecondaryColorPositiveDisabledOnBackground,
        borderWidth: tkn.IconButtonSecondaryBorderWidthPositiveDisabled,
        borderColor: tkn.IconButtonSecondaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'neutral' && {
      backgroundColor: tkn.IconButtonTertiaryColorPositiveDefaultBackground,
      color: tkn.IconButtonTertiaryColorPositiveDefaultOnBackground,
      borderWidth: tkn.IconButtonTertiaryBorderWidthPositiveDefault,
      borderColor: tkn.IconButtonTertiaryColorPositiveDefaultOutline,
      ...(active && tertiaryNeutralActive),
      ...(!active && {
        '&:active': tertiaryNeutralActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn.IconButtonTertiaryColorPositiveHoveredBackground,
          color: tkn.IconButtonTertiaryColorPositiveHoveredOnBackground,
          borderWidth: tkn.IconButtonTertiaryBorderWidthPositiveHovered,
          borderColor: tkn.IconButtonTertiaryColorPositiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn.IconButtonTertiaryColorPositiveDisabledBackground,
        color: tkn.IconButtonTertiaryColorPositiveDisabledOnBackground,
        borderWidth: tkn.IconButtonTertiaryBorderWidthPositiveDisabled,
        borderColor: tkn.IconButtonTertiaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'primary' &&
    kind === 'destructive' && {
      backgroundColor: tkn.IconButtonPrimaryColorDestructiveDefaultBackground,
      color: tkn.IconButtonPrimaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn.IconButtonPrimaryBorderWidthDestructiveDefault,
      borderColor: tkn.IconButtonPrimaryColorDestructiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && primaryDestructiveActive),
      ...(!active && {
        '&:active': primaryDestructiveActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn.IconButtonPrimaryColorDestructiveHoveredBackground,
          color: tkn.IconButtonPrimaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn.IconButtonPrimaryBorderWidthDestructiveHovered,
          borderColor: tkn.IconButtonPrimaryColorDestructiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn.IconButtonPrimaryColorDestructiveDisabledBackground,
        color: tkn.IconButtonPrimaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn.IconButtonPrimaryBorderWidthDestructiveDisabled,
        borderColor: tkn.IconButtonPrimaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'destructive' && {
      backgroundColor: tkn.IconButtonSecondaryColorDestructiveDefaultBackground,
      color: tkn.IconButtonSecondaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn.IconButtonSecondaryBorderWidthDestructiveDefault,
      borderColor: tkn.IconButtonSecondaryColorDestructiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && secondaryDestructiveActive),
      ...(!active && {
        '&:active': secondaryDestructiveActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn.IconButtonSecondaryColorDestructiveHoveredBackground,
          color: tkn.IconButtonSecondaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn.IconButtonSecondaryBorderWidthDestructiveHovered,
          borderColor: tkn.IconButtonSecondaryColorDestructiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn.IconButtonSecondaryColorDestructiveDisabledBackground,
        color: tkn.IconButtonSecondaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn.IconButtonSecondaryBorderWidthDestructiveDisabled,
        borderColor: tkn.IconButtonSecondaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'destructive' && {
      backgroundColor: tkn.IconButtonTertiaryColorDestructiveDefaultBackground,
      color: tkn.IconButtonTertiaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn.IconButtonTertiaryBorderWidthDestructiveDefault,
      borderColor: tkn.IconButtonTertiaryColorDestructiveDefaultOutline,
      ...(active && tertiaryDestructiveActive),
      ...(!active && {
        '&:active': tertiaryDestructiveActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn.IconButtonTertiaryColorDestructiveHoveredBackground,
          color: tkn.IconButtonTertiaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn.IconButtonTertiaryBorderWidthDestructiveHovered,
          borderColor: tkn.IconButtonTertiaryColorDestructiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn.IconButtonTertiaryColorDestructiveDisabledBackground,
        color: tkn.IconButtonTertiaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn.IconButtonTertiaryBorderWidthDestructiveDisabled,
        borderColor: tkn.IconButtonTertiaryColorDestructiveDisabledOutline,
      },
    }),
}))

export default {
  IconButton,
}
