import { useRouter } from 'next/router'
import { TPaymentsGroup, getPaymentsGroup } from 'lib/navigation'

/**
 * Returns an active payments group router parameter; returns null if it's missing.
 */
export const usePaymentsGroupQueryParam = (): TPaymentsGroup | null => {
  const { query } = useRouter()

  return getPaymentsGroup(query.tab)
}
