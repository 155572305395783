interface IGetPurchaseOrdersPathParams {
  businessId: Maybe<string>
  search?: string
}

/**
 * Returns path to the purchase orders page.
 */
export const getPurchaseOrdersPath = ({ businessId, search }: IGetPurchaseOrdersPathParams) => {
  let path = `/${businessId}/purchase-orders`

  if (search) {
    path += `?${search}`
  }

  return path
}
