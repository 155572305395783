import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  getPurchaseOrdersPath,
  useBusinessIdPathParam,
  usePurchaseOrdersTabQueryParam,
  TPurchaseOrdersTab,
  GUIDED_PO_EXPERIENCE_QUERY_PARAM,
} from 'lib/navigation'
import type { IPaginationQueryParams } from 'lib/pagination'
import { PurchaseOrderSortableFieldEnum, PurchaseOrderSortingDirectionEnum } from 'gql/types'

export interface IPurchaseOrdersQueryParams extends IPaginationQueryParams {
  tab?: Maybe<TPurchaseOrdersTab>
  labelIds?: string[]
  vendorNameSearch?: string
  invoiceNumberSearch?: string
  purchaseOrderNumberSearch?: string
  sortingField?: PurchaseOrderSortableFieldEnum
  sortingDirection?: PurchaseOrderSortingDirectionEnum
  guidedPoExperience?: boolean
  purchaseOrderId?: string
}
type TOpen = (params?: IPurchaseOrdersQueryParams, options?: Parameters<typeof Router['push']>[2]) => Promise<boolean>
type TGetPath = (search?: string) => string
type TUsePurchaseOrdersNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles purchase orders page navigation. */
export const usePurchaseOrdersNavigation: TUsePurchaseOrdersNavigation = () => {
  const businessId = useBusinessIdPathParam()
  const purchaseOrdersTab = usePurchaseOrdersTabQueryParam()

  const open: TOpen = useCallback(
    (
      {
        page,
        perPage,
        tab = purchaseOrdersTab,
        labelIds,
        purchaseOrderId,
        purchaseOrderNumberSearch,
        invoiceNumberSearch,
        vendorNameSearch,
        sortingField,
        sortingDirection,
        guidedPoExperience,
      } = {},
      options = {},
    ) =>
      Router.push(
        {
          pathname: getPurchaseOrdersPath({ businessId }),
          query: {
            ...(page && { page }),
            ...(perPage && { per_page: perPage }),
            ...(tab && { tab }),
            ...(labelIds && labelIds.length > 0 && { label_ids: labelIds }),
            ...(purchaseOrderNumberSearch && { purchase_order_number_search: purchaseOrderNumberSearch }),
            ...(invoiceNumberSearch && { invoice_number_search: invoiceNumberSearch }),
            ...(vendorNameSearch && { vendor_name_search: vendorNameSearch }),
            ...(sortingField && { sort: sortingField.toLowerCase() }),
            ...(sortingDirection && { order: sortingDirection.toLowerCase() }),
            ...(purchaseOrderId && { purchase_order_id: purchaseOrderId }),
            ...(guidedPoExperience && { [GUIDED_PO_EXPERIENCE_QUERY_PARAM]: JSON.stringify(true) }),
          },
        },
        undefined,
        options,
      ),
    [businessId, purchaseOrdersTab],
  )

  const getPath: TGetPath = useCallback(
    (search?: string) => getPurchaseOrdersPath({ businessId, search }),
    [businessId],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
