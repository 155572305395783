/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonSecondaryBorderWidthPositiveDefault = '1px'
export const IconButtonSecondaryBorderWidthPositiveHovered = '1px'
export const IconButtonSecondaryBorderWidthPositivePressed = '1px'
export const IconButtonSecondaryBorderWidthPositiveDisabled = '1px'
export const IconButtonSecondaryBorderWidthDestructiveDefault = '1px'
export const IconButtonSecondaryBorderWidthDestructiveHovered = '1px'
export const IconButtonSecondaryBorderWidthDestructivePressed = '1px'
export const IconButtonSecondaryBorderWidthDestructiveDisabled = '1px'
export const IconButtonSecondaryColorPositiveDefaultBackground = '#ffffff00'
export const IconButtonSecondaryColorPositiveDefaultOnBackground = '#00000099'
export const IconButtonSecondaryColorPositiveDefaultOutline = '#0000001f'
export const IconButtonSecondaryColorPositiveHoveredBackground = '#00000014'
export const IconButtonSecondaryColorPositiveHoveredOnBackground = '#000000de'
export const IconButtonSecondaryColorPositiveHoveredOutline = '#0000001f'
export const IconButtonSecondaryColorPositivePressedBackground = '#00000029'
export const IconButtonSecondaryColorPositivePressedOnBackground = '#000000de'
export const IconButtonSecondaryColorPositivePressedOutline = '#00000061'
export const IconButtonSecondaryColorPositiveDisabledBackground = '#ffffff00'
export const IconButtonSecondaryColorPositiveDisabledOnBackground = '#00000061'
export const IconButtonSecondaryColorPositiveDisabledOutline = '#0000001f'
export const IconButtonSecondaryColorDestructiveDefaultBackground = '#ffffff00'
export const IconButtonSecondaryColorDestructiveDefaultOnBackground = '#f4511e'
export const IconButtonSecondaryColorDestructiveDefaultOutline = '#0000001f'
export const IconButtonSecondaryColorDestructiveHoveredBackground = '#f4511e14'
export const IconButtonSecondaryColorDestructiveHoveredOnBackground = '#f4511e'
export const IconButtonSecondaryColorDestructiveHoveredOutline = '#0000001f'
export const IconButtonSecondaryColorDestructivePressedBackground = '#f4511e29'
export const IconButtonSecondaryColorDestructivePressedOnBackground = '#f4511e'
export const IconButtonSecondaryColorDestructivePressedOutline = '#f4511e'
export const IconButtonSecondaryColorDestructiveDisabledBackground = '#ffffff00'
export const IconButtonSecondaryColorDestructiveDisabledOnBackground = '#00000061'
export const IconButtonSecondaryColorDestructiveDisabledOutline = '#0000001f'
