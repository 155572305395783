import { useCallback } from 'react'
import { getPayablesPath, IPayableQueryParams, useBusinessIdPathParam } from 'lib/navigation'

export const useOpenPayableInNewTab = () => {
  const businessId = useBusinessIdPathParam()

  return useCallback(
    ({ invoiceId }: IPayableQueryParams) => {
      const baseUrl = window.location.origin
      const path = getPayablesPath({ businessId, search: `invoice_id=${invoiceId}` })

      window.open(new URL(path, baseUrl).toString(), '_blank')
    },
    [businessId],
  )
}
