import { useRouter } from 'next/router'
import { TReceivableGroup, getReceivableGroup } from 'lib/navigation'

/**
 * Returns active receivable group router parameter.
 * Returns null when it's missing.
 */
export const useReceivableGroupQueryParam = (): TReceivableGroup | null => {
  const { query } = useRouter()

  return getReceivableGroup(query.tab)
}
