import { useRouter } from 'next/router'
import { TPurchaseOrderView, getPurchaseOrderView } from 'lib/navigation'

/**
 * Returns active purchase order view router query parameter.
 * Returns null when it's missing.
 */
export const usePurchaseOrderViewQueryParam = (): TPurchaseOrderView | null => {
  const { query } = useRouter()

  return getPurchaseOrderView(query.purchase_order_view)
}
