import MuiGlobalStyles from '@mui/material/GlobalStyles'

export const GlobalStyles: React.FC = () => (
  <MuiGlobalStyles
    styles={({ breakpoints }) => ({
      '.intercom-app iframe[name="intercom-banner-frame"]': {
        zIndex: 2,
      },
      input: {
        // fix for iOS Safari zooming on focus
        [breakpoints.down('mobile')]: {
          '&:focus': {
            fontSize: '16px',
          },
        },
      },
    })}
  />
)
