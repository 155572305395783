type TGetUrlSearch = (options: { [queryName: string]: string | string[] | undefined | null }) => string

/**
 * Forms a concatenated string of query parameters.
 * @param options - Object with query parameters.
 */
export const getURLSearch: TGetUrlSearch = (options) => {
  const search = new URLSearchParams()

  for (const [key, value] of Object.entries(options)) {
    if (value !== undefined && value !== null) {
      search.set(key, value.toString())
    }
  }

  return search.toString()
}
