const PURCHASE_ORDER_VIEWS = ['edit', 'preview'] as const

export type TPurchaseOrderView = typeof PURCHASE_ORDER_VIEWS[number]

/**
 * Checks if the given parameter is a valid purchase order view
 * and returns either the view or null.
 */
export const getPurchaseOrderView = (view: unknown) =>
  PURCHASE_ORDER_VIEWS.find((existing_view) => existing_view === view) ?? null
