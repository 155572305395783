import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getVendorsPath, useBusinessIdPathParam, IVendorsQueryParams } from 'lib/navigation'

type TOpen = (params?: IVendorsQueryParams, options?: Parameters<typeof Router['push']>[2]) => Promise<boolean>
type TGetPath = () => string
type TUseVendorsNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/**  Handles vendors page navigation. */
export const useVendorsNavigation: TUseVendorsNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    ({ page, perPage, search, withDeleted, vendorsWithInvoices } = {}, options = {}) => {
      const pathname = getVendorsPath(businessIdPathParam)
      const shallow = Router.asPath.includes(pathname)

      return Router.push(
        {
          pathname,
          query: {
            ...(page && { page }),
            ...(search && { search }),
            ...(perPage && { per_page: perPage }),
            ...(withDeleted === true && { withDeleted: 'true' }),
            ...(typeof vendorsWithInvoices === 'boolean' && { vendors_with_invoices: vendorsWithInvoices }),
          },
        },
        undefined,
        { shallow, ...options },
      )
    },
    [businessIdPathParam],
  )

  const getPath: TGetPath = useCallback(() => getVendorsPath(businessIdPathParam), [businessIdPathParam])

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
