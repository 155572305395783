export interface IGetPaymentsPathParams {
  businessId: Maybe<string>
  search?: string
}

/**
 * Returns path to the my payments page with an optional search query.
 */
export const getPaymentsPath = ({ businessId, search }: IGetPaymentsPathParams) => {
  let path = `/${businessId}/payments`

  if (search) {
    path += `?${search}`
  }

  return path
}
