import { useMemo } from 'react'
import { useBusinessIdPathParam } from 'lib/navigation'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

export const useMembershipRole = () => {
  const businessId = useBusinessIdPathParam()
  const { data } = gql.useProfileHook()

  return useMemo(() => {
    const memberships = data?.profile?.memberships
    const membership = memberships?.find((membership) => membership.business?.id === businessId)

    return membership?.role
  }, [data?.profile?.memberships, businessId])
}
