import { Box, BoxProps, ButtonBase, styled } from '@mui/material'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import * as tokens from '../../tokens/tokens'
import type { TButtonProps } from './Button'
import * as tkn from './Button.tokens'

type TButton = Pick<TButtonProps, 'variant' | 'kind' | 'size' | 'startIcon' | 'endIcon' | 'fullWidth' | 'active'>

const primaryNeutralActive = {
  backgroundColor: tkn.ButtonPrimaryColorPositivePressedBackground,
  color: tkn.ButtonPrimaryColorPositivePressedOnBackground,
  borderWidth: tkn.ButtonPrimaryBorderWidthPositivePressed,
  borderColor: `${tkn.ButtonPrimaryColorPositivePressedOutline} !important`,
  zIndex: 2,
}
const secondaryNeutralActive = {
  backgroundColor: tkn.ButtonSecondaryColorPositivePressedBackground,
  color: tkn.ButtonSecondaryColorPositivePressedOnBackground,
  borderWidth: tkn.ButtonSecondaryBorderWidthPositivePressed,
  borderColor: `${tkn.ButtonSecondaryColorPositivePressedOutline} !important`,
  zIndex: 2,
}
const tertiaryNeutralActive = {
  backgroundColor: tkn.ButtonTertiaryColorPositivePressedBackground,
  color: tkn.ButtonTertiaryColorPositivePressedOnBackground,
  borderWidth: tkn.ButtonTertiaryBorderWidthPositivePressed,
  borderColor: `${tkn.ButtonTertiaryColorPositivePressedOutline} !important`,
  zIndex: 2,
}
const primaryDestructiveActive = {
  backgroundColor: tkn.ButtonPrimaryColorDestructivePressedBackground,
  color: tkn.ButtonPrimaryColorDestructivePressedOnBackground,
  borderWidth: tkn.ButtonPrimaryBorderWidthDestructivePressed,
  borderColor: `${tkn.ButtonPrimaryColorDestructivePressedOutline} !important`,
  zIndex: 2,
}
const secondaryDestructiveActive = {
  backgroundColor: tkn.ButtonSecondaryColorDestructivePressedBackground,
  color: tkn.ButtonSecondaryColorDestructivePressedOnBackground,
  borderWidth: tkn.ButtonSecondaryBorderWidthDestructivePressed,
  borderColor: ` ${tkn.ButtonSecondaryColorDestructivePressedOutline} !important`,
  zIndex: 2,
}
const tertiaryDestructiveActive = {
  backgroundColor: tkn.ButtonTertiaryColorDestructivePressedBackground,
  color: tkn.ButtonTertiaryColorDestructivePressedOnBackground,
  borderWidth: tkn.ButtonTertiaryBorderWidthDestructivePressed,
  borderColor: `${tkn.ButtonTertiaryColorDestructivePressedOutline} !important`,
  zIndex: 2,
}

export const Button = styled(ButtonBase, {
  shouldForwardProp: youShallNotPass('kind', 'variant', 'size', 'startIcon', 'endIcon', 'fullWidth', 'active'),
})<TButton>(({ variant, kind, size, startIcon, endIcon, fullWidth, active }) => ({
  textAlign: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  ...(fullWidth && {
    width: '100%',
  }),
  fontSize: tokens.ThemeTypographyDesktopButtonFontSize,
  lineHeight: tokens.ThemeTypographyDesktopButtonLineHeight,
  fontWeight: tokens.ThemeTypographyDesktopButtonFontWeight,
  letterSpacing: tokens.ThemeTypographyDesktopButtonLetterSpacing,
  fontFamily: tokens.ThemeTypographyDesktopButtonFontFamily,
  textIndent: tokens.ThemeTypographyDesktopButtonParagraphSpacing,
  whiteSpace: 'nowrap',
  ...(size === 'large' && {
    height: tkn.ButtonBaseSizeDesktopLargeHeight,
    minWidth: tkn.ButtonBaseSizeDesktopLargeMinWidth,
    borderRadius: tkn.ButtonBaseBorderRadiusDesktopLarge,
    paddingLeft: tkn.ButtonBaseSpacingIconOffDesktopLargeLeft,
    paddingRight: tkn.ButtonBaseSpacingIconOffDesktopLargeRight,
  }),
  ...(size === 'medium' && {
    height: tkn.ButtonBaseSizeDesktopMediumHeight,
    minWidth: tkn.ButtonBaseSizeDesktopMediumMinWidth,
    borderRadius: tkn.ButtonBaseBorderRadiusDesktopMedium,
    paddingLeft: tkn.ButtonBaseSpacingIconOffDesktopMediumLeft,
    paddingRight: tkn.ButtonBaseSpacingIconOffDesktopMediumRight,
  }),
  ...(size === 'small' && {
    height: tkn.ButtonBaseSizeDesktopSmallHeight,
    minWidth: tkn.ButtonBaseSizeDesktopSmallMinWidth,
    borderRadius: tkn.ButtonBaseBorderRadiusDesktopSmall,
    paddingLeft: tkn.ButtonBaseSpacingIconOffDesktopSmallLeft,
    paddingRight: tkn.ButtonBaseSpacingIconOffDesktopSmallRight,
  }),
  ...(size === 'medium' &&
    startIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconLDesktopMediumLeft,
      paddingRight: tkn.ButtonBaseSpacingIconLDesktopMediumRight,
    }),
  ...(size === 'small' &&
    startIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconLDesktopSmallLeft,
      paddingRight: tkn.ButtonBaseSpacingIconLDesktopSmallRight,
    }),
  ...(size === 'large' &&
    startIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconLDesktopLargeLeft,
      paddingRight: tkn.ButtonBaseSpacingIconLDesktopLargeRight,
    }),
  ...(size === 'medium' &&
    endIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconRDesktopMediumLeft,
      paddingRight: tkn.ButtonBaseSpacingIconRDesktopMediumRight,
    }),
  ...(size === 'small' &&
    endIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconRDesktopSmallLeft,
      paddingRight: tkn.ButtonBaseSpacingIconRDesktopSmallRight,
    }),
  ...(size === 'large' &&
    endIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconRDesktopLargeLeft,
      paddingRight: tkn.ButtonBaseSpacingIconRDesktopLargeRight,
    }),

  // [theme.breakpoints.down('md')]: {
  //   fontSize: tokens.ThemeTypographyMobileButtonFontSize,
  //   lineHeight: tokens.ThemeTypographyMobileButtonLineHeight,
  //   fontWeight: tokens.ThemeTypographyMobileButtonFontWeight,
  //   letterSpacing: tokens.ThemeTypographyMobileButtonLetterSpacing,
  //   fontFamily: tokens.ThemeTypographyMobileButtonFontFamily,
  //   textIndent: tokens.ThemeTypographyMobileButtonParagraphSpacing,
  //   ...(size === 'large' && {
  //     height: tkn.ButtonBaseSizeMobileLarge,
  //     borderRadius: tkn.ButtonBaseBorderRadiusMobileLarge,
  //     paddingLeft: tkn.ButtonBaseSpacingIconOffMobileLargeLeft,
  //     paddingRight: tkn.ButtonBaseSpacingIconOffMobileLargeRight,
  //   }),
  //   ...(size === 'medium' && {
  //     height: tkn.ButtonBaseSizeMobileMedium,
  //     borderRadius: tkn.ButtonBaseBorderRadiusMobileMedium,
  //     paddingLeft: tkn.ButtonBaseSpacingIconOffMobileMediumLeft,
  //     paddingRight: tkn.ButtonBaseSpacingIconOffMobileMediumRight,
  //   }),
  //   ...(size === 'small' && {
  //     height: tkn.ButtonBaseSizeMobileSmall,
  //     borderRadius: tkn.ButtonBaseBorderRadiusMobileSmall,
  //     paddingLeft: tkn.ButtonBaseSpacingIconOffMobileSmallLeft,
  //     paddingRight: tkn.ButtonBaseSpacingIconOffMobileSmallRight,
  //   }),
  //   ...(size === 'medium' &&
  //     startIcon && {
  //       paddingLeft: tkn.ButtonBaseSpacingIconLMobileMediumLeft,
  //       paddingRight: tkn.ButtonBaseSpacingIconLMobileMediumRight,
  //     }),
  //   ...(size === 'small' &&
  //     startIcon && {
  //       paddingLeft: tkn.ButtonBaseSpacingIconLMobileSmallLeft,
  //       paddingRight: tkn.ButtonBaseSpacingIconLMobileSmallRight,
  //     }),
  //   ...(size === 'large' &&
  //     startIcon && {
  //       paddingLeft: tkn.ButtonBaseSpacingIconLMobileLargeLeft,
  //       paddingRight: tkn.ButtonBaseSpacingIconLMobileLargeRight,
  //     }),
  //   ...(size === 'medium' &&
  //     endIcon && {
  //       paddingLeft: tkn.ButtonBaseSpacingIconRMobileMediumLeft,
  //       paddingRight: tkn.ButtonBaseSpacingIconRMobileMediumRight,
  //     }),
  //   ...(size === 'small' &&
  //     endIcon && {
  //       paddingLeft: tkn.ButtonBaseSpacingIconRMobileSmallLeft,
  //       paddingRight: tkn.ButtonBaseSpacingIconRMobileSmallRight,
  //     }),
  //   ...(size === 'large' &&
  //     endIcon && {
  //       paddingLeft: tkn.ButtonBaseSpacingIconRMobileLargeLeft,
  //       paddingRight: tkn.ButtonBaseSpacingIconRMobileLargeRight,
  //     }),
  // },
  ...(variant === 'primary' &&
    kind === 'neutral' && {
      backgroundColor: tkn.ButtonPrimaryColorPositiveDefaultBackground,
      color: tkn.ButtonPrimaryColorPositiveDefaultOnBackground,
      borderWidth: tkn.ButtonPrimaryBorderWidthPositiveDefault,
      borderStyle: 'solid',
      borderColor: tkn.ButtonPrimaryColorPositiveDefaultOutline,
      ...(active && primaryNeutralActive),
      ...(!active && {
        '&:active': primaryNeutralActive,
        '&:hover': {
          backgroundColor: tkn.ButtonPrimaryColorPositiveHoveredBackground,
          color: tkn.ButtonPrimaryColorPositiveHoveredOnBackground,
          borderWidth: tkn.ButtonPrimaryBorderWidthPositiveHovered,
          borderColor: tkn.ButtonPrimaryColorPositiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn.ButtonPrimaryColorPositiveHoveredBackground,
        color: tkn.ButtonPrimaryColorPositiveHoveredOnBackground,
        borderWidth: tkn.ButtonPrimaryBorderWidthPositiveHovered,
        borderColor: tkn.ButtonPrimaryColorPositiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn.ButtonPrimaryColorPositiveDisabledBackground,
        color: tkn.ButtonPrimaryColorPositiveDisabledOnBackground,
        borderWidth: tkn.ButtonPrimaryBorderWidthPositiveDisabled,
        borderColor: tkn.ButtonPrimaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'neutral' && {
      backgroundColor: tkn.ButtonSecondaryColorPositiveDefaultBackground,
      color: tkn.ButtonSecondaryColorPositiveDefaultOnBackground,
      borderWidth: tkn.ButtonSecondaryBorderWidthPositiveDefault,
      borderColor: tkn.ButtonSecondaryColorPositiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && secondaryNeutralActive),
      ...(!active && {
        '&:active': secondaryNeutralActive,
        '&:hover': {
          backgroundColor: tkn.ButtonSecondaryColorPositiveHoveredBackground,
          color: tkn.ButtonSecondaryColorPositiveHoveredOnBackground,
          borderWidth: tkn.ButtonSecondaryBorderWidthPositiveHovered,
          borderColor: tkn.ButtonSecondaryColorPositiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn.ButtonSecondaryColorPositiveHoveredBackground,
        color: tkn.ButtonSecondaryColorPositiveHoveredOnBackground,
        borderWidth: tkn.ButtonSecondaryBorderWidthPositiveHovered,
        borderColor: tkn.ButtonSecondaryColorPositiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn.ButtonSecondaryColorPositiveDisabledBackground,
        color: tkn.ButtonSecondaryColorPositiveDisabledOnBackground,
        borderWidth: tkn.ButtonSecondaryBorderWidthPositiveDisabled,
        borderColor: tkn.ButtonSecondaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'neutral' && {
      backgroundColor: tkn.ButtonTertiaryColorPositiveDefaultBackground,
      color: tkn.ButtonTertiaryColorPositiveDefaultOnBackground,
      borderWidth: tkn.ButtonTertiaryBorderWidthPositiveDefault,
      borderStyle: 'solid',
      borderColor: tkn.ButtonTertiaryColorPositiveDefaultOutline,
      ...(active && tertiaryNeutralActive),
      ...(!active && {
        '&:active': tertiaryNeutralActive,
        '&:hover': {
          backgroundColor: tkn.ButtonTertiaryColorPositiveHoveredBackground,
          color: tkn.ButtonTertiaryColorPositiveHoveredOnBackground,
          borderWidth: tkn.ButtonTertiaryBorderWidthPositiveHovered,
          borderColor: tkn.ButtonTertiaryColorPositiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn.ButtonTertiaryColorPositiveHoveredBackground,
        color: tkn.ButtonTertiaryColorPositiveHoveredOnBackground,
        borderWidth: tkn.ButtonTertiaryBorderWidthPositiveHovered,
        borderColor: tkn.ButtonTertiaryColorPositiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn.ButtonTertiaryColorPositiveDisabledBackground,
        color: tkn.ButtonTertiaryColorPositiveDisabledOnBackground,
        borderWidth: tkn.ButtonTertiaryBorderWidthPositiveDisabled,
        borderColor: tkn.ButtonTertiaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'primary' &&
    kind === 'destructive' && {
      backgroundColor: tkn.ButtonPrimaryColorDestructiveDefaultBackground,
      color: tkn.ButtonPrimaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn.ButtonPrimaryBorderWidthDestructiveDefault,
      borderStyle: 'solid',
      borderColor: tkn.ButtonPrimaryColorDestructiveDefaultOutline,
      ...(active && primaryDestructiveActive),
      ...(!active && {
        '&:active': primaryDestructiveActive,
        '&:hover': {
          backgroundColor: tkn.ButtonPrimaryColorDestructiveHoveredBackground,
          color: tkn.ButtonPrimaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn.ButtonPrimaryBorderWidthDestructiveHovered,
          borderColor: tkn.ButtonPrimaryColorDestructiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn.ButtonPrimaryColorDestructiveHoveredBackground,
        color: tkn.ButtonPrimaryColorDestructiveHoveredOnBackground,
        borderWidth: tkn.ButtonPrimaryBorderWidthDestructiveHovered,
        borderColor: tkn.ButtonPrimaryColorDestructiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn.ButtonPrimaryColorDestructiveDisabledBackground,
        color: tkn.ButtonPrimaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn.ButtonPrimaryBorderWidthDestructiveDisabled,
        borderColor: tkn.ButtonPrimaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'destructive' && {
      backgroundColor: tkn.ButtonSecondaryColorDestructiveDefaultBackground,
      color: tkn.ButtonSecondaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn.ButtonSecondaryBorderWidthDestructiveDefault,
      borderStyle: 'solid',
      borderColor: tkn.ButtonSecondaryColorDestructiveDefaultOutline,
      ...(active && secondaryDestructiveActive),
      ...(!active && {
        '&:active': secondaryDestructiveActive,
        '&:hover': {
          backgroundColor: tkn.ButtonSecondaryColorDestructiveHoveredBackground,
          color: tkn.ButtonSecondaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn.ButtonSecondaryBorderWidthDestructiveHovered,
          borderColor: tkn.ButtonSecondaryColorDestructiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn.ButtonSecondaryColorDestructiveHoveredBackground,
        color: tkn.ButtonSecondaryColorDestructiveHoveredOnBackground,
        borderWidth: tkn.ButtonSecondaryBorderWidthDestructiveHovered,
        borderColor: tkn.ButtonSecondaryColorDestructiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn.ButtonSecondaryColorDestructiveDisabledBackground,
        color: tkn.ButtonSecondaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn.ButtonSecondaryBorderWidthDestructiveDisabled,
        borderColor: tkn.ButtonSecondaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'destructive' && {
      backgroundColor: tkn.ButtonTertiaryColorDestructiveDefaultBackground,
      color: tkn.ButtonTertiaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn.ButtonTertiaryBorderWidthDestructiveDefault,
      borderStyle: 'solid',
      borderColor: tkn.ButtonTertiaryColorDestructiveDefaultOutline,
      ...(active && tertiaryDestructiveActive),
      ...(!active && {
        '&:active': tertiaryDestructiveActive,
        '&:hover': {
          backgroundColor: tkn.ButtonTertiaryColorDestructiveHoveredBackground,
          color: tkn.ButtonTertiaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn.ButtonTertiaryBorderWidthDestructiveHovered,
          borderColor: tkn.ButtonTertiaryColorDestructiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn.ButtonTertiaryColorDestructiveHoveredBackground,
        color: tkn.ButtonTertiaryColorDestructiveHoveredOnBackground,
        borderWidth: tkn.ButtonTertiaryBorderWidthDestructiveHovered,
        borderColor: tkn.ButtonTertiaryColorDestructiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn.ButtonTertiaryColorDestructiveDisabledBackground,
        color: tkn.ButtonTertiaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn.ButtonTertiaryBorderWidthDestructiveDisabled,
        borderColor: tkn.ButtonTertiaryColorDestructiveDisabledOutline,
      },
      ...(active && {
        backgroundColor: tkn.ButtonTertiaryColorDestructivePressedBackground,
        color: tkn.ButtonTertiaryColorDestructivePressedOnBackground,
        borderWidth: tkn.ButtonTertiaryBorderWidthDestructivePressed,
        borderColor: tkn.ButtonTertiaryColorDestructivePressedOutline,
      }),
    }),
}))

type TStartIconBox = BoxProps & Pick<TButtonProps, 'size'>
const StartIconBox = styled(Box, {
  shouldForwardProp: youShallNotPass('size'),
})<TStartIconBox>(({ size }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',

  ...(size === 'large' && {
    marginRight: tkn.ButtonBaseSpacingIconLDesktopLargeGap,
  }),
  ...(size === 'medium' && {
    marginRight: tkn.ButtonBaseSpacingIconLDesktopMediumGap,
  }),
  ...(size === 'small' && {
    marginRight: tkn.ButtonBaseSpacingIconLDesktopSmallGap,
  }),

  // [theme.breakpoints.down('md')]: {
  //   ...(size === 'large' && {
  //     marginRight: tkn.ButtonBaseSpacingIconLMobileLargeGap,
  //   }),
  //   ...(size === 'medium' && {
  //     marginRight: tkn.ButtonBaseSpacingIconLMobileMediumGap,
  //   }),
  //   ...(size === 'small' && {
  //     marginRight: tkn.ButtonBaseSpacingIconLMobileSmallGap,
  //   }),
  // },
}))

type TEndIconBox = BoxProps & Pick<TButtonProps, 'size'>
const EndIconBox = styled(Box, {
  shouldForwardProp: youShallNotPass('size'),
})<TEndIconBox>(({ size }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',

  ...(size === 'small' && {
    marginLeft: tkn.ButtonBaseSpacingIconRDesktopSmallGap,
  }),
  ...(size === 'medium' && {
    marginLeft: tkn.ButtonBaseSpacingIconRDesktopMediumGap,
  }),
  ...(size === 'large' && {
    marginLeft: tkn.ButtonBaseSpacingIconRDesktopLargeGap,
  }),

  // [theme.breakpoints.down('md')]: {
  //   ...(size === 'small' && {
  //     marginLeft: tkn.ButtonBaseSpacingIconRMobileSmallGap,
  //   }),
  //   ...(size === 'medium' && {
  //     marginLeft: tkn.ButtonBaseSpacingIconRMobileMediumGap,
  //   }),
  //   ...(size === 'large' && {
  //     marginLeft: tkn.ButtonBaseSpacingIconRMobileLargeGap,
  //   }),
  // },
}))

export default {
  Button,
  StartIconBox,
  EndIconBox,
}
