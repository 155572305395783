import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  getReceiptsPath,
  useBusinessIdPathParam,
  useRemoveRouterQueryParams,
  useOpenReceiptInNewTab,
} from 'lib/navigation'

export interface IReceiptQueryParams {
  receiptId: Maybe<string>
  tab?: TReceiptTab
}
export type TReceiptTab = 'details' | 'docs'
type TOpen = (params: IReceiptQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TGetPath = (search?: string) => string
type TUseReceiptNavigation = () => {
  open: TOpen
  openInNewTabIfNeeded: TOpen
  close: TClose
  getPath: TGetPath
}

/** Handles receipt dialog navigation. */
export const useReceiptNavigation: TUseReceiptNavigation = () => {
  const removeRouterQueryParams = useRemoveRouterQueryParams()
  const businessId = useBusinessIdPathParam()
  const openReceiptInNewTab = useOpenReceiptInNewTab()

  const open: TOpen = useCallback(
    (params) =>
      Router.push(
        {
          query: {
            ...Router.query,
            receipt_id: params.receiptId,
            ...(params.tab && { receipt_tab: params.tab }),
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const openInNewTabIfNeeded: TOpen = useCallback(
    (params) => {
      if (Router.query.receipt_id) {
        openReceiptInNewTab(params)
        return Promise.resolve(true)
      }

      return open(params)
    },
    [open, openReceiptInNewTab],
  )

  const close: TClose = useCallback(
    () => removeRouterQueryParams('receipt_id', 'receipt_tab'),
    [removeRouterQueryParams],
  )
  const getPath: TGetPath = useCallback((search?: string) => getReceiptsPath({ businessId, search }), [businessId])

  return useMemo(() => ({ open, close, getPath, openInNewTabIfNeeded }), [open, close, getPath, openInNewTabIfNeeded])
}
