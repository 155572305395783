import { stripIgnoredCharacters, ASTNode } from 'graphql'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, NormalizedCacheObject } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { ErrorHandler, ErrorResponse, onError } from '@apollo/client/link/error'
import { getCookie } from 'cookies-next'
import uniqBy from 'lodash/uniqBy'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import { getLoginPath } from 'lib/navigation/helpers/getLoginPath/getLoginPath'
import { emitFeatureUpsell } from 'lib/emitter'
import autoMergeTypes from '../gql/auto-merge-types.json'
import { ERouterPage } from './navigation/consts'

let inlinedBusinessId: string | null = null

const httpLink = createHttpLink({
  uri: `${process.env.BACKEND_APP_URL}/graphql`,
  print(ast: ASTNode, originalPrint: (ast: ASTNode) => string) {
    // Reduces query size by 25%
    return stripIgnoredCharacters(originalPrint(ast))
  },
})

const getFeatureGatingError = ({ graphQLErrors }: ErrorResponse) => {
  if (!graphQLErrors) {
    return null
  }
  // @ts-expect-error extensions.messages is unknown type
  const featureError = (graphQLErrors ?? []).find((gqlError) => gqlError?.extensions?.messages?.feature)

  // @ts-expect-error extensions.messages is unknown type
  return featureError?.extensions?.messages?.feature?.[0] ?? null
}

export const errorHandler: ErrorHandler = (errorResponse) => {
  const { networkError } = errorResponse
  const featureGatingError = getFeatureGatingError(errorResponse)

  if (featureGatingError) {
    emitFeatureUpsell(featureGatingError)
  }

  if (networkError) {
    if ('statusCode' in networkError) {
      if (networkError.statusCode === 401) {
        if (!isSSR()) {
          if (window.document.location.pathname !== ERouterPage.login) {
            window.document.location.assign(getLoginPath({ next: window.document.location.pathname }))
          }
        }
      }
    }
  }
}

const errorLink = onError(errorHandler)

interface IRequestLinkParams {
  token?: Maybe<string>
  businessId?: Maybe<string>
}

const requestLink = ({ token }: IRequestLinkParams = {}) =>
  new ApolloLink((operation, forward) => {
    const authToken = token ?? getCookie('token')

    if (authToken) {
      const headers: { [key: string]: string } = {
        Authorization: `Bearer ${authToken}`,
      }

      if (inlinedBusinessId) {
        headers['X-Business-ID'] = inlinedBusinessId
      }
      operation.setContext({ headers })
    }

    return forward(operation)
  })

// Arrays of data, where incoming array should completely substitute existed one.
// Those are queries that we're fetching without parameters.
const replaceableArrayQueryNames = [
  'businesses',
  'plaidConnections',
  'finicityConnections',
  'accounts',
  'invoicesFilteringOptions',
]

const replaceableArrays = replaceableArrayQueryNames.reduce<Record<string, { merge: false }>>((all, queryName) => {
  all[queryName] = {
    merge: false,
  }
  return all
}, {})

const singletoneTypes = ['NotificationSettings', 'XeroState', 'NetsuiteState', 'QuickBooksState']

export const apolloCache = () =>
  new InMemoryCache({
    typePolicies: {
      Mergeable: {
        keyFields: false,
        merge: true,
      },
      NonMergeable: {
        keyFields: false,
        merge: false,
      },
      Singletone: {
        keyFields: [],
      },
      PurchaseOrder: {
        fields: {
          lineItems: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      PurchaseOrderLineItem: {
        fields: {
          children: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      CatalogItem: {
        fields: {
          properties: {
            merge: (_, incoming) => incoming,
          },
          variations: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      CatalogItemVariation: {
        fields: {
          propertyValues: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      CatalogItemProperty: {
        fields: {
          values: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Invoice: {
        fields: {
          lineItems: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      LocalBusiness: {
        fields: {
          vendorPaymentTerms: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Journey: {
        keyFields: ['type'],
        fields: {
          steps: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Query: {
        fields: {
          ...replaceableArrays,
          settleInboxMessages: {
            keyArgs: ['archived', 'messageId'],
            //eslint-disable-next-line default-param-last
            merge: (existing = { __typename: 'SettleInboxMessages', data: [] }, incoming) => ({
              ...incoming,
              data: uniqBy([...existing.data, ...incoming.data], '__ref'),
            }),
          },
          userRoles: {
            merge: (_, incoming) => incoming,
          },
          rules: {
            merge: (_, incoming) => incoming,
          },
          billingMethods: {
            merge: (_, incoming) => incoming,
          },
          journeys: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Dashboard: {
        fields: {
          actionableItems: relayStylePagination(),
        },
      },
      PublicInvoice: {
        keyFields: ['uuid'],
      },
      BillingPlanFeatures: {
        keyFields: ['feature'],
      },
      JourneyStep: {
        keyFields: ['type', 'relatedObjectData'],
      },
      CodeTypeV2: {
        keyFields: ['referralCode'],
      },
    },
    possibleTypes: {
      // Types that don't have id or uniq filed, that should be merged into one object.
      // In other words, only one instance of this type can exist in application
      Mergeable: autoMergeTypes,
      // Types that don't have id or uniq filed, that couldn't be merged into one object.
      // In other words, there are many instances of such types that are not connected to each other.
      // Those types won't be normalized by Apollo cache
      NonMergeable: ['QuickBooksDictionary', 'XeroDictionary'],
      SyncObject: ['Invoice', 'VendorCredit', 'CatalogItem'],
      LinkedDocument: ['Invoice', 'PurchaseOrder', 'Receipt'],
      Singletone: singletoneTypes,
      Integration: ['NetsuiteState', 'QuickBooksState', 'XeroState'],
    },
  })

export const setRequestLinkBusinessId = (id: typeof inlinedBusinessId): void => {
  inlinedBusinessId = id
}

interface IInitializeApolloProps extends IRequestLinkParams {
  initialCache?: NormalizedCacheObject
}

export const initializeApollo = ({ token, businessId, initialCache }: IInitializeApolloProps = {}) => {
  if (businessId) {
    setRequestLinkBusinessId(businessId)
  }

  const client = new ApolloClient({
    cache: apolloCache(),
    connectToDevTools: process.env.VERCEL_ENV !== 'production',
    link: ApolloLink.from([requestLink({ token }), errorLink, httpLink]),
  })

  if (initialCache) {
    client.cache.restore(initialCache)
  }

  return client
}

export type TApolloClient = ReturnType<typeof initializeApollo>

const apolloClient = initializeApollo()

export default apolloClient
