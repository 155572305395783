// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import omit from 'lodash/omit'
import { useCallback, useMemo } from 'react'
import { useRemoveRouterQueryParams } from 'lib/navigation'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

interface IChecklistQueryParams {
  checklist?: gql.ChecklistItemEnum
  documentRequest?: gql.DocumentTypeEnum
  from?: string
}

type TOpen = (params?: IChecklistQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TUseChecklistNavigation = () => {
  open: TOpen
  close: TClose
}

/** Handles settings dialog navigation. */
export const useChecklistNavigation: TUseChecklistNavigation = () => {
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open: TOpen = useCallback(
    ({ checklist, documentRequest, from } = {}) =>
      Router.push(
        {
          query: {
            ...omit(Router.query, ['checklist', 'document_request', 'from']),
            checklist,
            ...(documentRequest && { document_request: documentRequest }),
            ...(from && { from }),
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const close: TClose = useCallback(
    () => removeRouterQueryParams('checklist', 'document_request', 'from'),
    [removeRouterQueryParams],
  )

  return useMemo(() => ({ open, close }), [open, close])
}
