import { getTranslation } from 'lib/translations/helpers/getTranslation/getTranslation'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

const t = getTranslation()

const ACCOUNTING_PLATFORM_NAME_BY_TYPE: Record<gql.ProviderEnum, string> = {
  [gql.ProviderEnum.QUICK_BOOKS]: t('qbo'),
  [gql.ProviderEnum.XERO]: t('xero'),
  [gql.ProviderEnum.NETSUITE]: t('netsuite'),
}

export const getAccountingPlatformName = (accountingPlatform: gql.ProviderEnum) =>
  ACCOUNTING_PLATFORM_NAME_BY_TYPE[accountingPlatform]
