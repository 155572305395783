// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import omit from 'lodash/omit'
import { useCallback, useMemo } from 'react'
import {
  ISettingsQueryParams,
  getSettingsPath,
  useBusinessIdPathParam,
  useRemoveRouterQueryParams,
} from 'lib/navigation'

type TOpen = (params?: ISettingsQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TGetPath = (params?: ISettingsQueryParams) => string
type TUseSettingsNavigation = () => {
  open: TOpen
  close: TClose
  getPath: TGetPath
}

export const DEFAULT_SETTINGS_TAB = 'team-members' as const

/** Handles settings dialog navigation. */
export const useSettingsNavigation: TUseSettingsNavigation = () => {
  const businessId = useBusinessIdPathParam()
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open: TOpen = useCallback(
    ({
      bankAccountId,
      tab = DEFAULT_SETTINGS_TAB,
      teamMemberId,
      ruleId,
      accountNumber,
      routingNumber,
      destinationCountry,
      bicSwift,
      cnaps,
      autoFocusAccount,
      anchor,
      roleId,
    } = {}) =>
      Router.push(
        {
          query: {
            ...omit(Router.query, [
              'bank_account_id',
              'team_member_id',
              'rule_id',
              'role_id',
              'from',
              'account_number',
              'routing_number',
              'destination_country',
              'bic_swift',
              'cnaps',
              'auto_focus_account',
              'anchor',
            ]),
            settings: tab,
            ...(autoFocusAccount && { auto_focus_account: autoFocusAccount }),
            ...(ruleId && { rule_id: ruleId }),
            ...(roleId && { role_id: roleId }),
            ...(teamMemberId && { team_member_id: teamMemberId }),
            ...(bankAccountId && { bank_account_id: bankAccountId }),
            ...(accountNumber && { account_number: accountNumber }),
            ...(routingNumber && { routing_number: routingNumber }),
            ...(destinationCountry && { destination_country: destinationCountry }),
            ...(bicSwift && { bic_swift: bicSwift }),
            ...(cnaps && { cnaps }),
            ...(anchor && { anchor }),
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const close: TClose = useCallback(
    () =>
      removeRouterQueryParams(
        'from',
        'settings',
        'rule_id',
        'role_id',
        'team_member_id',
        'bank_account_id',
        'account_number',
        'routing_number',
        'destination_country',
        'bic_swift',
        'cnaps',
        'anchor',
      ),
    [removeRouterQueryParams],
  )

  const getPath: TGetPath = useCallback(
    ({ businessId: businessIdParam, ...params } = {}) => {
      const businessIdToUse = businessIdParam || businessId

      return getSettingsPath(businessIdToUse, params)
    },
    [businessId],
  )

  return useMemo(() => ({ open, close, getPath }), [open, getPath, close])
}
