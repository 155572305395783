import { GetBusinessTraitsHelper_BusinessFragment } from './getBusinessTraits.graphql'
import { Counters } from 'gql/types'

export const getBusinessTraits = ({
  id,
  address,
  officialName,
  billingPlan,
  newBillingPlan,
  roles,
  kybStatus,
  businessStructure,
  counters,
  lendingChecklistItemsRequired,
}: GetBusinessTraitsHelper_BusinessFragment & { counters?: Pick<Counters, 'all' | 'receivableInvoices'> }) => ({
  businessId: id,
  businessName: officialName,
  country: address?.country,
  businessPlan: newBillingPlan?.name ?? billingPlan?.name,
  businessRoles: roles,
  businessKybStatus: kybStatus,
  businessStructure,
  payableInvoicesCount: counters?.all,
  receivableInvoicesCount: counters?.receivableInvoices,
  lendingChecklistItemsRequired,
})
