/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonPrimaryBorderWidthPositiveDefault = '0px'
export const IconButtonPrimaryBorderWidthPositiveHovered = '0px'
export const IconButtonPrimaryBorderWidthPositivePressed = '0px'
export const IconButtonPrimaryBorderWidthPositiveDisabled = '0px'
export const IconButtonPrimaryBorderWidthDestructiveDefault = '0px'
export const IconButtonPrimaryBorderWidthDestructiveHovered = '0px'
export const IconButtonPrimaryBorderWidthDestructivePressed = '0px'
export const IconButtonPrimaryBorderWidthDestructiveDisabled = '0px'
export const IconButtonPrimaryColorPositiveDefaultBackground = '#ED9341'
export const IconButtonPrimaryColorPositiveDefaultOnBackground = '#ffffff'
export const IconButtonPrimaryColorPositiveDefaultOutline = '#ffffff00'
export const IconButtonPrimaryColorPositiveHoveredBackground = '#ed9341eb'
export const IconButtonPrimaryColorPositiveHoveredOnBackground = '#ffffff'
export const IconButtonPrimaryColorPositiveHoveredOutline = '#ffffff00'
export const IconButtonPrimaryColorPositivePressedBackground = '#ed9341d6'
export const IconButtonPrimaryColorPositivePressedOnBackground = '#ffffff'
export const IconButtonPrimaryColorPositivePressedOutline = '#ffffff00'
export const IconButtonPrimaryColorPositiveDisabledBackground = '#0000000a'
export const IconButtonPrimaryColorPositiveDisabledOnBackground = '#00000061'
export const IconButtonPrimaryColorPositiveDisabledOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructiveDefaultBackground = '#f4511e'
export const IconButtonPrimaryColorDestructiveDefaultOnBackground = '#ffffff'
export const IconButtonPrimaryColorDestructiveDefaultOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructiveHoveredBackground = '#f4511eeb'
export const IconButtonPrimaryColorDestructiveHoveredOnBackground = '#ffffff'
export const IconButtonPrimaryColorDestructiveHoveredOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructivePressedBackground = '#f4511ed6'
export const IconButtonPrimaryColorDestructivePressedOnBackground = '#ffffff'
export const IconButtonPrimaryColorDestructivePressedOutline = '#ffffff00'
export const IconButtonPrimaryColorDestructiveDisabledBackground = '#0000000a'
export const IconButtonPrimaryColorDestructiveDisabledOnBackground = '#00000061'
export const IconButtonPrimaryColorDestructiveDisabledOutline = '#ffffff00'
