import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import general from 'public/locales/en/general.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(intervalPlural)
  .init({
    resources: {
      en: {
        general,
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: 'en',
    fallbackLng: 'en',
    defaultNS: 'general',

    supportedLngs: ['en'],
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['b', 'br', 'i', 'li', 'ul', 'span'],
    },
    compatibilityJSON: 'v3',
  })

// has to be used in _app.tsx
export const i18next = i18n
