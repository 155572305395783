import { useCallback } from 'react'
import { getPurchaseOrdersPath, IPurchaseOrderQueryParams, useBusinessIdPathParam } from 'lib/navigation'

export const useOpenPurchaseOrderInNewTab = () => {
  const businessId = useBusinessIdPathParam()

  return useCallback(
    ({ purchaseOrderId }: IPurchaseOrderQueryParams) => {
      const baseUrl = window.location.origin
      const path = getPurchaseOrdersPath({ businessId, search: `purchase_order_id=${purchaseOrderId}` })

      window.open(new URL(path, baseUrl).toString(), '_blank')
    },
    [businessId],
  )
}
