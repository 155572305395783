import Router from 'next/router'
import omit from 'lodash/omit'
import { O } from 'ts-toolbelt'
import { useCallback, useMemo } from 'react'
import {
  getPaymentPath,
  TGetPaymentPathParams,
  useBusinessIdPathParam,
  useRemoveRouterQueryParams,
  PAYMENT_NAVIGATION_QUERY_PARAMS,
} from 'lib/navigation'

type TOpenParams = O.Nullable<TGetPaymentPathParams>

type TOpen = (params: TOpenParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TGetPath = (params: TGetPaymentPathParams) => string
type TUsePaymentNavigation = () => {
  open: TOpen
  close: TClose
  getPath: TGetPath
}

/** Handles payment dialog navigation. */
export const usePaymentNavigation: TUsePaymentNavigation = () => {
  const businessId = useBusinessIdPathParam()
  const removeRouterQueryParams = useRemoveRouterQueryParams()

  const open: TOpen = useCallback(({ reconciliationItemId, transferId, paymentDialog, extPeriod, autoFocus }) => {
    const query = { ...omit(Router.query, ...PAYMENT_NAVIGATION_QUERY_PARAMS) }

    const hasRequiredParams = Boolean(transferId || reconciliationItemId) && Boolean(paymentDialog)

    if (hasRequiredParams) {
      Object.assign(query, {
        ...(transferId && { transfer_id: transferId }),
        ...(reconciliationItemId && { reconciliation_item_id: reconciliationItemId }),
        ...(extPeriod && { ext_period: extPeriod }),
        ...(autoFocus && { auto_focus: autoFocus }),
        payment_dialog: paymentDialog,
      })
    }

    return Router.push({ query }, undefined, { shallow: true })
  }, [])

  const close: TClose = useCallback(
    () => removeRouterQueryParams(...PAYMENT_NAVIGATION_QUERY_PARAMS),
    [removeRouterQueryParams],
  )

  const getPath: TGetPath = useCallback((params) => getPaymentPath(businessId, params), [businessId])

  return useMemo(() => ({ open, close, getPath }), [open, close, getPath])
}
