import { useBusiness } from 'lib/hooks/useBusiness/useBusiness'
import { ProviderEnum } from 'gql/types'

export const useConnectedAccountingPlatform = () => {
  const { business } = useBusiness()

  return (
    (business?.quickBooksLinked && ProviderEnum.QUICK_BOOKS) ||
    (business?.xeroLinked && ProviderEnum.XERO) ||
    (business?.netsuiteLinked && ProviderEnum.NETSUITE) ||
    null
  )
}
