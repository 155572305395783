import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  getPurchaseOrdersPath,
  TPurchaseOrderView,
  useBusinessIdPathParam,
  useOpenPurchaseOrderInNewTab,
  useRemoveRouterQueryParams,
  GUIDED_PO_EXPERIENCE_QUERY_PARAM,
} from 'lib/navigation'

export type TPurchaseOrderTab = 'details' | 'docs'
export interface IPurchaseOrderQueryParams {
  purchaseOrderId?: Maybe<string>
  tab?: TPurchaseOrderTab
  view?: TPurchaseOrderView
  guidedPoExperience?: boolean
}

type TOpen = (params: IPurchaseOrderQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TGetPath = () => string
type TUsePurchaseOrderNavigation = () => {
  open: TOpen
  openInNewTabIfNeeded: TOpen
  close: TClose
  getPath: TGetPath
}

/** Handles purchase order dialog navigation. */
export const usePurchaseOrderNavigation: TUsePurchaseOrderNavigation = () => {
  const removeRouterQueryParams = useRemoveRouterQueryParams()
  const businessId = useBusinessIdPathParam()
  const openPurchaseOrderInNewTab = useOpenPurchaseOrderInNewTab()

  const open: TOpen = useCallback(
    (params) =>
      Router.push(
        {
          query: {
            ...Router.query,
            ...(params.purchaseOrderId && { purchase_order_id: params.purchaseOrderId }),
            ...(params.tab && { purchase_order_tab: params.tab }),
            ...(params.view && { purchase_order_view: params.view }),
            ...(params.guidedPoExperience && { [GUIDED_PO_EXPERIENCE_QUERY_PARAM]: JSON.stringify(true) }),
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const openInNewTabIfNeeded: TOpen = useCallback(
    (params) => {
      if (Router.query.purchase_order_id) {
        openPurchaseOrderInNewTab(params)
        return Promise.resolve(true)
      }

      return open(params)
    },
    [open, openPurchaseOrderInNewTab],
  )

  const close: TClose = useCallback(
    () => removeRouterQueryParams('purchase_order_id', 'purchase_order_tab', 'purchase_order_view'),
    [removeRouterQueryParams],
  )
  const getPath: TGetPath = useCallback(
    (search?: string) => getPurchaseOrdersPath({ businessId, search }),
    [businessId],
  )

  return useMemo(() => ({ open, close, getPath, openInNewTabIfNeeded }), [open, close, getPath, openInNewTabIfNeeded])
}
