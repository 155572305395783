import { CacheProvider, EmotionCache } from '@emotion/react'
import { ThemeProvider, CssBaseline } from '@mui/material'
import StyledEngine from './components/StyledEngine/StyledEngine'
import useMUITheme from './hooks/useMUITheme/useMUITheme'
import { createEmotionCache } from 'styles/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

export interface IAppStylesProviderProps {
  emotionCache: EmotionCache
}

export const AppStylesProvider: Settle.FC<IAppStylesProviderProps> = ({ children, emotionCache }) => {
  const theme = useMUITheme()

  return (
    <CacheProvider value={emotionCache || clientSideEmotionCache}>
      <StyledEngine>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <style global={true} jsx={true}>
            {`
              body {
                -webkit-font-smoothing: antialiased;

                text-rendering: optimizelegibility;
              }
              .pac-container {
                z-index: 10000;
              }
              .MuiAutocomplete-option {
                font-size: 14px;
              }
              .MuiPickersBasePicker-pickerView {
                padding-bottom: 24px;
              }
              #finicityConnectIframe {
                z-index: 10000 !important;
              }
            `}
          </style>
          {children}
        </ThemeProvider>
      </StyledEngine>
    </CacheProvider>
  )
}
