import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { identifyUser, trackPage } from 'lib/tracking'
import { useUserTraits } from '../../../hooks/useUserTraits'

export const useSegmentIdentityTracking = (profileId: string) => {
  const router = useRouter()
  const traits = useUserTraits()

  // initial identifyUser(profileId) call to let segment identify the user via the profileId
  // it should be called prior to any other events
  useEffect(() => {
    if (profileId) {
      identifyUser(profileId)
    }
  }, [profileId])

  // subsequent identifyUser(profileId) call to set user traits
  useEffect(() => {
    if (profileId && traits) {
      identifyUser(profileId, traits)
    }
  }, [profileId, traits])

  // trackPage should be called on mount after identifyUser(profileId) is called
  useEffect(() => {
    trackPage()

    router.events.on('routeChangeComplete', () => trackPage())
    return () => {
      router.events.off('routeChangeComplete', () => trackPage())
    }
  }, [])
}
