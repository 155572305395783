/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonBaseSizeDesktopLarge = '48px'
export const IconButtonBaseSizeDesktopMedium = '40px'
export const IconButtonBaseSizeDesktopSmall = '32px'
export const IconButtonBaseSizeMobileLarge = '48px'
export const IconButtonBaseSizeMobileMedium = '40px'
export const IconButtonBaseSizeMobileSmall = '32px'
export const IconButtonBaseBorderRadiusDesktopLarge = '6px'
export const IconButtonBaseBorderRadiusDesktopMedium = '5px'
export const IconButtonBaseBorderRadiusDesktopSmall = '4px'
export const IconButtonBaseBorderRadiusMobileLarge = '6px'
export const IconButtonBaseBorderRadiusMobileMedium = '5px'
export const IconButtonBaseBorderRadiusMobileSmall = '4px'
export const IconButtonBaseSpacingDesktopSmallLeft = '4px'
export const IconButtonBaseSpacingDesktopSmallRight = '4px'
export const IconButtonBaseSpacingDesktopMediumLeft = '8px'
export const IconButtonBaseSpacingDesktopMediumRight = '8px'
export const IconButtonBaseSpacingDesktopLargeLeft = '12px'
export const IconButtonBaseSpacingDesktopLargeRight = '12px'
export const IconButtonBaseSpacingMobileSmallLeft = '4px'
export const IconButtonBaseSpacingMobileSmallRight = '4px'
export const IconButtonBaseSpacingMobileMediumLeft = '8px'
export const IconButtonBaseSpacingMobileMediumRight = '8px'
export const IconButtonBaseSpacingMobileLargeLeft = '12px'
export const IconButtonBaseSpacingMobileLargeRight = '12px'
