import { useConnectedAccountingPlatform } from 'lib/hooks/useConnectedAccountingPlatform/useConnectedAccountingPlatform'
import { getAccountingPlatformName } from 'lib/helpers/getAccountingPlatformName/getAccountingPlatformName'
import { useBusiness } from 'lib/hooks/useBusiness/useBusiness'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'
import { ProviderEnum, SyncStateEnum } from 'gql/types'

export interface IUseAccountingPlatform {
  connectedAccountingPlatform: Maybe<ProviderEnum>
  disconnectedAccountingPlatform: Maybe<ProviderEnum>
  lastConnectedAccountingPlatform: Maybe<ProviderEnum>
  accountingPlatformName: Maybe<string>
  syncErrorMessage: Maybe<string>
  syncState: Maybe<SyncStateEnum>
  syncInProgress: boolean
  loading: boolean
  isReadOnly: boolean
  syncAutomatically: boolean
  syncedAt: string | null
  autoImportBills: boolean | null
}

export const useAccountingPlatform = (): IUseAccountingPlatform => {
  const { business } = useBusiness()
  const { data, loading } = gql.useAccountingPlatformHook({
    errorPolicy: 'all', // doesn't throw an error when user doesn't have permission to read accounting state
  })
  const syncState =
    (business?.xeroLinked && data?.xeroState?.syncState) ||
    (business?.netsuiteLinked && data?.netsuiteState?.syncState) ||
    (business?.quickBooksLinked && data?.quickBooksState?.syncState) ||
    null
  const syncErrorMessage =
    (business?.xeroLinked && data?.xeroState?.syncErrorMessage) ||
    (business?.netsuiteLinked && data?.netsuiteState?.syncErrorMessage) ||
    (business?.quickBooksLinked && data?.quickBooksState?.syncErrorMessage) ||
    null
  const connectedAccountingPlatform = useConnectedAccountingPlatform()
  const disconnectedAccountingPlatform = (() => {
    if (connectedAccountingPlatform) {
      return null
    }

    return data?.accountingState?.accountingProvider ?? null
  })()

  const autoImportBills = (() => {
    if (connectedAccountingPlatform === ProviderEnum.QUICK_BOOKS) {
      return data?.quickBooksState?.autoImportBills ?? null
    }
    if (connectedAccountingPlatform === ProviderEnum.NETSUITE) {
      return data?.netsuiteState?.autoImportBills ?? null
    }

    return null
  })()

  const syncInProgress = syncState === SyncStateEnum.SYNC_IN_PROGRESS || syncState === SyncStateEnum.READY_TO_SYNC
  const lastConnectedAccountingPlatform = connectedAccountingPlatform || disconnectedAccountingPlatform
  const accountingPlatformName =
    (lastConnectedAccountingPlatform && getAccountingPlatformName(lastConnectedAccountingPlatform)) ?? 'N/A'

  const isReadOnly: IUseAccountingPlatform['isReadOnly'] = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return data?.quickBooksState?.readOnly ?? false
      case ProviderEnum.XERO:
        return data?.xeroState?.readOnly ?? false
      case ProviderEnum.NETSUITE:
        return data?.netsuiteState?.readOnly ?? false
      default:
        return false
    }
  })()

  const syncAutomatically = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return data?.quickBooksState?.syncAutomatically ?? false
      case ProviderEnum.XERO:
        return data?.xeroState?.syncAutomatically ?? false
      case ProviderEnum.NETSUITE:
        return data?.netsuiteState?.syncAutomatically ?? false
      default:
        return false
    }
  })()

  const syncedAt: IUseAccountingPlatform['syncedAt'] = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return data?.quickBooksState?.syncedAt ?? null
      case ProviderEnum.XERO:
        return data?.xeroState?.syncedAt ?? null
      case ProviderEnum.NETSUITE:
        return data?.netsuiteState?.syncedAt ?? null
      default:
        return null
    }
  })()

  return {
    connectedAccountingPlatform,
    disconnectedAccountingPlatform,
    loading,
    syncState,
    syncInProgress,
    lastConnectedAccountingPlatform,
    accountingPlatformName,
    syncErrorMessage,
    syncAutomatically,
    isReadOnly,
    syncedAt,
    autoImportBills,
  }
}
