// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  useBusinessIdPathParam,
  useRemoveRouterQueryParams,
  TGetPayablePathParams,
  getPayablePath,
  useOpenPayableInNewTab,
} from 'lib/navigation'
import { PAYMENT_NAVIGATION_QUERY_PARAMS, GUIDED_BILL_EXPERIENCE_QUERY_PARAM } from 'lib/navigation/consts'

export interface IPayableQueryParams {
  invoiceId: Maybe<string>
}

type TOpen = (params: IPayableQueryParams) => Promise<boolean>
type TClose = () => Promise<boolean>
type TGetPath = (params: TGetPayablePathParams) => string
type TUsePayableNavigation = () => {
  open: TOpen
  openInNewTabIfNeeded: TOpen
  close: TClose
  getPath: TGetPath
}

/** Handles payable invoice dialog navigation. */
export const usePayableNavigation: TUsePayableNavigation = () => {
  const businessId = useBusinessIdPathParam()
  const removeRouterQueryParams = useRemoveRouterQueryParams()
  const openPayableInNewTab = useOpenPayableInNewTab()

  const open: TOpen = useCallback(
    (params) =>
      Router.push(
        {
          query: {
            ...Router.query,
            ...(params.invoiceId && { invoice_id: params.invoiceId }),
          },
        },
        undefined,
        { shallow: true },
      ),
    [],
  )

  const close: TClose = useCallback(
    // includes nested payment dialogs query params in case of payable dialog load failure
    () => removeRouterQueryParams('invoice_id', GUIDED_BILL_EXPERIENCE_QUERY_PARAM, ...PAYMENT_NAVIGATION_QUERY_PARAMS),
    [removeRouterQueryParams],
  )

  const openInNewTabIfNeeded: TOpen = useCallback(
    (params) => {
      if (Router.query.invoice_id) {
        openPayableInNewTab(params)
        return Promise.resolve(true)
      }

      return open(params)
    },
    [open, openPayableInNewTab],
  )

  const getPath: TGetPath = useCallback((params) => getPayablePath(businessId, params), [businessId])

  return useMemo(() => ({ open, close, getPath, openInNewTabIfNeeded }), [open, close, getPath, openInNewTabIfNeeded])
}
