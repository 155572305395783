export const ScrollbarObtrusiveGlobalStyles: React.FC = () => (
  <style global={true} jsx={true}>
    {`
      .layoutScrollbarObtrusive *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
      }
      .layoutScrollbarObtrusive *::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: #c1c1c1;
      }
      .layoutScrollbarObtrusive *::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .layoutScrollbarObtrusive * {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 transparent;
      }
    `}
  </style>
)
