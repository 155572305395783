import { ReactNode, forwardRef } from 'react'
import { ExtendButtonBase, ButtonBaseTypeMap, ButtonBaseProps, CircularProgress, ButtonProps } from '@mui/material'
import $ from './Button.styled'

interface IProps {
  /**
   * The kind to use.
   * @default 'neutral'
   */
  kind?: 'neutral' | 'destructive'
  /**
   * The variant to use.
   * @default 'secondary'
   */
  variant?: 'primary' | 'secondary' | 'tertiary'
  /**
   * The size of the component
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Element placed before the children.
   */
  startIcon?: ReactNode
  /**
   * Element placed after the children.
   */
  endIcon?: ReactNode
  /**
   * If `true`, the button takes up the full width of its container.
   * @default false
   */
  fullWidth?: boolean
  /**
   * If `true`, the button becomes disabled and shows loading indicator as start icon.
   * @default false
   */
  loading?: boolean
  /**
   * If `true`, the button is set to active state.
   * @default false
   */
  active?: boolean
  /**
   * href prop from the MUI Button component.
   */
  href?: ButtonProps['href']
}

export type TButtonProps<D extends React.ElementType<any> = 'button', P = unknown> = ButtonBaseProps<D, IProps & P>

type TButton = ExtendButtonBase<ButtonBaseTypeMap<IProps>>

//@ts-expect-error TODO
export const Button: TButton = forwardRef(
  (
    {
      size = 'medium',
      startIcon,
      endIcon,
      kind = 'neutral',
      variant = 'secondary',
      children,
      fullWidth,
      loading,
      disabled,
      active,
      ...otherProps
    },
    ref,
  ) => (
    <$.Button
      endIcon={endIcon}
      startIcon={startIcon}
      kind={kind}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      ref={ref}
      disabled={loading || disabled}
      aria-selected={active}
      active={active}
      {...otherProps}
    >
      {(startIcon || loading) && (
        <$.StartIconBox size={size}>
          {loading ? <CircularProgress size={20} color="inherit" /> : startIcon}
        </$.StartIconBox>
      )}
      {children}
      {endIcon && <$.EndIconBox size={size}>{endIcon}</$.EndIconBox>}
    </$.Button>
  ),
)

//@ts-expect-error TODO
Button.displayName = 'Button'
