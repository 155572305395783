/**
 * Returns the inactive account path.
 */
export const getInactivePath = (businessId?: string) => {
  const path = `/inactive`

  if (businessId) {
    return `${path}?businessId=${businessId}`
  }

  return path
}
