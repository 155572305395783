/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonPrimaryBorderWidthPositiveDefault = '0px'
export const ButtonPrimaryBorderWidthPositiveHovered = '0px'
export const ButtonPrimaryBorderWidthPositivePressed = '0px'
export const ButtonPrimaryBorderWidthPositiveDisabled = '0px'
export const ButtonPrimaryBorderWidthDestructiveDefault = '0px'
export const ButtonPrimaryBorderWidthDestructiveHovered = '0px'
export const ButtonPrimaryBorderWidthDestructivePressed = '0px'
export const ButtonPrimaryBorderWidthDestructiveDisabled = '0px'
export const ButtonPrimaryColorPositiveDefaultBackground = '#ED9341'
export const ButtonPrimaryColorPositiveDefaultOnBackground = '#ffffff'
export const ButtonPrimaryColorPositiveDefaultOutline = '#ffffff00'
export const ButtonPrimaryColorPositiveHoveredBackground = '#ed9341eb'
export const ButtonPrimaryColorPositiveHoveredOnBackground = '#ffffff'
export const ButtonPrimaryColorPositiveHoveredOutline = '#ffffff00'
export const ButtonPrimaryColorPositivePressedBackground = '#ed9341d6'
export const ButtonPrimaryColorPositivePressedOnBackground = '#ffffff'
export const ButtonPrimaryColorPositivePressedOutline = '#ffffff00'
export const ButtonPrimaryColorPositiveDisabledBackground = '#0000000a'
export const ButtonPrimaryColorPositiveDisabledOnBackground = '#00000061'
export const ButtonPrimaryColorPositiveDisabledOutline = '#ffffff00'
export const ButtonPrimaryColorDestructiveDefaultBackground = '#f4511e'
export const ButtonPrimaryColorDestructiveDefaultOnBackground = '#ffffff'
export const ButtonPrimaryColorDestructiveDefaultOutline = '#ffffff00'
export const ButtonPrimaryColorDestructiveHoveredBackground = '#f4511eeb'
export const ButtonPrimaryColorDestructiveHoveredOnBackground = '#ffffff'
export const ButtonPrimaryColorDestructiveHoveredOutline = '#ffffff00'
export const ButtonPrimaryColorDestructivePressedBackground = '#f4511ed6'
export const ButtonPrimaryColorDestructivePressedOnBackground = '#ffffff'
export const ButtonPrimaryColorDestructivePressedOutline = '#ffffff00'
export const ButtonPrimaryColorDestructiveDisabledBackground = '#0000000a'
export const ButtonPrimaryColorDestructiveDisabledOnBackground = '#00000061'
export const ButtonPrimaryColorDestructiveDisabledOutline = '#ffffff00'
