/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonTertiaryBorderWidthPositiveDefault = '0px'
export const ButtonTertiaryBorderWidthPositiveHovered = '0px'
export const ButtonTertiaryBorderWidthPositivePressed = '0px'
export const ButtonTertiaryBorderWidthPositiveDisabled = '0px'
export const ButtonTertiaryBorderWidthDestructiveDefault = '0px'
export const ButtonTertiaryBorderWidthDestructiveHovered = '0px'
export const ButtonTertiaryBorderWidthDestructivePressed = '0px'
export const ButtonTertiaryBorderWidthDestructiveDisabled = '0px'
export const ButtonTertiaryColorPositiveDefaultBackground = '#ffffff00'
export const ButtonTertiaryColorPositiveDefaultOnBackground = '#ED9341'
export const ButtonTertiaryColorPositiveDefaultOutline = '#ffffff00'
export const ButtonTertiaryColorPositiveHoveredBackground = '#ed934114'
export const ButtonTertiaryColorPositiveHoveredOnBackground = '#ED9341'
export const ButtonTertiaryColorPositiveHoveredOutline = '#ffffff00'
export const ButtonTertiaryColorPositivePressedBackground = '#ed934129'
export const ButtonTertiaryColorPositivePressedOnBackground = '#ED9341'
export const ButtonTertiaryColorPositivePressedOutline = '#ffffff00'
export const ButtonTertiaryColorPositiveDisabledBackground = '#ffffff00'
export const ButtonTertiaryColorPositiveDisabledOnBackground = '#00000061'
export const ButtonTertiaryColorPositiveDisabledOutline = '#ffffff00'
export const ButtonTertiaryColorDestructiveDefaultBackground = '#ffffff00'
export const ButtonTertiaryColorDestructiveDefaultOnBackground = '#f4511e'
export const ButtonTertiaryColorDestructiveDefaultOutline = '#ffffff00'
export const ButtonTertiaryColorDestructiveHoveredBackground = '#f4511e14'
export const ButtonTertiaryColorDestructiveHoveredOnBackground = '#f4511e'
export const ButtonTertiaryColorDestructiveHoveredOutline = '#ffffff00'
export const ButtonTertiaryColorDestructivePressedBackground = '#f4511e29'
export const ButtonTertiaryColorDestructivePressedOnBackground = '#f4511e'
export const ButtonTertiaryColorDestructivePressedOutline = '#ffffff00'
export const ButtonTertiaryColorDestructiveDisabledBackground = '#ffffff00'
export const ButtonTertiaryColorDestructiveDisabledOnBackground = '#00000061'
export const ButtonTertiaryColorDestructiveDisabledOutline = '#ffffff00'
