import { TPayableGroup } from 'lib/navigation/hooks'
import { IPayableInvoicesFilters } from 'lib/navigation/interfaces'

export interface IGetPayablesPathParams {
  businessId: Maybe<string>
  group?: TPayableGroup
  search?: string
  filters?: IPayableInvoicesFilters
}

/**
 * Returns path to the payables page
 * with an optional search query.
 */
export const getPayablesPath = ({ businessId, search, group, filters }: IGetPayablesPathParams) => {
  let path = `/${businessId}/payables`

  if (search) {
    path += `?${search}`
  }

  if (group) {
    path += `${path.includes('?') ? '&' : '?'}tab=${group}`
  }

  if (filters) {
    let filtersQuery = ''

    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        filtersQuery += `${key}=${value}&`
      }
    }

    path += `${path.includes('?') ? '&' : '?'}${filtersQuery.slice(0, -1)}`
  }

  return path
}
