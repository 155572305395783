import type { ParsedUrlQuery } from 'querystring'
import { useRouter } from 'next/router'
import { z } from 'zod'

const PAYABLE_GROUPS = ['unscheduled', 'scheduled', 'paid', 'trash', 'in_review', 'approved', 'rejected'] as const
const schema = z.enum(PAYABLE_GROUPS).nullable()

export type TPayableGroup = z.infer<typeof schema>

/**
 * Returns active payable group router parameter.
 * Returns null when it's missing.
 */
export const usePayableGroupQueryParam = (query?: ParsedUrlQuery): TPayableGroup | null => {
  const router = useRouter()
  const result = schema.safeParse(query ? query.tab : router.query.tab)

  return result.success ? result.data : null
}
