import { ISettingsQueryParams, DEFAULT_SETTINGS_TAB } from 'lib/navigation'

/**
 * Returns the base path to the settings dialog
 * without any particular page.
 */
export const getSettingsPath = (
  businessId: Maybe<string>,
  { bankAccountId, ruleId, tab, teamMemberId, roleId }: ISettingsQueryParams = { tab: DEFAULT_SETTINGS_TAB },
) => {
  let path = `/${businessId}?settings=${tab}`

  if (bankAccountId) {
    path += `&bank_account_id=${bankAccountId}`
  }

  if (teamMemberId) {
    path += `&team_member_id=${teamMemberId}`
  }

  if (ruleId) {
    path += `&rule_id=${ruleId}`
  }

  if (roleId) {
    path += `&role_id=${roleId}`
  }

  return path
}
